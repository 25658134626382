import React from "react";
import CustomButton from "components/Buttons/CustomButton";
import { useTranslation } from "react-i18next";

interface AddButtonType {
  refetch:()=> void;
  setOpenAddChangeHistoryModal: (value: boolean) => void;
}

const AddButton = ({
  refetch,
  setOpenAddChangeHistoryModal,
}: AddButtonType) => {
	const { t } = useTranslation();
	return (
		<CustomButton
		text={t("Add new Change History row")}
		style={{ m: 2 }}
		onClickFunction={() => {
			setOpenAddChangeHistoryModal(true);
		}}
		/>
		);
	}

export default AddButton;