import React, { useState } from "react";
import TableBasic from "components/TableBasic/TableBasic";
import { useTranslation } from "react-i18next";
import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import dayjs from "dayjs";
import useGetLoginHistoryQuery from "hooks/useGetLoginHistoryQuery";
import { Box, Button, MenuItem, Select, TextField } from "@mui/material";
import _, { find } from "lodash";
import { loginHistoryResult } from "constans/Constans";
import ArrowTableHeader from "components/PageUsers/UsersList/Arrow";
import { headerSortTable } from "components/TableBasic/HeaderSortTable";
import { Loader } from "components/Loader/Loader";
import useGetLoginLogCsvQuery from "hooks/useGetLoginLogCsvQuery";
import { CSVLink } from "react-csv";
import CustomButton from "components/Buttons/CustomButton";
import { format } from "date-fns";

function LoginHistory() {
  const { t } = useTranslation();
  const [sortOptions, setSortOptions] = useState<GridSortModel>([]);
  const [selectValue, setSelectValue] = useState<string[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [CSVEnabled, setCSVEnabled] = useState(false);
  const [loginHistoryEnabled, setLoginHistoryEnabled] = useState(true);
  const [inputValues, setInputValues] = useState({
    fullName: undefined,
    login: undefined,
    loginDateTime: undefined,
    inet: undefined,
  });
  const queryValues = {
    page: pageNumber,
    itemsPerPage: itemsPerPage,
    fullName: inputValues.fullName,
    login: inputValues.login,
    loginDateTime: inputValues.loginDateTime,
    inet: inputValues.inet,
    "status[]": selectValue,
    "orderBy[fullName]":
      find(sortOptions, { field: "fullName" })?.sort || undefined,
    "orderBy[login]": find(sortOptions, { field: "login" })?.sort || undefined,
    "orderBy[date]":
      find(sortOptions, { field: "loginDateTime" })?.sort || "desc",
    "orderBy[inet]": find(sortOptions, { field: "inet" })?.sort || undefined,
    "orderBy[status]":
      find(sortOptions, { field: "status" })?.sort || undefined,
  };
  const { data: loginHistoryData, isLoading: loginHistoryLoading } =
    useGetLoginHistoryQuery(
      {
        query: queryValues,
      },
      {
        enabled: loginHistoryEnabled,
      }
    );
  const { data: CsvData, isLoading: CsvDataLoading } = useGetLoginLogCsvQuery(
    {
      query: queryValues,
    },
    {
      enabled: CSVEnabled,
      onSettled: () => {
        setCSVEnabled(false);
      },
    }
  );
  const handleChangeSelect = (option: string) => {
    setSelectValue((prevSelectedOptions) => {
      if (prevSelectedOptions && prevSelectedOptions.includes(option)) {
        return prevSelectedOptions.filter((o) => o !== option);
      } else {
        return [...prevSelectedOptions, option];
      }
    });
  };
  const filterRows = (value: string, columnName: string) => {
    setInputValues((inputValues) => ({
      ...inputValues,
      [columnName]: value,
    }));
  };
  const handleChange = (
    e: { target: { value: string } },
    columnName: string
  ) => {
    filterRows(e.target.value, columnName);
  };
  const options = loginHistoryResult.map((row) => ({
    name: row.name,
    nameValue: row.nameValue,
  }));

  const GetCsv = () => {
    setCSVEnabled(true);
  };
  const columns: GridColDef[] = [
    {
      flex: 0.2,
      minWidth: 200,
      field: "fullName",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box className="table-header-custom-styles">
          <div>
            <ArrowTableHeader
              onClick={() => {
                headerSortTable(sortOptions, setSortOptions, "fullName");
              }}
              direction={find(sortOptions, { field: "fullName" })?.sort || null}
            >
              {t("First Name and Last Name")}
            </ArrowTableHeader>
          </div>
          <TextField
            variant="standard"
            onChange={(e) => handleChange(e, "fullName")}
          />
        </Box>
      ),
    },
    {
      flex: 0.2,
      minWidth: 250,
      field: "login",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box className="table-header-custom-styles">
          <div>
            <ArrowTableHeader
              onClick={() => {
                headerSortTable(sortOptions, setSortOptions, "login");
              }}
              direction={find(sortOptions, { field: "login" })?.sort || null}
            >
              {t("Login")}
            </ArrowTableHeader>
          </div>
          <TextField
            variant="standard"
            onChange={(e) => handleChange(e, "login")}
          />
        </Box>
      ),
    },
    {
      flex: 0.15,
      minWidth: 150,
      field: "loginDateTime",
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (params: {
        value: string | number | Date | dayjs.Dayjs | null | undefined;
      }) => dayjs(params.value).format("YYYY-MM-DD   HH:mm"),
      renderHeader: () => (
        <Box className="table-header-custom-styles">
          <div>
            <ArrowTableHeader
              onClick={() => {
                headerSortTable(sortOptions, setSortOptions, "loginDateTime");
              }}
              direction={
                find(sortOptions, { field: "loginDateTime" })?.sort || null
              }
            >
              {t("Login Date")}
            </ArrowTableHeader>
          </div>
          <TextField
            variant="standard"
            onChange={(e) => handleChange(e, "loginDateTime")}
          />
        </Box>
      ),
    },
    {
      flex: 0.15,
      minWidth: 150,
      field: "inet",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box className="table-header-custom-styles">
          <div>
            <ArrowTableHeader
              onClick={() => {
                headerSortTable(sortOptions, setSortOptions, "inet");
              }}
              direction={find(sortOptions, { field: "inet" })?.sort || null}
            >
              {t("IP")}
            </ArrowTableHeader>
          </div>
          <TextField
            variant="standard"
            onChange={(e) => handleChange(e, "inet")}
          />
        </Box>
      ),
    },
    {
      flex: 0.15,
      minWidth: 350,
      field: "status",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: { value: string | undefined }) => {
        const filteredValues = _.filter(loginHistoryResult, {
          nameValue: params.value,
        });
        return _.map(filteredValues, "name").join(", ");
      },
      renderHeader: () => {
        return (
          <Box className="table-header-custom-styles-select">
            <div>
              <ArrowTableHeader
                onClick={() => {
                  headerSortTable(sortOptions, setSortOptions, "status");
                }}
                direction={find(sortOptions, { field: "status" })?.sort || null}
              >
                {t("Status")}
              </ArrowTableHeader>
            </div>
            <Select
              onBlur={(e) => e.stopPropagation()}
              multiple
              value={selectValue}
              sx={{ m: 0, width: "320px", pr: 4 }}
              variant="standard"
              renderValue={(selected) => (
                <div>
                  {selected.map((option) => {
                    const matchingObject = _.find(loginHistoryResult, {
                      nameValue: option,
                    });
                    return (
                      matchingObject && (
                        <span key={option}>{matchingObject.name} </span>
                      )
                    );
                  })}
                </div>
              )}
            >
              <MenuItem onClick={() => setSelectValue([])}>
                <b>{t("Any")}</b>
              </MenuItem>
              {options.map((option) => (
                <MenuItem
                  onClick={() => handleChangeSelect(option.nameValue)}
                  key={option.nameValue}
                  value={option.nameValue}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        );
      },
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <TableBasic
        paginationMode="server"
        columns={columns}
        loading={loginHistoryLoading}
        rows={loginHistoryData?.["hydra:member"]}
        title={t("Report On Activity Of System Users Work")}
        setItemsPerPage={setItemsPerPage}
        totalItems={loginHistoryData?.["hydra:totalItems"]}
        setPageNumber={setPageNumber}
        queryFunction={setLoginHistoryEnabled}
      />
      {CsvDataLoading && <Loader />}
      <CustomButton
        text={t("Generate CSV")}
        style={{ mx: 5, mb: 5, mt: 5 }}
        onClickFunction={() => GetCsv()}
      />
      {CsvData && !CsvDataLoading && (
        <CSVLink
          className={"csv-button-link"}
          data={String(CsvData)}
          filename={`log-logs_${format(
            new Date(),
            "yyyy-MM-dd HH:mm:ss"
          )}.csv`}
          target="_blank"
        >
          <Button
            color="inherit"
            variant="contained"
            className="button-custom-styles"
            sx={{ mx: 5, mb: 5, mt: 5 }}
          >
            {t("Download")}
          </Button>
        </CSVLink>
      )}
    </div>
  );
}

export default LoginHistory;
