import React, { useState } from "react";
import TableBasic from "components/TableBasic/TableBasic";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { operations } from "schema/schema";
import useBalancesQuery from "hooks/useBalancesQuery";
import dayjs from "dayjs";
import { formatValueDateTime } from "helpers/Global";
import UseHasAccess from "hooks/useHasAccess";
import { Role } from "helpers/Global";
import ActionsButton from "./ActionsButtonForPoints";
import EditBalanceModal from "components/PageEditUser/Points/EditBalanceModal";
import { BalancesTypes } from "types/types";

interface UserPointsTypes {
  userInformationData:
    | operations["api_apiv1users_id_get"]["responses"]["200"]["content"]["application/ld+json"]
    | undefined;
}
function PointsTableSubPage({ userInformationData }: UserPointsTypes) {
  const { t } = useTranslation();
  const userId = String(userInformationData?.id);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pointsEnabled, setPointsEnabled] = useState(true);
  const [openEditBalanceModal, setOpenEditBalanceModal] = useState(false);
  const [balanceRowData, setBalanceRowData] = useState<BalancesTypes>();
  const hasAccess = UseHasAccess([Role.ROLE_SUPER_USER]).length > 0;
  const { data: pointsData, refetch, isLoading: pointsDataLoading } = useBalancesQuery(
    {
      query: {
        user: userId,
        page: pageNumber,
        itemsPerPage: itemsPerPage,
        "order[operationDate]": "desc",
      },
    },
    {
      enabled: pointsEnabled,
      onSettled: () => {
        setPointsEnabled(false);
      },
    }
  );

  const columns: GridColDef[] = [
    {
      flex: 0.1,
      field: "paymentTitle",
      minWidth: 300,
      headerName: t("Paid Title / Name Of Withdrawal") as string,
    },
    {
      flex: 0.1,
      field: "points",
      minWidth: 5,
      headerName: t("Points") as string,
    },
    {
      flex: 0.1,
      field: "introduced",
      minWidth: 50,
      headerName: t("Introduced") as string,
    },
    {
      flex: 0.1,
      field: "operationDate",
      minWidth: 80,
      headerName: t("Date") as string,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format(formatValueDateTime) : "",
    },
    {
      flex: 0.1,
      minWidth: 90,
      hide: !hasAccess,
      sortable: false,
      disableColumnMenu: true,
      field: "actions",
      headerName: t("Actions") as string,
      renderCell: ({ row }) =>
        hasAccess && (
          <ActionsButton id={row.id} 
          refetch={refetch} 
          setOpenEditBalanceModal={setOpenEditBalanceModal}
          rowData={row}
          setBalanceRowData={setBalanceRowData}
          />
        ),
    },
  ];
  return (
    <Grid item xs={12}>
      <TableBasic
        paginationMode="server"
        columns={columns}
        loading={pointsDataLoading}
        totalItems={pointsData?.["hydra:totalItems"]}
        queryFunction={setPointsEnabled}
        initialSortingTable={"operationDate"}
        setItemsPerPage={setItemsPerPage}
        setPageNumber={setPageNumber}
        rows={pointsData?.["hydra:member"]}
        title={t("Points")}
      />
      <EditBalanceModal
          refetch={refetch}
          showBalanceModal={openEditBalanceModal}
          setShowBalanceModal={setOpenEditBalanceModal}
          balanceRowData={balanceRowData}
      />
    </Grid>
  );
}

export default PointsTableSubPage;
