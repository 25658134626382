import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { operations, components } from "schema/schema";

type Response = string;
type Id = {
  id: string;
};

const getUserEmailQuery = async (id: Id) => {
  const { data } = await getApiClient().get(id.id);
  return data.email;
};

export default function useGetUserEmailQuery(
  id: Id,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [id],
    () => getUserEmailQuery(id),
    options
  );
}
