import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { components } from "schema/schema";

const path = "/api/v1/p24_transactions/";

type Payload = components["schemas"]["P24Transaction.jsonld"];

type Params = {
  payload: Payload;
	TransactionCodeId: number | undefined;
};

const putTransaction = async ({ payload, TransactionCodeId }: Params) => {
  const { data } = await getApiClient().put(`${path}${TransactionCodeId}`, payload);
  return data;
};

export default function usePutTransactionMutation(
  options: UseMutationOptions<Response, AxiosError, Params> = {}
) {
  return useMutation<Response, AxiosError, Params>(putTransaction, options);
}
