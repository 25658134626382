
import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { components } from "schema/schema";

const path = "/api/v1/balances";
type BalanceId = components["schemas"]["Balance.jsonld"]["id"];

const putDeleteBalance = async (balanceId: BalanceId) => {

  const { data } = await getApiClient().delete(`${path}/${balanceId}`);
  
  return data;
};

export default function useDeleteBalanceMutation(
  options: UseMutationOptions<void, AxiosError, BalanceId> = {}
) {
  return useMutation<void, AxiosError, BalanceId>(putDeleteBalance, options);
}