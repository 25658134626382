import React, { useState } from "react";
import TableBasic from "components/TableBasic/TableBasic";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import useGetCrmsQuery from "hooks/useGetCrmsQuery";
import { operations } from "schema/schema";
import dayjs from "dayjs";
import { formatValueDateTime, Status } from "helpers/Global";
import UseHasAccess from "hooks/useHasAccess";
import { Role } from "helpers/Global";
import ActionsButton from "./ActionsButton";
import AddButton from "./AddButton";
import EditChangeHistoryModal from "components/PageEditUser/TableSubpages/ChangeHistory/EditChangeHistoryModal";
import AddChangeHistoryModal from "./AddChangeHistoryModal";
import { CrmsTypes } from "types/types";

interface UserChangeHistoryTypes {
  userInformationData:
    | operations["api_apiv1users_id_get"]["responses"]["200"]["content"]["application/ld+json"]
    | undefined;
}

function ChangeHistory({ userInformationData }: UserChangeHistoryTypes) {
  const hasAccess = UseHasAccess([Role.ROLE_SUPER_USER]).length > 0;
  const userId = String(userInformationData?.id);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [historyEnabled, setHistoryEnabled] = useState(true);
  const [openEditChangeHistoryModal, setOpenEditChangeHistoryModal] = useState(false);
  const [openAddChangeHistoryModal, setOpenAddChangeHistoryModal] = useState(false);
  const [changeHistoryRowData, setChangeHistoryRowData] = useState<CrmsTypes>();
  const { data: changeHistory, refetch, isLoading: userChangeHistoryLoading } =
    useGetCrmsQuery(
      {
        query: {
          toUser: userId,
          type: "30",
          "status[]": [Status.Status_0, Status.Status_1, Status.Status_3],
          "order[createdAt]": "desc",
          page: pageNumber,
          itemsPerPage: itemsPerPage,
        },
      },
      {
        enabled: historyEnabled,
        onSettled: () => {
          setHistoryEnabled(false);
        },
      }
    );
  const { t } = useTranslation();
  const columns: GridColDef[] = [
    {
      flex: 0.1,
      field: "createdAt",
      minWidth: 150,
      headerName: t("Date") as string,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format(formatValueDateTime) : "",
    },
    {
      flex: 0.1,
      field: "content",
      minWidth: 400,
      headerName: t("Change Content") as string,
    },
    {
      flex: 0.1,
      field: "author",
      valueFormatter: (params) => {
        return params.value == null ? "System" : params.value;
      },
      minWidth: 50,
      headerName: t("Author") as string,
    },
    {
      flex: 0.1,
      minWidth: 90,
      hide: !hasAccess,
      sortable: false,
      disableColumnMenu: true,
      field: "actions",
      headerName: t("Actions") as string,
      renderCell: ({ row }) =>
        hasAccess && (
          <ActionsButton id={row.id} refetch={refetch} 
          setOpenEditChangeHistoryModal={setOpenEditChangeHistoryModal}
          rowData={row}
          setChangeHistoryRowData={setChangeHistoryRowData}
          />
        ),
    },
  ];

  return (
    <Grid item xs={12}>
      <AddButton 
        refetch={refetch} 
        setOpenAddChangeHistoryModal={setOpenAddChangeHistoryModal}
      />
      <TableBasic
        paginationMode="server"
        columns={columns}
        loading={userChangeHistoryLoading}
        rows={changeHistory?.["hydra:member"]}
        title={t("Change History")}
        totalItems={changeHistory?.["hydra:totalItems"]}
        setItemsPerPage={setItemsPerPage}
        setPageNumber={setPageNumber}
        queryFunction={setHistoryEnabled}
      />
      <EditChangeHistoryModal
          refetch={refetch}
          showChangeHistoryModal={openEditChangeHistoryModal}
          setShowChangeHistoryModal={setOpenEditChangeHistoryModal}
          changeHistoryRowData={changeHistoryRowData}
      />
      <AddChangeHistoryModal
          refetch={refetch}
          showChangeHistoryModal={openAddChangeHistoryModal}
          setShowChangeHistoryModal={setOpenAddChangeHistoryModal}
      />
    </Grid>
  );
}

export default ChangeHistory;
