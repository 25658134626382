import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { formatValueDatepicker } from "helpers/Global";
import EventIcon from "@mui/icons-material/Event";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { Loader } from "components/Loader/Loader";
import useGetUserEmailQuery from "hooks/useGetUserEmailQuery";
import usePutContributionCodeMutation from "hooks/usePutContributionCodeMutation";
import CustomButton from "components/Buttons/CustomButton";
import { map, uniqBy } from "lodash";
import useGetUserFilterParametersQuery from "hooks/useGetUserFilterParametersQuery";
import { ContributionTypes } from "types/types";
import FormHelperText from "@mui/material/FormHelperText";

export const useStyles = makeStyles(() => ({
  textFieldCustom: {
    margin: " 15px 25px",
  },
}));
interface FormInputs {
  dateFrom: string;
  dateTo: string | undefined;
  email: string;
  withoutExpiration?: boolean;
}
interface EditContributionCodeTypes {
  contributionRowData: ContributionTypes | undefined;
  refetch: () => void;
}

function EditContributionCode({ refetch, contributionRowData }: EditContributionCodeTypes) {
  const [valuesLoaded, setValuesLoaded] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [messageError, setMessageError] = useState();
  const { mutate: putContributionMutation, isLoading: putContributionCodeLoading } = usePutContributionCodeMutation();
  const { data: email } = useGetUserEmailQuery(
    {
      "id": `${contributionRowData?.user}`,
    },
    {
      enabled: !!contributionRowData?.user
    }
  );
  const { data: userTypesData } = useGetUserFilterParametersQuery(
    {
      query: { email: inputValue },
    },
    {
      enabled: inputValue.length > 1,
    }
  );
  const { data: user } = useGetUserFilterParametersQuery(
    {
      query: { emailExact: inputValue }
    },
    {
      enabled: inputValue.length > 1,
    }
  )
  const schema = yup.object().shape({
    email: yup
      .string()
      .typeError(t("Message Error Wrong User") as string),
    dateFrom: yup
      .date()
      .typeError(t("Enter The Correct Date") as string),
    dateTo: yup
      .date()
      .typeError(t("Enter The Correct Date") as string),
  });  
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      dateFrom: contributionRowData?.dateFrom != null ? contributionRowData?.dateFrom : undefined,
      dateTo: contributionRowData?.dateTo != null ? contributionRowData?.dateTo : undefined,
      email: email,
    },
  });
  const onSubmit = ({
    dateFrom,
    dateTo,
  }: FormInputs) => {
    putContributionMutation(
      {
        payload: {
          dateFrom: dateFrom ?? undefined,
          dateTo: dateTo === null ? undefined : dateTo,
          user: (user?.["hydra:member"].length === 1)
            ? "/api/v1/users/" + user?.["hydra:member"][0]?.id
            : "",
        },
        ContributionCodeId: contributionRowData?.id,
      },
      {
        onSuccess: () => {
          setOpenError(false);
          setOpenSuccess(true);
          refetch();
        },
        onError: (error: any) => {
          setOpenSuccess(false);
          setOpenError(true);
          setMessageError(error.response?.data["hydra:description"]);
        },
      }
    );
  };
  const { withoutExpiration } = useWatch({ control: control });
  useEffect(() => {
    if (valuesLoaded === false) {
      if (email && inputValue === "") {
        setInputValue(email);
        setValuesLoaded(true);
      }
    }
  }, [email, user]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {putContributionCodeLoading && <Loader />}
      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={t("Language Code") as string}
        >
          <FormControl className={classes.textFieldCustom}>
            <Autocomplete
              noOptionsText={t("None")}
              sx={{ width: 450 }}
              onInputChange={(_, value) => setInputValue(value)}
              options={
                map(
                  uniqBy(userTypesData?.["hydra:member"], "email"),
                  (userType: any) => userType.email
                ) || []
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("User")}
                  variant="outlined"
                />
              )}
              value={inputValue}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
            />
            {errors.email && (
              <FormHelperText sx={{ color: "error.main" }}>
                {errors.email.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl className={classes.textFieldCustom}>
            <Controller
              control={control}
              name="dateFrom"
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value}
                  onChange={onChange}
                  label={t("Date From")}
                  inputFormat={formatValueDatepicker}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EventIcon />
                      </InputAdornment>
                    ),
                  }}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "170px", mx: 2 }} />
                  )}
                />
              )}
            />
            {errors.dateFrom && (
              <FormHelperText sx={{ color: "error.main" }}>
                {errors.dateFrom.message}
              </FormHelperText>
            )}
          </FormControl>
          {(contributionRowData?.dateTo !== "9999-12-31T00:00:00+00:00" ||
            !withoutExpiration) && (
              <FormControl className={classes.textFieldCustom}>
                <Controller
                  control={control}
                  name="dateTo"
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      value={value}
                      onChange={onChange}
                      disabled={withoutExpiration}
                      label={t("Expiration Date")}
                      inputFormat={formatValueDatepicker}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EventIcon />
                          </InputAdornment>
                        ),
                      }}
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: "170px", mx: 2 }} />
                      )}
                    />
                  )}
                />
                {errors.dateTo && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {errors.dateTo.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
        </LocalizationProvider>
      </Box>
      <CustomButton
        text={t("You Save")}
        type="submit"
        style={{ mx: 5, mb: 5, mt: 5 }}
      />
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Edit Contribution")}
        successMessage={t("Message Success Edit Contribution")}
      />
    </form>
  );
}

export default EditContributionCode;
