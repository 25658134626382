import React, { useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import {
  Box,
  Button,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSettings } from "hooks/useSettings";
import { makeStyles } from "@material-ui/core/styles";
import EditSubscriber from "components/PageEditSubscriptions/EditSubscriber";
import MultiEditingSubscribers from "components/PageEditSubscriptions/MultiEditingSubscribers";
import { map } from "lodash";
import useGetNewsletterSubscribersTotalItemsQuery from "hooks/useGetNewsletterSubscribersTotalItemsQuery";
import useGetNewsletterSubscriberCsvQuery from "hooks/useGetNewsletterSubscriberCsvQuery";
import { CSVLink } from "react-csv";
import { Loader } from "components/Loader/Loader";
import CustomButton from "components/Buttons/CustomButton";
import { format } from "date-fns";

export const useStyles = makeStyles(() => ({
  tableCellShort: {
    paddingTop: "0.6rem !important",
    paddingBottom: "0.6rem !important",
  },
}));

function EditSubscriptions() {
  const { t } = useTranslation();
  const { settings } = useSettings();
  const classes = useStyles();
  const [successEditSubscription, setSuccessEditSubscription] = useState(false);
  const [CSVEnabled, setCSVEnabled] = useState(false);
  const { data: newsletterTypesData, refetch } =
    useGetNewsletterSubscribersTotalItemsQuery(
      {
        query: {},
      },
      {
        onSettled: () => {
          setSuccessEditSubscription(false);
        },
      }
    );
  const { data: csvData, isLoading: csvDataLoading } =
    useGetNewsletterSubscriberCsvQuery({
      enabled: CSVEnabled,
      onSettled: () => {
        setCSVEnabled(false);
      },
    });
  const GetCsv = () => {
    setCSVEnabled(true);
  };
  if (successEditSubscription) {
    refetch();
  }

  return (
    <Card
      sx={{
        backgroundColor: settings.mode === "light" ? "inherit" : "#28243D",
        overflow: "unset",
      }}
    >
      <CardHeader title={t("Edit Subscriptions")} />
      <Divider />
      <Box sx={{ mx: 5 }}>
        <Typography>{t("Statistics")}</Typography>
        <Table sx={{ maxWidth: "400px", my: 5 }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellShort}>
                {t("Name")}
              </TableCell>
              <TableCell className={classes.tableCellShort}>
                {t("Value")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(
              newsletterTypesData?.["hydra:member"],
              (subscription, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.tableCellShort}>
                    {subscription.newsletterTypeName}
                  </TableCell>
                  <TableCell className={classes.tableCellShort}>
                    {subscription.value}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </Box>
      <Divider />
      <Box sx={{ m: 5 }}>
        <EditSubscriber
          setSuccessEditSubscription={setSuccessEditSubscription}
        />
      </Box>
      <Divider />
      <Box sx={{ m: 5 }}>
        <MultiEditingSubscribers
          setSuccessEditSubscription={setSuccessEditSubscription}
        />
      </Box>
      <Box>
        {csvDataLoading && <Loader />}
        <CustomButton
          text={t("Generate CSV")}
          style={{ mx: 5, mb: 5, mt: 5 }}
          onClickFunction={() => GetCsv()}
        />
        {csvData && !csvDataLoading && (
          <CSVLink
            className={"csv-button-link"}
            data={String(csvData)}
            filename={`newsletter-subscribers_${format(
              new Date(),
              "yyyy-MM-dd HH:mm:ss"
            )}.csv`}
            target="_blank"
          >
            <Button
              color="inherit"
              variant="contained"
              className="button-custom-styles"
              sx={{ mx: 5, mb: 5, mt: 5 }}
            >
              {t("Download")}
            </Button>
          </CSVLink>
        )}
      </Box>
    </Card>
  );
}

export default EditSubscriptions;
