import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { operations } from "schema/schema";

const path = "api/v1/balance/balance_between_date_csv";
const queryKey = "balanceListCSV";

type Response =
  operations["balanceBetweeenDateCSV"]["responses"]["200"]["content"]["application/ld+json"];
type Parameter = operations["balanceBetweeenDateCSV"]["parameters"];

const getBalanceListCsvQuery = async (parameters: Parameter) => {
  const { data } = await getApiClient().get(path, {
    params: parameters?.query,
  });
  return data;
};

export default function useGetBalanceListCsvQuery(
  parameters: Parameter,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey, parameters],
    () => getBalanceListCsvQuery(parameters),
    options
  );
}
