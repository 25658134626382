import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { components } from "schema/schema";

const path = "/api/whoami";
export const queryKey = "whoami";

type Response = components["schemas"]["UserShortInfo"];

const getWhoIam = async () => {
  const { data } = await getApiClient().get(path);
  return data;
};

export default function useWhoIAmQuery(
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(queryKey, () => getWhoIam(), options);
}
