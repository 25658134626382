import React, { useState, MouseEvent, useEffect } from "react";
import { Box, Button, Card, Dialog, useMediaQuery, FormControl, FormControlLabel, CardHeader } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled, useTheme } from "@mui/material/styles";
import CustomButton from "components/Buttons/CustomButton";
import { useTranslation } from "react-i18next";
import usePostNewsletterTestSendMutation from "hooks/usePostNewsletterTestSendMutation";
import usePostNewsletterSendMutation from "hooks/usePostNewsletterSendMutation";
import { FormControlProps } from "@mui/material/FormControl";
import { Controller, useForm, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";

interface ViewNewsletterMailModalTypes {
  showNewsletterMailModal: boolean;
  setShowNewsletterMailModal: (val: boolean) => void;
  formData: any;
  newsletterId: any;
  refetch: any;
  disableSend: boolean;
};
const FormControlCustom = styled(FormControl)<FormControlProps>(
  ({ theme }) => ({
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 260,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: 500,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 800,
    },
    margin: "0.5rem 1.25rem",
  })
);

function ViewNewsletterMailModal({
  showNewsletterMailModal,
  setShowNewsletterMailModal,
  formData,
  newsletterId,
  refetch,
  disableSend,
}: ViewNewsletterMailModalTypes) {
  const theme = useTheme();
  const { control, getValues } = useForm();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const { mutate: postTestMutate } = usePostNewsletterTestSendMutation();
  const { mutate: postSendMutate } = usePostNewsletterSendMutation();
  const [action, setAction] = useState({});
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [messageError, setMessageError] = useState();
  const handleTest = () => {
    const testEmail = getValues("receiversTest");
    console.log(testEmail);
    postTestMutate(
      {
        payload: {
          newsletterHistoryId: newsletterId,
          receivers: testEmail,
          senderEmail: formData.senderEmail,
          senderName: formData.senderName,
          title: formData.title,
          replyEmail: formData.reply,
          replyName: formData.replyName,
          contentHtml: formData.contentHtml,
          contentTxt: formData.contentTxt,
        }
      },
      {
        onSuccess: () => {
          setOpenError(false);
          setOpenSuccess(true);
        },
        onError: (error: any) => {
          setOpenSuccess(false);
          setOpenError(true);
          setMessageError(error.response?.data["hydra:description"]);
        },
      }
    )
  }

  const handleSend = () => {
    const sendEmail = getValues("receiversSend");
    console.log(sendEmail);
    postSendMutate(
      {
        payload: {
          newsletterHistoryId: newsletterId,
          receivers: sendEmail,
        }
      },
      {
        onSuccess: () => {
          setOpenError(false);
          setOpenSuccess(true);
          refetch();
        },
        onError: (error: any) => {
          setOpenSuccess(false);
          setOpenError(true);
          setMessageError(error.response?.data["hydra:description"]);
        },
      }
    )
  }

  return (
    <>
      <Dialog
        open={showNewsletterMailModal}
        BackdropProps={{
          style: {
            backgroundColor: theme.palette.customColors.modalBgc,
          },
        }}
      >
        <Box
          className="array-modal"
          sx={{
            width: onlyMediumScreen ? "90vw" : "70vw",
            minHeight: 150,
            px: onlySmallScreen ? 5 : "unset",
          }}
        >
          <Button
            className="array-close-button"
            onClick={() => setShowNewsletterMailModal(false)}
          >
            <CloseIcon />
          </Button>
          <Card
            sx={{
              minHeight: 10,
              px: onlySmallScreen ? "unset" : "50px",
              py: 5,
              fontSize: 'default',
              justifyContent: "center",
              alignItems: "center",
              overflow: 'auto',
              borderRadiusTopLeft: "0",
            }}
          >
            <div>
              <CardHeader title={t("Send Mail")} />
            </div>
            <div style={{ textAlign: 'center' }}>
              <strong style={{ textAlign: 'center', marginBottom: '15px', fontSize: '35px' }}>{t("Title")}: {formData.title}</strong>
            </div>

          </Card>
          <Card
            sx={{
              minHeight: 100,
              maxHeight: 500,
              px: onlySmallScreen ? "unset" : "50px",
              py: 0,
              fontSize: 'default',
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              overflow: 'auto',
            }}
          >
            <div style={{ margin: "auto", display: "flex" }}>
              <FormControlCustom>
                <Controller
                  control={control}
                  name="receiversTest"
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      onChange={onChange}
                      type="text"
                      label={`${t("Email For Test")}`}
                    />
                  )}
                />
              </FormControlCustom>
              <CustomButton text={t("Test")} style={{ m: 1, mt: 2, mb: 5 }} type="submit" onClickFunction={() => handleTest()} />
            </div>
            <div style={{ margin: "auto", display: "flex" }}>
              <FormControlCustom>
                <Controller
                  control={control}
                  name="receiversSend"
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      onChange={onChange}
                      type="text"
                      label={`${t("Email For Send")}`}
                    />
                  )}
                />
              </FormControlCustom>
              <CustomButton text={t("Send")} style={{ m: 1, mt: 2, mb: 5 }} isDisabled={disableSend ? true : false} type="submit" onClickFunction={() => handleSend()} />
            </div>
          </Card>
          <Card
            sx={{
              minHeight: 80,
              maxHeight: 80,
              px: onlySmallScreen ? "unset" : "50px",
              py: 0,
              fontSize: 'default',
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              overflow: 'auto',
            }}
          >
            <CustomButton
              text={t("Back To View")}
              style={{ mt: 2 }}
              type="submit"
              onClickFunction={() => {
                refetch({
                  query: {
                    status: "",
                    id: newsletterId,
                  }
                });
                setShowNewsletterMailModal(false);
                setAction("Back To View");
              }}
            />
            <ChangeParametersModal
              openSuccess={openSuccess}
              openError={openError}
              setOpenSuccess={setOpenSuccess}
              setOpenError={setOpenError}
              errorMessage={t("Message Error Send Mail")}
              successMessage={t("Message Success Send Mail")}
            />
          </Card>
        </Box>
      </Dialog >
    </>
  );
}
export default ViewNewsletterMailModal;
