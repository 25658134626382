import { useQuery, UseQueryOptions } from "react-query";
import { API_PREFIX, getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { components, operations } from "schema/schema";

const path = `${API_PREFIX}/newsletter_histories/file/show/`
type Response =  components["schemas"]["NewsletterHistory.NewsletterFileNamesDto.jsonld"];
type Id = {
  id: number;
};

const getNewsletterHistoriesFileQuery = async (id: Id) => {
  
  const { data } = await getApiClient().get(path + id.id);
  return data;
};

export default function useGetNewsletterHistoriesFileQuery(
  id: Id,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [id],
    () => getNewsletterHistoriesFileQuery(id),
    options
  );
}
