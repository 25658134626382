import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { components } from "schema/schema";

type TransactionId = components["schemas"]["P24Transaction.jsonld"]["@id"];

const putDeleteTransactions = async (TransactionId: TransactionId) => {
  const { data } = await getApiClient().delete(`${TransactionId}`);
  return data;
};

export default function useDeleteTransactionMutation(
  options: UseMutationOptions<void, AxiosError, TransactionId> = {}
) {
  return useMutation<void, AxiosError, TransactionId>(putDeleteTransactions, options);
}
