import React, { useState } from "react";
import { Box, useMediaQuery, FormControl, Typography } from "@mui/material";
import TableBasic from "components/TableBasic/TableBasic";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import useGetAdsByIdQuery from "hooks/useGetAdByIdQuery";
import useGetAdHistoryCvstatsQuery from "hooks/useGetAdHistoryCvstatsQuery";
import { useLocation } from "react-router-dom";
import { useTheme, styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import { Divider } from "@mui/material";
import { useSettings } from "hooks/useSettings";
import dayjs from "dayjs";
import { FormControlProps } from "@mui/material/FormControl";
import { Controller, useForm, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import CustomButton from "components/Buttons/CustomButton";
import { useNavigate } from "react-router-dom";
import usePostSendAmazonEmailMutation from "hooks/usePostSendAmazonEmailMutation";
import { Loader } from "components/Loader/Loader";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormHelperText from "@mui/material/FormHelperText";

export interface FormInputs {
  title: string;
  sender: string;
  receiver: string;
}
const FormControlCustom = styled(FormControl)<FormControlProps>(
  ({ theme }) => ({
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingRight: 5,
      maxWidth: 260,
      margin: "0.5rem 0rem",
    },
    [theme.breakpoints.down("md")]: {
      paddingRight: 5,
      maxWidth: 500,
      margin: "0.5rem 0rem",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 800,
      margin: "0.5rem 1.25rem",
    }
  })
);

function AdsDetails() {
  const { t } = useTranslation();
  const location = useLocation();
  const { settings } = useSettings();
  const theme = useTheme();
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [adCvstatsEnabled, setAdCvstatsEnabled] = useState(true);
  const schema = yup.object().shape({
    title: yup.string()
      .required(t('This Field is Required') as string)
      .max(255, t('Maximum 255 Characters') as string)
      .notOneOf([t("Campaign Summary") as string], t("Modify Campaign Summary") as string),
    sender: yup.string()
      .required(t('This Field is Required') as string)
      .max(255, t('Maximum 255 Characters') as string),
    receiver: yup.string()
      .required(t('This Field is Required') as string)
      .email(t('Invalid Email') as string)
      .typeError(t('Invalid Email') as string)
      .max(255, t('Maximum 255 Characters') as string),
  });
  const { control: control, handleSubmit, formState: { errors } } = useForm<FormInputs>({
    resolver: yupResolver(schema), defaultValues: {
      title: t("Campaign Summary") as string
    }
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const { data: AdsDetails, isLoading: AdsDetailsLoading } = useGetAdsByIdQuery(
    {
      id: location.state?.id,
    }
  );
  const { data: AdCvstats, isLoading: AdCvstatsLoading } =
    useGetAdHistoryCvstatsQuery(
      {
        query: {
          page: pageNumber,
          itemsPerPage: itemsPerPage,
          "order[id]": "desc",
          ad: location.state?.id,
        },
      },
      {
        enabled: adCvstatsEnabled,
      }
    );
  const [textError, setTextError] = useState("");
  const [textSuccess, setTextSuccess] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [messageError, setMessageError] = useState();
  let adTable = "";
  if (AdCvstats && AdCvstats["hydra:member"]) {
    const adTable = AdCvstats?.["hydra:member"].reduce((accumulator, item) => {
      return accumulator +
        `<tr><th>${dayjs(item.date).format(
          "YYYY-MM-DD   HH:mm"
        )}</th><th>${item.click}</th><th>${item.view
        }</th><th>${item.ctr}</th></tr>`;
    }, "");
  }
  let contentHtml = `
    <p>
      <strong>${t("Information About Ad")}</strong> ${AdsDetails?.adInfo}
    </p>
    <p>
      <strong>${t("Start Date Of Emmision")}</strong>
      ${dayjs(AdsDetails?.beginDate).format("YYYY-MM-DD HH:mm")}
    </p>
    <p>
      <strong>${t("End Date Of Emmision")}</strong>
      ${AdsDetails?.endDate
      ? dayjs(AdsDetails?.endDate).format("YYYY-MM-DD HH:mm")
      : t("Indefinite")
    }
    </p>
    <p>
      <strong>${t("Number Of Ad Views")}</strong> ${AdsDetails?.view}
    </p>
    <p>
      <strong>${t("Number Of Clicks On Ad")}</strong> ${AdsDetails?.click}
    </p>
    <p>
      <strong>${t("Ratio Of The Campaign")}</strong> ${AdsDetails?.ctr}%
    </p>
    <p>
      <strong>${t("Url Adress")}</strong> ${AdsDetails?.url}
    </p>
    <br>
    <p><strong>${t("Ad Cvstats")}:</strong></p>	
    ${adTable !== ""
      ? `<table>
          <tr style="border:1px solid black">
            <th>${t("Day")}  </th>
            <th>${t("Clicks")}  </th>
            <th>${t("Views")}  </th>
            <th>${t("Ratio")}%  </th>
          </tr>
          ${adTable}
        </table>`
      : `${t("No Data")}`
    }
  `;


  const onSubmit = ({
    sender,
    receiver,
    title
  }: FormInputs) => {

    const handlePost = () => {
      setIsLoading(true);
      postMutate(
        {
          payload: {
            receivers: receiver,
            contentHtml: contentHtml,
            contentTxt: "Raport reklamy",
            senderName: sender,
            title: title,
          },
        },
        {
          onSuccess: () => {
            setIsLoading(false);
            setOpenError(false);
            setOpenSuccess(true);
            setTextSuccess(`${t("Message Success Send Report")}`);
          },
          onError: (error: any) => {
            setIsLoading(false);
            setOpenSuccess(false);
            setOpenError(true);
            setMessageError(error.response?.data["hydra:description"]);
            setTextError(`${t("Message Error Send Report")}`);
          },
        }
      );
    };
    handlePost();
  };

  const { mutate: postMutate } = usePostSendAmazonEmailMutation();


  const columns: GridColDef[] = [
    {
      flex: 0.2,
      minWidth: 100,
      field: "date",
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (params: {
        value: string | number | Date | dayjs.Dayjs | null | undefined;
      }) => dayjs(params.value).format("YYYY-MM-DD"),
      renderHeader: () => (
        <Box>
          <div>{t("Day")}</div>
        </Box>
      ),
    },
    {
      flex: 0.2,
      minWidth: 110,
      field: "click",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box>
          <div>{t("Clicks")}</div>
        </Box>
      ),
    },
    {
      flex: 0.2,
      minWidth: 110,
      field: "view",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box>
          <div>{t("Views")}</div>
        </Box>
      ),
    },
    {
      flex: 0.2,
      minWidth: 110,
      field: "ctr",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box>
          <div>{t("Ratio %")}</div>
        </Box>
      ),
    },
  ];

  let url = window.location.href;
  let segments = url.split("/");
  segments.pop();
  let newUrl = segments.join("/");

  return (
    <Card
      sx={{
        backgroundColor: settings.mode === "light" ? "inherit" : "#28243D",
      }}
    >
      <CardHeader title={t("Detailed Report")} />
      <Divider />
      {AdsDetailsLoading}
      <div
        style={{ marginLeft: "20px", flexWrap: "wrap", wordWrap: "break-word" }}
      >
        <p>
          {t("Information About Ad")} {AdsDetails?.adInfo}
        </p>
        <p>
          {t("Start Date Of Emmision")}{" "}
          {dayjs(AdsDetails?.beginDate).format("YYYY-MM-DD HH:mm")}
        </p>
        <p>
          {t("End Date Of Emmision")}{" "}
          {AdsDetails?.endDate
            ? dayjs(AdsDetails?.endDate).format("YYYY-MM-DD HH:mm")
            : `${t("Indefinite")}`}
        </p>
        <p>
          {t("Number Of Ad Views")} {AdsDetails?.view}
        </p>
        <p>
          {t("Number Of Clicks On Ad")} {AdsDetails?.click}
        </p>
        <p>
          {t("Ratio Of The Campaign")} {AdsDetails?.ctr}%
        </p>
        <p>
          {t("Url Adress")} {AdsDetails?.url}
        </p>
      </div>
      <div style={{ height: 500, width: "100%" }}>
        <TableBasic
          styles={{ height: "400px" }}
          paginationMode="server"
          columns={columns}
          initialSortingTable={"adTypes"}
          loading={AdCvstatsLoading}
          rows={AdCvstats?.["hydra:member"]}
          title={t("Ad Cvstats")}
          setItemsPerPage={setItemsPerPage}
          totalItems={AdCvstats?.["hydra:totalItems"]}
          setPageNumber={setPageNumber}
          queryFunction={setAdCvstatsEnabled}
          customPageSize={itemsPerPage}
        />
      </div>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <CardHeader title={t("File preview")} />
          <Divider />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <p>
            {t("Static Graphic")} {AdsDetails?.fileName}
          </p>
          <img
            src={
              newUrl +
              "/img/Ads/" +
              dayjs(AdsDetails?.beginDate).year() +
              "/" +
              AdsDetails?.fileName
            }
            alt={`${t("There Is No File With Static Graphic")}`}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <p>
            {t("Animated Graphic")} {AdsDetails?.fileNameDesktop}
          </p>
          <img
            src={
              newUrl +
              "/img/Ads/" +
              dayjs(AdsDetails?.beginDate).year() +
              "/" +
              AdsDetails?.fileNameDesktop
            }
            alt={`${t("There Is No File With Animated Graphic")}`}
          />
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          sx={{
            minHeight: 100,
            maxHeight: 900,
            paddingLeft: "5px",
            fontSize: "default",
            justifyContent: "center",
            alignItems: "center",
            paddingRight: onlySmallScreen ? "unset" : "50px",
            flexWrap: "wrap",
            py: 0,
            overflow: "auto",
          }}
        >
          <Typography sx={{ ml: 5, mt: 5 }}>{t("Send By E-mail")}</Typography>
          <Divider />
          <FormControlCustom>
            <Controller
              control={control}
              name="title"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  type="text"
                  label={`${t("Title")}`}
                  error={!!errors.title}
                />
              )}
            />
            {errors.title && (
              <FormHelperText sx={{ color: "error.main" }}>
                {errors.title.message}
              </FormHelperText>
            )}
          </FormControlCustom>
          <FormControlCustom>
            <Controller
              control={control}
              name="sender"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  type="text"
                  label={t("Sender")}
                  error={!!errors.sender}
                />
              )}
            />
            {errors.sender && (
              <FormHelperText sx={{ color: "error.main" }}>
                {errors.sender.message}
              </FormHelperText>
            )}
          </FormControlCustom>
          <FormControlCustom>
            <Controller
              control={control}
              name="receiver"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  type="text"
                  label={`${t("Receiver")}`}
                  error={!!errors.receiver}
                />
              )}
            />
            {errors.receiver && (
              <FormHelperText sx={{ color: "error.main" }}>
                {errors.receiver.message}
              </FormHelperText>
            )}
          </FormControlCustom>
          <CustomButton
            text={t("Send")}
            style={{ m: 2, height: "60px" }}
            type="submit"
          />
          {isLoading ? <Loader /> : null}
        </Card>
      </form>
      <CustomButton
        text={t("Go To Edit")}
        style={{ m: 5, height: "60px" }}
        onClickFunction={() => {
          navigate("/ads-edit", {
            state: {
              ad: AdsDetails,
            },
          });
        }}
      />
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={textError}
        successMessage={textSuccess}
      />
    </Card>
  );
}

export default AdsDetails;
