import React, { useState, MouseEvent } from "react";
import { PencilOutline } from "mdi-material-ui";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DotsVertical from "mdi-material-ui/DotsVertical";
import DeleteOutline from "mdi-material-ui/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import useDeleteBalanceMutation from "hooks/useDeleteBalanceMutation";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import TwoStepsAcceptModal from "components/TwoStepsAcceptModal/TwoStepsAcceptModal";
import { operations } from "schema/schema";

interface ActionsButtonType {
  id: number;
  refetch:()=> void;
  setOpenEditBalanceModal: (value: boolean) => void;
  rowData: operations["api_apiv1balances_get_collection"]["responses"]["200"]["content"]["application/ld+json"];
  setBalanceRowData: any;
}

const ActionsButtonForPoints = ({ 
  id, 
  refetch,
  setOpenEditBalanceModal,
  rowData,
  setBalanceRowData,
}: ActionsButtonType) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const rowOptionsOpen = Boolean(anchorEl);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openTwoStepsAcceptModal, setOpenTwoStepsAcceptModal] = useState(false);
  const [openError, setOpenError] = useState(false);
  const { t } = useTranslation();
  let { balanceId } = useParams();
  balanceId = JSON.stringify(id);
  const { mutate } = useDeleteBalanceMutation();
  const handleRowOptionsClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleRowOptionsClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    mutate(id, {
      onSuccess: () => {
        setOpenSuccess(true);
        refetch();
      },
      onError: () => {
        setOpenSuccess(false);
        setOpenError(true);
      },
    });
    handleRowOptionsClose();
  };

  return (
    <>
      <IconButton size="small" onClick={handleRowOptionsClick}>
        <DotsVertical />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={rowOptionsOpen}
        onClose={handleRowOptionsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ style: { minWidth: "8rem" } }}
      >
        <MenuItem onClick={() => {
          setBalanceRowData(rowData);
          setOpenEditBalanceModal(true);
        }}>
          <PencilOutline fontSize="small" sx={{ mr: 2 }} />
          {t("Edit")}
        </MenuItem>
        <MenuItem onClick={() => setOpenTwoStepsAcceptModal(true)}>
          <DeleteOutline fontSize="small" sx={{ mr: 2 }} />
          {t("Delete")}
        </MenuItem>
      </Menu>
      <TwoStepsAcceptModal
        message={t("Do You Want To Delete Balance?")}
        executeButtonName={t("Delete")}
        executeFunction={handleDelete}
        isOpen={openTwoStepsAcceptModal}
        setIsOpen={setOpenTwoStepsAcceptModal}
      />
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Delete Balance")}
        successMessage={t("Message Success Delete Balance")}
      />
    </>
  );
};
export default ActionsButtonForPoints;