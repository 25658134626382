import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { formatValueDatepicker } from "helpers/Global";
import EventIcon from "@mui/icons-material/Event";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { Loader } from "components/Loader/Loader";
import CustomButton from "components/Buttons/CustomButton";
import { EmailAlert } from "mdi-material-ui";
import { map, uniqBy } from "lodash";
import usePaymentTypesQuery from "hooks/usePaymentTypesQuery";
import usePutTransactionMutation from "hooks/usePutTransactionMutation";
import useGetPaymentNameQuery from "hooks/useGetPaymentNameQuery";
import { TransactionsTypes } from "types/types";
import FormHelperText from "@mui/material/FormHelperText";

export const useStyles = makeStyles(() => ({
  textFieldCustom: {
    margin: " 15px 25px",
  },
}));
interface FormInputs {
  comment: string;
  verificationDate: string;
  amountInBaseUnit: number;
}
interface EditTransactionsTypes {
  transactionsRowData: TransactionsTypes | undefined;
  refetch: () => void;
}

function EditTransactionsCode({ refetch, transactionsRowData }: EditTransactionsTypes) {
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [messageError, setMessageError] = useState();
  const { mutate: putTransactionMutation, isLoading: putTransactionLoading } = usePutTransactionMutation();
  const { data: paymentName } = useGetPaymentNameQuery(
    {
      "id": `${transactionsRowData?.paymentType}`,
    },
    {
      enabled: !!transactionsRowData?.paymentType
    }
  );
  const { data: paymentTypesData } = usePaymentTypesQuery(
    {
      query: { name: inputValue },
    },
    {
      enabled: inputValue.length > 1
    }
  );
  const schema = yup.object().shape({
    inputValue: yup
      .string()
      .typeError(t("Message Error Payment Type") as string),
    verificationDate: yup
      .date()
      .typeError(t("Enter The Correct Date") as string),
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      comment: transactionsRowData?.comment,
      verificationDate: transactionsRowData?.verificationDate,
      amountInBaseUnit: transactionsRowData?.amountInBaseUnit,
    },
  });
  const onSubmit = ({
    comment,
    verificationDate,
    amountInBaseUnit,
  }: FormInputs) => {
    putTransactionMutation(
      {
        payload: {
          comment: comment,
          verificationDate: verificationDate,
          amountInCents: amountInBaseUnit * 100,
          paymentType: '/api/v1/payment_types/' + paymentTypesData?.["hydra:member"]?.find(
            (temp) => temp.name === inputValue
          )?.id,
        },
        TransactionCodeId: transactionsRowData?.id,
      },
      {
        onSuccess: () => {
          setOpenError(false);
          setOpenSuccess(true);
          refetch();
        },
        onError: (error: any) => {
          setOpenSuccess(false);
          setOpenError(true);
          setMessageError(error.response?.data["hydra:description"]);
        },
      }
    );
  };
  const { comment, verificationDate, amountInBaseUnit } = useWatch({ control: control });
  useEffect(() => {
    if (paymentName && inputValue === "") {
      setInputValue(paymentName);
    }
    reset({
      comment: comment,
      verificationDate: verificationDate,
      amountInBaseUnit: amountInBaseUnit,
    });
  }, [paymentName]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {putTransactionLoading && <Loader />}
      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={t("Language Code") as string}
        >
          <FormControl className={classes.textFieldCustom}>
            <Controller
              control={control}
              name="verificationDate"
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value}
                  onChange={onChange}
                  label={t("Verification Date")}
                  inputFormat={formatValueDatepicker}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EventIcon />
                      </InputAdornment>
                    ),
                  }}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "170px", mx: 2 }} />
                  )}
                />
              )}
            />
            {errors.verificationDate && (
              <FormHelperText sx={{ color: "error.main" }}>
                {errors.verificationDate.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl className={classes.textFieldCustom}>
            <Controller
              control={control}
              name="comment"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  variant="standard"
                  label={t("Comments")}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </FormControl>
          <FormControl className={classes.textFieldCustom}>
            <Controller
              control={control}
              name="amountInBaseUnit"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  variant="standard"
                  label={t("Amount")}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </FormControl>
          <FormControl className={classes.textFieldCustom}>
            <Autocomplete
              noOptionsText={t("None")}
              sx={{ width: 450 }}
              onInputChange={(_, value) => setInputValue(value)}
              options={
                map(
                  uniqBy(paymentTypesData?.["hydra:member"], "name"),
                  (formValue) => formValue.name
                ) || []
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Payment Type")}
                  variant="outlined"
                />
              )}
              value={inputValue}
              isOptionEqualToValue={(option, value) =>
                option === value
              }
            />
          </FormControl>
        </LocalizationProvider>
      </Box>
      <CustomButton
        text={t("You Save")}
        type="submit"
        style={{ mx: 5, mb: 5, mt: 5 }}
      />
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Data")}
        successMessage={t("Message Success Edit Transaction")}
      />
    </form>
  );
}

export default EditTransactionsCode;
