
import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { API_PREFIX, getApiClient } from "helpers/Global";
import { components, operations } from "schema/schema";

const path = `${API_PREFIX}/newsletter_histories/delete`;
type Payload = components["schemas"]["NewsletterHistory.NewsletterAndFilesDto.jsonld"];
type Response = string;

type Params = {
  payload: Payload;
};

const deleteNewsletterHistory = async ({ payload }: Params) => {
  
  const { data } = await getApiClient().post(path, payload);
  return data;
};

export default function useDeleteNewsletterHistory(
  options: UseMutationOptions<Response, AxiosError, Params> = {}
) {
  return useMutation<Response, AxiosError, Params>(deleteNewsletterHistory, options);
}