import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { API_PREFIX, getApiClient } from "helpers/Global";
import { components } from "schema/schema";

const path = `${API_PREFIX}/newsletter_histories/send_email`;
type Payload = components["schemas"]["NewsletterHistory.MessengerDto.jsonld"];
type Response = components["schemas"]["NewsletterHistory.jsonld"];

type Params = {
  payload: Payload;
};

const postNewsletterSend = async ({ payload }: Params) => {

  const { data } = await getApiClient().post(path, payload);
  return data;
};

export default function usePostNewsletterSendMutation(
  options: UseMutationOptions<Response, AxiosError, Params> = {}
) {
  return useMutation<Response, AxiosError, Params>(postNewsletterSend, options);
}
