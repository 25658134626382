import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { Divider, FormControl, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useSettings } from "hooks/useSettings";
import { useTheme } from "@mui/material/styles";
import { Controller, useForm, useWatch } from "react-hook-form";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import EyeOutline from "mdi-material-ui/EyeOutline";
import EyeOffOutline from "mdi-material-ui/EyeOffOutline";
import TextField from "@mui/material/TextField";
import PasswordStrengthBar from "react-password-strength-bar";
import useWhoIAmQuery from "hooks/useWhoIAmQuery";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Loader } from "components/Loader/Loader";
import useChangePasswordByUserMutation from "hooks/useChangePasswordByUserMutation";
import CustomButton from "components/Buttons/CustomButton";

interface FormInputs {
  currentPassword: string;
  newPassword: string;
  repeatedPassword: string;
}

function ChangePassword() {
  const { t } = useTranslation();
  const { settings } = useSettings();
  const theme = useTheme();
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatedNewPassword, setShowRepeatedNewPassword] = useState(false);
  const { data } = useWhoIAmQuery();
  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .min(5, t("Password Is To Short") as string)
      .required(t("This Field is Required") as string),
    repeatedPassword: yup
      .string()
      .required(t("This Field is Required") as string)
      .oneOf(
        [yup.ref("newPassword"), null],
        t("Passwords Are Not The Same") as string
      ),
  });
  const { mutate: changePasswordMutation, isLoading: isLoading } =
    useChangePasswordByUserMutation();
  const {
    control: control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      newPassword: "",
      repeatedPassword: "",
    },
  });
  const { newPassword } = useWatch({ control: control });
  const onSubmit = ({ newPassword, currentPassword }: FormInputs) => {
    changePasswordMutation(
      {
        payload: {
          currentPassword: currentPassword,
          newPassword: newPassword,
        },
        userId: data?.user_id,
      },
      {
        onSuccess: () => {
          setOpenSuccess(true);
        },
        onError: (data) => {
          setOpenSuccess(false);
          setOpenError(true);
          if(("" + data) === "AxiosError: Request failed with status code 403") setError(t("Message Error Wrong Current Password") ?? "Error");
          else if (data) setError(t("Message Error Edit User Password") ?? "Error");
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isLoading && <Loader />}
      <Card
        sx={{
          backgroundColor: settings.mode === "light" ? "inherit" : "#28243D",
          height: "100%",
        }}
      >
        <CardHeader
          sx={{ textAlign: "center" }}
          title={t("Change Password Form")}
        />
        <Divider />
        <Grid
          container
          spacing={6}
          item
          sx={{
            my: 1,
            alignItems: "center",
            flexDirection: "column",
            width: onlySmallScreen ? "100%" : "unset",
            pl: onlyMediumScreen ? 0 : "unset",
            m: onlySmallScreen ? 0 : "unset",
          }}
        >
          <FormControl sx={{ my: 2 }}>
            <Controller
              name="currentPassword"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextField
                  label={t("Current Password")}
                  value={value}
                  variant="outlined"
                  onChange={onChange}
                  id="current-user-password"
                  type={showCurrentPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() =>
                            setShowCurrentPassword(!showCurrentPassword)
                          }
                        >
                          {showCurrentPassword ? (
                            <EyeOutline />
                          ) : (
                            <EyeOffOutline />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl sx={{ my: 2 }}>
            <Controller
              name="newPassword"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextField
                  error={!!errors.newPassword}            
                  helperText={
                    errors?.newPassword?.message
                  }      
                  label={t("New Password")}
                  value={value}
                  variant="outlined"
                  onChange={onChange}
                  id="new-user-password"
                  type={showNewPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        >
                          {showNewPassword ? <EyeOutline /> : <EyeOffOutline />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {newPassword && newPassword.length > 0 && (
              <PasswordStrengthBar
                shortScoreWord={t("Too Short")}
                scoreWords={[
                  t("Weak"),
                  t("Weak"),
                  t("Okay"),
                  t("Good"),
                  t("Strong"),
                ]}
                password={newPassword}
              />
            )}
          </FormControl>
          <FormControl sx={{ my: 2 }}>
            <Controller
              name="repeatedPassword"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextField
                  error={!!errors.repeatedPassword}            
                  helperText={
                    errors?.repeatedPassword?.message
                  }    
                  label={t("Repeat New Password")}
                  value={value}
                  variant="outlined"
                  onChange={onChange}
                  id="repeat-new-user-password"
                  type={showRepeatedNewPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() =>
                            setShowRepeatedNewPassword(!showRepeatedNewPassword)
                          }
                        >
                          {showRepeatedNewPassword ? (
                            <EyeOutline />
                          ) : (
                            <EyeOffOutline />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
          <CustomButton text={t("Change")} style={{ m: 2 }} type="submit" />
        </Grid>
      </Card>
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={error}
        successMessage={t("Message Success Edit User Password")}
      />
    </form>
  );
}

export default ChangePassword;
