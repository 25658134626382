import { SyntheticEvent, useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import CardContent from "@mui/material/CardContent";
import { useTranslation } from "react-i18next";
import PointsTableSubPage from "components/PageEditUser/Points/PointsTableSubPage";
import AddPointsTableSubPage from "components/PageEditUser/Points/AddPointsTableSubPage";
import Contributions from "components/PageEditUser/TableSubpages/Contributions";
import TransactionsTableSubPage from "components/PageEditUser/Transactions/TransactionsTableSubPage";
import AddPaymentTableSubPage from "components/PageEditUser/Transactions/AddPaymentTableSubPage";
import History from "components/PageEditUser/TableSubpages/History/History";
import Note from "components/PageEditUser/TableSubpages/Note";
import AddInscription from "components/PageEditUser/TableSubpages/AddInscription";
import ChangeHistory from "components/PageEditUser/TableSubpages/ChangeHistory/ChangeHistory";
import { operations } from "schema/schema";

interface UserValuesTableTypes {
  isPointsOpened: boolean;
  isTransactionsOpened: boolean;
  userInformationData:
    | operations["api_apiv1users_id_get"]["responses"]["200"]["content"]["application/ld+json"]
    | undefined;
  refetch: () => void;
}
const UserValuesTable = ({
  isPointsOpened,
  userInformationData,
  isTransactionsOpened,
  refetch,
}: UserValuesTableTypes) => {
  const [value, setValue] = useState<string>("Note");
  const { t } = useTranslation();
  useEffect(() => {
    isPointsOpened ? setValue("Points") : setValue("Note");
    isTransactionsOpened
      ? setValue("Transactions")
      : isPointsOpened
      ? setValue("Points")
      : setValue("Note");
  }, [isPointsOpened, isTransactionsOpened]);
  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabList
        onChange={handleChange}
        className="user-values-tab-navigation"
        aria-label="user-values-navigation"
      >
        <Tab
          sx={{
            display: isPointsOpened
              ? "none"
              : isTransactionsOpened
              ? "none"
              : "unset",
          }}
          value="Note"
          label={t("Note")}
        />
        <Tab
          sx={{
            display: isPointsOpened
              ? "none"
              : isTransactionsOpened
              ? "none"
              : "unset",
          }}
          value="Contributions"
          label={t("Contributions")}
        />
        <Tab
          sx={{
            display: isPointsOpened
              ? "none"
              : isTransactionsOpened
              ? "none"
              : "unset",
          }}
          value="History"
          label={t("History")}
        />
        <Tab
          sx={{
            display: isPointsOpened
              ? "none"
              : isTransactionsOpened
              ? "none"
              : "unset",
          }}
          value="Add Inscription"
          label={t("Add Inscription")}
        />
        {/*<Tab*/}
        {/*  sx={{*/}
        {/*    display: isPointsOpened*/}
        {/*      ? "none"*/}
        {/*      : isTransactionsOpened*/}
        {/*      ? "none"*/}
        {/*      : "unset",*/}
        {/*  }}*/}
        {/*  value="Forms"*/}
        {/*  label={t("Forms")}*/}
        {/*/>*/}
        <Tab
          sx={{
            display: isPointsOpened
              ? "none"
              : isTransactionsOpened
              ? "none"
              : "unset",
          }}
          value="Change History"
          label={t("Change History")}
        />
        <Tab
          sx={{
            display: isPointsOpened
              ? "none"
              : isTransactionsOpened
              ? "unset"
              : "none",
          }}
          value="Transactions"
          label={t("Transactions")}
        />
        <Tab
          sx={{
            display: isPointsOpened
              ? "none"
              : isTransactionsOpened
              ? "unset"
              : "none",
          }}
          value="Add Payment"
          label={t("Add Payment")}
        />
        <Tab
          sx={{
            display: isPointsOpened
              ? "unset"
              : isTransactionsOpened
              ? "none"
              : "none",
          }}
          value="Points"
          label={t("Points")}
        />
        <Tab
          sx={{
            display: isPointsOpened
              ? "unset"
              : isTransactionsOpened
              ? "none"
              : "none",
          }}
          value="Add Points"
          label={t("Add Points")}
        />
      </TabList>
      <CardContent>
        <TabPanel value="Transactions" sx={{ p: 0 }}>
          <TransactionsTableSubPage selectedUserId={userInformationData?.id} />
        </TabPanel>
        <TabPanel value="Add Payment" sx={{ p: 0 }}>
          <AddPaymentTableSubPage userInformationData={userInformationData} />
        </TabPanel>
        <TabPanel value="Points" sx={{ p: 0 }}>
          <PointsTableSubPage userInformationData={userInformationData} />
        </TabPanel>
        <TabPanel value="Add Points" sx={{ p: 0 }}>
          <AddPointsTableSubPage userInformationData={userInformationData} />
        </TabPanel>
        <TabPanel value="Note" sx={{ p: 0 }}>
          <Note userInformationData={userInformationData} refetch={refetch} />
        </TabPanel>
        <TabPanel value="Contributions" sx={{ p: 0 }}>
          <Contributions userInformationData={userInformationData} />
        </TabPanel>
        <TabPanel value="History" sx={{ p: 0 }}>
          <History userInformationData={userInformationData} />
        </TabPanel>
        <TabPanel value="Add Inscription" sx={{ p: 0 }}>
          <AddInscription userInformationData={userInformationData} />
        </TabPanel>
        <TabPanel value="Change History" sx={{ p: 0 }}>
          <ChangeHistory userInformationData={userInformationData} />
        </TabPanel>
      </CardContent>
    </TabContext>
  );
};

export default UserValuesTable;
