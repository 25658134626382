import React from "react";
import { Box, Button, Card, Dialog, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import EditBalanceCode from "./EditBalanceCode";
import { BalancesTypes } from "types/types";

interface EditBalanceModalTypes {
  showBalanceModal: boolean;
  setShowBalanceModal: (val: boolean) => void;
  refetch: () => void;
  balanceRowData: BalancesTypes | undefined;
}

function EditBalanceModal({
  showBalanceModal,
  setShowBalanceModal,
  balanceRowData,
  refetch,
}: EditBalanceModalTypes) {
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Dialog
        open={showBalanceModal}
        BackdropProps={{
          style: {
            backgroundColor: theme.palette.customColors.modalBgc,
          },
        }}
      >
        <Box
          className="array-modal"
          sx={{
            width: onlyMediumScreen ? "90vw" : "70vw",
            minHeight: 150,
            px: onlySmallScreen ? 5 : "unset",
          }}
        >
          <Card
            sx={{
              minHeight: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingRight: onlySmallScreen ? "unset" : "50px",
              flexWrap: "wrap",
              py: 5,
            }}
          >
            <EditBalanceCode
              balanceRowData={balanceRowData}
              refetch={refetch}
            />
          </Card>
          <Button
            className="array-close-button"
            onClick={() => setShowBalanceModal(false)}
          >
            <CloseIcon />
          </Button>
        </Box>
      </Dialog>
    </>
  );
}
export default EditBalanceModal;
