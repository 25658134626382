import React, { useState, MouseEvent, useEffect } from "react";
import { Box, Button, Card, Dialog, useMediaQuery, CardHeader } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import CustomButton from "components/Buttons/CustomButton";
import { useTranslation } from "react-i18next";
import { operations } from "schema/schema";
import useGetNewsletterHistoryQuery from "hooks/useGetNewsletterHistoryQuery";
import usePutNewsletterHistoryMutation from "hooks/usePutNewsletterHistoryMutation";
import { useNavigate } from "react-router-dom";
import useGetWhoAmI from "hooks/useWhoIAmQuery";
import ViewNewsletterMailModal from "./ViewNewsletterMailModal";
import TwoStepsAcceptModal from "components/TwoStepsAcceptModal/TwoStepsAcceptModal";

interface ViewNewsletterModalTypes {
  showNewsletterModal: boolean;
  setShowNewsletterModal: (val: boolean) => void;
  formData: any;
  newsletterId: any;
  refetch: any
};

function ViewNewsletterModal({
  showNewsletterModal,
  setShowNewsletterModal,
  formData,
  newsletterId,
  refetch,
}: ViewNewsletterModalTypes) {
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const [openViewNewsletterMailModal, setOpenViewNewsletterMailModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState('contentHtml');
  const { mutate: putMutate } = usePutNewsletterHistoryMutation();
  const navigate = useNavigate();
  const { data: WhoAmI } = useGetWhoAmI();
  const [action, setAction] = useState({});
  const [disableSend, setDisableSend] = useState(false);
  const [openTwoStepsAcceptModal, setOpenTwoStepsAcceptModal] = useState(false);
  const handlePut = () => {
    if (NewsletterDataLoading) {
      alert(t("Data Is Still Loading, Please Try Later"));
      return;
    }
    putMutate(
      {
        newsletterHistoriesId: newsletterId,
        payload: {
          title: formData.title,
          senderEmail: formData.senderEmail,
          senderName: formData.senderName,
          reply: formData.reply,
          replyName: formData.replyName,
          contentHtml: formData.contentHtml,
          contentTxt: formData.contentTxt,
          status: 3,
          type: "/api/v1/newsletter_types/2",
          user: "/api/v1/users/" + WhoAmI?.user_id,
        },
      }
    );
    setAction('saved');
    alert(t("Sucess Save Newsletter"));
  }
  const { data: NewsletterData, isLoading: NewsletterDataLoading } = useGetNewsletterHistoryQuery(
    {
      query:
      {
        id: newsletterId,
      }
    },
  );
  useEffect(() => {
  }, [disableSend]);
  const handleCheck = () => {
    if (NewsletterDataLoading) {
      alert(t("Data Is Still Loading, Please Try Later"));
      return;
    }
    if (action === 'saved') {
      setDisableSend(false);
      setOpenViewNewsletterMailModal(true);
    } else if (NewsletterData?.["hydra:member"][0].title === formData.title
      && NewsletterData?.["hydra:member"][0].senderEmail === formData.senderEmail
      && NewsletterData?.["hydra:member"][0].senderName === formData.senderName
      && NewsletterData?.["hydra:member"][0].reply === formData.reply
      && NewsletterData?.["hydra:member"][0].replyName === formData.replyName
      && NewsletterData?.["hydra:member"][0].contentHtml === formData.contentHtml
      && NewsletterData?.["hydra:member"][0].contentTxt === formData.contentTxt
      && NewsletterData?.["hydra:member"][0].status == 3) {
      setDisableSend(false);
      setOpenViewNewsletterMailModal(true);
    }
    else {
      setDisableSend(true);
      setOpenTwoStepsAcceptModal(true);
    }
  }
  return (
    <>
      <Dialog
        open={showNewsletterModal}
        BackdropProps={{
          style: {
            backgroundColor: theme.palette.customColors.modalBgc,
          },
        }}
      >
        <Box
          className="array-modal"
          sx={{
            width: onlyMediumScreen ? "90vw" : "70vw",
            minHeight: 150,
            px: onlySmallScreen ? 5 : "unset",
          }}
        >
          <Button
            className="array-close-button"
            onClick={() => setShowNewsletterModal(false)}
          >
            <CloseIcon />
          </Button>
          <Card
            sx={{
              minHeight: 10,
              px: onlySmallScreen ? "unset" : "50px",
              fontSize: 'default',
              justifyContent: "center",
              alignItems: "center",
              pt: 5,
              overflow: 'auto',
              borderRadiusTopLeft: "0",
            }}
          >
            <div>
              <CardHeader title={t("Preview Of Message Content")} />
            </div>
            <div style={{ textAlign: 'center' }}>
              <strong style={{ textAlign: 'center', marginBottom: '15px', fontSize: '35px' }}>{t("Title")}: {formData.title}</strong>
            </div>
            <div>
              <CustomButton text={t("Content HTML")} style={{ mt: '10px', height: '30px', p: 0, fontSize: 13, backgroundColor: selectedTab === 'contentHtml' ? 'green !imporant' : 'inherit !important' }} onClickFunction={() => setSelectedTab('contentHtml')} />
              <CustomButton text={t("Content Txt")} style={{ mt: '10px', height: '30px', fontSize: 13, backgroundColor: selectedTab === 'contentTxt' ? 'green !imporant' : 'inherit !important' }} onClickFunction={() => setSelectedTab('contentTxt')} />
            </div>
          </Card>
          <Card
            sx={{
              minHeight: 471.5,
              px: onlySmallScreen ? "unset" : "50px",
              paddingTop: "20px",
              fontSize: 'default',
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              overflow: 'auto',
            }}
          >
            <div>
              {selectedTab === 'contentHtml' && formData && formData.contentHtml &&
                <div dangerouslySetInnerHTML={{ __html: formData.contentHtml }} />}
              {selectedTab === 'contentTxt' && formData && formData.contentTxt &&
                <pre>{formData.contentTxt}</pre>}
            </div>
          </Card>
          <Card
            sx={{
              height: 80,
              px: onlySmallScreen ? "unset" : "50px",
              fontSize: 'default',
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              py: 0,
              overflow: 'auto',
            }}
          >
            <CustomButton
              text={t("Back To Edit")}
              style={{ m: 2 }}
              type="submit"
              onClickFunction={() => {
                refetch({
                  query: {
                    status: "",
                    id: newsletterId,
                  }
                });
                setShowNewsletterModal(false);
                setAction("Back to edit");
              }}
            />
            {NewsletterData && (
              <>
                <CustomButton text={t("You Save")} style={{ m: 2 }} type="submit" onClickFunction={() => handlePut()} />
                <CustomButton text={t("Next")} style={{ m: 2 }} type="submit" onClickFunction={() => handleCheck()} />
              </>
            )}
            <ViewNewsletterMailModal
              showNewsletterMailModal={openViewNewsletterMailModal}
              setShowNewsletterMailModal={setOpenViewNewsletterMailModal}
              formData={formData}
              newsletterId={newsletterId}
              refetch={refetch}
              disableSend={disableSend}
            />
            <TwoStepsAcceptModal
              message={t("Do You Want Continue Without Save To Database?")}
              executeButtonName={t("Continue")}
              executeFunction={() => setOpenViewNewsletterMailModal(true)}
              isOpen={openTwoStepsAcceptModal}
              setIsOpen={setOpenTwoStepsAcceptModal}
            />
          </Card>
        </Box>
      </Dialog>
    </>
  );
}
export default ViewNewsletterModal;
