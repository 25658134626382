import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputAdornment,
  Checkbox,
  Autocomplete,
  FormControlLabel,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { formatValueDatepicker } from "helpers/Global";
import EventIcon from "@mui/icons-material/Event";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { Loader } from "components/Loader/Loader";
import useGetUserEmailQuery from "hooks/useGetUserEmailQuery";
import usePutChangeHistoryCodeMutation from "hooks/usePutChangeHistoryCodeMutation";
import CustomButton from "components/Buttons/CustomButton";
import { EmailAlert } from "mdi-material-ui";
import useWhoIAmQuery from "hooks/useWhoIAmQuery";
import { map, uniqBy } from "lodash";
import useGetUserFilterParametersQuery from "hooks/useGetUserFilterParametersQuery";
import { CrmsTypes } from "types/types";
import FormHelperText from "@mui/material/FormHelperText";

export const useStyles = makeStyles(() => ({
  textFieldCustom: {
    margin: " 15px 25px",
  },
}));
interface FormInputs {
  createdAt: string | null;
  content: string;
}
interface EditChangeHistoryCodeTypes {
  changeHistoryRowData: CrmsTypes | undefined;
  refetch: () => void;
}

function EditChangeHistoryCode({ refetch, changeHistoryRowData }: EditChangeHistoryCodeTypes) {
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [messageError, setMessageError] = useState();
  const { mutate: putChangeHistoryMutation, isLoading: putChangeHistoryCodeLoading } = usePutChangeHistoryCodeMutation();
  const { data: currentUser } = useWhoIAmQuery();
  const user_id = "/api/v1/users/" + currentUser?.user_id;
  const { data: email } = useGetUserEmailQuery(
    {
      "id": `${user_id}`,
    },
    {
      enabled: !!user_id 
    }
  );
  const { data: authorEmail } = useGetUserEmailQuery(
    {
      "id": `${changeHistoryRowData?.user}`,
    },
  );
  const { data: userTypesData } = useGetUserFilterParametersQuery(
    {
      query: { email: inputValue },
    },
    {
      enabled: inputValue.length > 1,
    }
  );
  const { data: author } = useGetUserFilterParametersQuery(
    {
      query: { emailExact: inputValue }
    },
    {
      enabled: inputValue.length > 1,
    }
  )
  const checkboxChanged = (value: boolean) => { 
    setCheckboxChecked(value); 
    if(value === true && email) {
      setInputValue(email);
    }
    else if(value === false && authorEmail) {
      setInputValue(authorEmail);
    }
  }
  const schema = yup.object().shape({
    inputValue: yup
      .string()
      .typeError(t("Message Error Wrong User") as string),
    createdAt: yup
      .date()
      .typeError(t("Enter The Correct Date") as string),
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      createdAt: changeHistoryRowData?.createdAt,
      content: changeHistoryRowData?.content,
    },
  });
  const onSubmit = ({
    createdAt,
    content,
  }: FormInputs) => {
    putChangeHistoryMutation(
      {
        payload: {
          createdAt: createdAt,
          content: content,
          user: checkboxChecked ? (author?.["hydra:member"].length === 1
          ? "/api/v1/users/" + author?.["hydra:member"][0]?.id 
          : "") : undefined,
        },
        CrmCodeId: changeHistoryRowData?.id,
      },
      {
        onSuccess: () => {
          setOpenError(false);
          setOpenSuccess(true);
          refetch();
        },
        onError: (error: any) => {
          setOpenSuccess(false);
          setOpenError(true);
          setMessageError(error.response?.data["hydra:description"]);
        },
      }
    );
  };
  const { content, createdAt } = useWatch({ control: control });
  useEffect(() => {
    if(email && inputValue === "" && authorEmail){
      setInputValue(authorEmail);
    }
    reset({
      content: content,
      createdAt: createdAt
    });
  }, [email, authorEmail]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {putChangeHistoryCodeLoading && <Loader />}
      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={t("Language Code") as string}
        >
          <FormControl className={classes.textFieldCustom}>
            <Controller
              control={control}
              name="createdAt"
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value}
                  onChange={onChange}
                  label={t("Date From")}
                  inputFormat={formatValueDatepicker}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EventIcon />
                      </InputAdornment>
                    ),
                  }}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "170px", mx: 2 }} />
                  )}
                />
              )}
            />
            {errors.createdAt && (
              <FormHelperText sx={{ color: "error.main" }}>
                {errors.createdAt.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl className={classes.textFieldCustom}>
          <Controller
            control={control}
            name="content"
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                onChange={onChange}
                variant="standard"
                label={t("Change Content")}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
          </FormControl>
          <Box>
            <FormControl className={classes.textFieldCustom}>
              <FormControlLabel
                label={t("Change The Author")}
                control={
                  <Checkbox
                    checked={checkboxChecked} 
                    onChange={ e => checkboxChanged(e.target.checked)} 
                    value={t("Author")}
                  />
                }
              />
            </FormControl>
            <FormControl className={classes.textFieldCustom}>
                <Autocomplete
                  disabled={!checkboxChecked}
                  noOptionsText={t("None")}
                  sx={{ width: 300 }}
                  onInputChange={(_, value) => setInputValue(value)}
                  options={
                    map(
                      uniqBy(userTypesData?.["hydra:member"], "email"),
                      (userType: any) => userType.email
                    ) || []
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Author")}
                      variant="outlined"
                    />
                  )}
                  value={inputValue}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                />
            </FormControl>
          </Box>
        </LocalizationProvider>
      </Box>
      <CustomButton
        text={t("You Save")}
        type="submit"
        style={{ mx: 5, mb: 5, mt: 5 }}
      />
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Wrong User")}
        successMessage={t("Message Success Edit Change History")}
      />
    </form>
  );
}

export default EditChangeHistoryCode;
