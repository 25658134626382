import {
  Box,
  Typography,
  useMediaQuery,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import TableBasic from "components/TableBasic/TableBasic";
import { useTranslation } from "react-i18next";
import useGetNewsletterHistoryQuery from "hooks/useGetNewsletterHistoryQuery";
import dayjs from "dayjs";
import { Controller, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import ArrowTableHeader from "components/PageUsers/UsersList/Arrow";
import { headerSortTable } from "components/TableBasic/HeaderSortTable";
import { find } from "lodash";
import { GridSortModel } from "@mui/x-data-grid";
import ViewNewsletterModal from "./ViewNewsletterModal";
import { MailOutline } from "@mui/icons-material";
import { operations } from "schema/schema";
import { newsletterHistoryStatus, menuItemStatus } from "constans/Constans";

export interface FormInputs {
  date: string,
  newsletterStatus: number,
  status: string
}

type NewsletterHistory = operations["api_apiv1newsletter_histories_id_get"]["responses"]["200"]["content"]["application/ld+json"];

function NewsletterHistory() {
  const [sortOptions, setSortOptions] = useState<GridSortModel>([]);
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { control } = useForm<FormInputs>();
  const { newsletterStatus } = useWatch({ control });
  const [newsletterRowData, setNewsletterRowData] = useState<NewsletterHistory>();
  const [openViewNewsletterModal, setOpenViewNewsletterModal] = useState(false);
  const [inputValues, setInputValues] = useState({
    title: undefined,
    senderDisplayName: undefined,
    userEmail: undefined,
    newsletterTypeName: undefined,
    status: undefined,
    sendDate: undefined,
    replyNameAndReply: undefined
  });
	const queryValues = {
		page: pageNumber,
		itemsPerPage: itemsPerPage,
    "status[]": newsletterStatus === newsletterHistoryStatus.UNSAVED ? [menuItemStatus.TEMPORARY] : newsletterStatus === newsletterHistoryStatus.SENT ? [menuItemStatus.SENT] : newsletterStatus === newsletterHistoryStatus.DELETED ? [menuItemStatus.TEMPORARY,menuItemStatus.SENT] : [menuItemStatus.TEMPORARY,menuItemStatus.SENT],
    title: inputValues.title,
    ["user.email"]: inputValues.userEmail,
    ["type.name"]: inputValues.newsletterTypeName,
    sendDate: inputValues.sendDate,
    senderDisplayName: inputValues.senderDisplayName,
    replyNameAndReply: inputValues.replyNameAndReply,
    "orderBy[status]":
    find(sortOptions, { field: "status" })?.sort || undefined,
    "orderBy[title]":
    find(sortOptions, { field: "title" })?.sort || undefined,
    "orderBy[user.email]":
    find(sortOptions, { field: "userEmail" })?.sort || undefined,
    "orderBy[type.name]":
    find(sortOptions, { field: "newsletterTypeName" })?.sort || undefined,
    "orderBy[sendDate]":
    find(sortOptions, { field: "sendDate" })?.sort || undefined,
    "orderBy[senderDisplayName]":
    find(sortOptions, { field: "senderDisplayName" })?.sort || undefined,
    "orderBy[replyNameAndReply]":
    find(sortOptions, { field: "replyNameAndReply" })?.sort || undefined,
  };
  const { data: RowsData, refetch, isLoading: RowsDataLoading } = useGetNewsletterHistoryQuery(
		{
			query: queryValues
		},
  );
  const filterRows = (value: string, columnName: string) => {
    setInputValues((inputValues) => ({
      ...inputValues,
      [columnName]: value,
    }));
  };
  const handleChange = (
    e: { target: { value: string } },
    columnName: string
  ) => {
    filterRows(e.target.value, columnName);
  };
  const columns: GridColDef[] = [
    {
      flex: 0.15,
      minWidth: 200,
      field: "title",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box className="table-header-custom-styles">
          <div>
            <ArrowTableHeader
              onClick={() => {
                headerSortTable(sortOptions, setSortOptions, "title");
              }}
              direction={find(sortOptions, { field: "title" })?.sort || null}
            >
              {t("Title")}
            </ArrowTableHeader>
          </div>
          <TextField
            variant="standard"
            onChange={(e) => handleChange(e, "title")}
          />
        </Box>
      ),
    },
		{
      flex: 0.2,
      minWidth: 200,
      field: "senderDisplayName",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box className="table-header-custom-styles">
          <div>
            <ArrowTableHeader
              onClick={() => {
                headerSortTable(sortOptions, setSortOptions, "senderDisplayName");
              }}
              direction={find(sortOptions, { field: "senderDisplayName" })?.sort || null}
            >
              {t("Sender")}
            </ArrowTableHeader>
          </div>
          <TextField
            variant="standard"
            onChange={(e) => handleChange(e, "senderDisplayName")}
          />
        </Box>
      ),
    },
    {
      flex: 0.15,
      minWidth: 200,
      field: "replyNameAndReply",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params) => params.row?.replyName ? params.row?.replyName + " <" + params.row?.reply + ">" : params.row?.reply,
      renderHeader: () => (
        <Box className="table-header-custom-styles">
          <div>
            <ArrowTableHeader
              onClick={() => {
                headerSortTable(sortOptions, setSortOptions, "replyNameAndReply");
              }}
              direction={find(sortOptions, { field: "replyNameAndReply" })?.sort || null}
            >
              {t("Reply")}
            </ArrowTableHeader>
          </div>
          <TextField
            variant="standard"
            onChange={(e) => handleChange(e, "replyNameAndReply")}
          />
        </Box>
      ),
    },
		{
      flex: 0.15,
      minWidth: 100,
      field: "newsletterTypeName",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box className="table-header-custom-styles">
          <div>
            <ArrowTableHeader
              onClick={() => {
                headerSortTable(sortOptions, setSortOptions, "newsletterTypeName");
              }}
              direction={find(sortOptions, { field: "newsletterTypeName" })?.sort || null}
            >
              {t("Type")}
            </ArrowTableHeader>
          </div>
          <TextField
            variant="standard"
            onChange={(e) => handleChange(e, "newsletterTypeName")}
          />
        </Box>
      ),
    },
    {
      flex: 0.15,
      minWidth: 100,
      field: "status",
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (params) => 
        params.value === 0 ? t("Unsaved (Autosave)") : 
        params.value === 1 ? t("Sent") : 
        params.value === 2 ? t("Deleted") : 
        params.value === 3 ? t("Saved") : 
        "",
      renderHeader: () => (
        <Box className="table-header-custom-styles">
          <div>
            <ArrowTableHeader
              onClick={() => {
                headerSortTable(sortOptions, setSortOptions, "status");
              }}
              direction={find(sortOptions, { field: "status" })?.sort || null}
            >
              {t("Status")}
            </ArrowTableHeader>
          </div>
        </Box>
      ),
    },
    {
      flex: 0.15,
      minWidth: 100,
      field: "sendDate",
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (params) => dayjs(params.value).isValid() ? dayjs(params.value).format('YYYY-MM-DD') : "",
      renderHeader: () => (
        <Box className="table-header-custom-styles">
          <div>
            <ArrowTableHeader
              onClick={() => {
                headerSortTable(sortOptions, setSortOptions, "sendDate");
              }}
              direction={find(sortOptions, { field: "sendDate" })?.sort || null}
            >
              {t("Date")}
            </ArrowTableHeader>
          </div>
          <TextField
            variant="standard"
            onChange={(e) => handleChange(e, "sendDate")}
          />
        </Box>
      ),
    },
		{
      flex: 0.15,
      minWidth: 200,
      field: "userEmail",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box className="table-header-custom-styles">
          <div>
            <ArrowTableHeader
              onClick={() => {
                headerSortTable(sortOptions, setSortOptions, "userEmail");
              }}
              direction={find(sortOptions, { field: "userEmail" })?.sort || null}
            >
              {t("Saved By")}
            </ArrowTableHeader>
          </div>
          <TextField
            variant="standard"
            onChange={(e) => handleChange(e, "userEmail")}
          />
        </Box>
      ),
    },
		{
      flex: 0.11,
      minWidth: 130,
      field: "preview",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Newsletter Preview"),
      renderCell: ({ row }) =>
      (
        <MenuItem onClick={() => {
          setNewsletterRowData(row);
          setOpenViewNewsletterModal(true);
        }}>
          {t("Show")}
          <MailOutline fontSize="small" sx={{ ml: 1, mr: 4 }} />
        </MenuItem>
      ),
    }
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: onlySmallScreen ? "column" : "row",
          mt: onlySmallScreen ? 5 : 0,
          mb: 5,
        }}
      >
          <Typography>{t("Newsletter Status")}:</Typography>
          <FormControl>
            <Controller
              control={control}
              name="newsletterStatus"
              render={({ field: { value, onChange } }) => (
              <Select
                defaultValue={2}
                sx={{ width: onlySmallScreen ? 120 : 150, ml: 5 }}
                value={value}
                onChange={onChange}
                size="medium"
                variant="standard"
              >
                <MenuItem value={0}>{t("Sent")}</MenuItem>
                <MenuItem value={1}>{t("Saved")}</MenuItem>
                <MenuItem value={2}>{t("All")}</MenuItem>
              </Select>
            )}
          />
        </FormControl>
      </Box>
      <TableBasic
        paginationMode="server"
        columns={columns}
        loading={RowsDataLoading}
        rows={RowsData?.["hydra:member"]}
        title={t("Mailing List")}
        setItemsPerPage={setItemsPerPage}
        totalItems={RowsData?.["hydra:totalItems"]}
        setPageNumber={setPageNumber}
				useRowId
      />
      <ViewNewsletterModal
        refetch={refetch}
        showNewsletterModal={openViewNewsletterModal}
        setShowNewsletterModal={setOpenViewNewsletterModal}
        newsletterRowData={newsletterRowData}
      />
    </div>
  );
}

export default NewsletterHistory;
