import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Autocomplete,
  InputAdornment,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { Loader } from "components/Loader/Loader";
import usePutHistoryMutation from "hooks/usePutHistoryMutation";
import CustomButton from "components/Buttons/CustomButton";
import { EmailAlert } from "mdi-material-ui";
import { HistoryTypes } from "types/types";
import useGetHistoryTypeNameQuery from "hooks/useGetHistoryTypeNameQuery";
import useHistoryTypesQuery from "hooks/useHistoryTypesQuery";
import { map, uniqBy } from "lodash";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { formatValueDatepicker } from "helpers/Global";
import EventIcon from "@mui/icons-material/Event";
import useWhoIAmQuery from "hooks/useWhoIAmQuery";
import useGetUserEmailQuery from "hooks/useGetUserEmailQuery";
import useGetUserFilterParametersQuery from "hooks/useGetUserFilterParametersQuery";
import FormHelperText from "@mui/material/FormHelperText";

export const useStyles = makeStyles(() => ({
  textFieldCustom: {
    margin: " 15px 25px",
  },
}));
interface FormInputs {
  content: string;
  createdAt: string | null;
  email: string;
  toEmail: string;
  authorEmail: string;
}
interface EditHistoryCodeTypes {
  historyRowData: HistoryTypes | undefined;
  refetch: () => void;
}

function EditHistoryCode({ refetch, historyRowData }: EditHistoryCodeTypes) {
  const [valuesLoaded, setValuesLoaded] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValueHistoryType, setInputValueHistoryType] = useState("");
  const [inputValueAuthor, setInputValueAuthor] = useState("");
  const [inputValueUser, setInputValueUser] = useState("");
  const { t } = useTranslation();
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [messageError, setMessageError] = useState();
  const { mutate: putHistoryMutation, isLoading: putHistoryLoading } = usePutHistoryMutation();
  const { data: currentUser } = useWhoIAmQuery();
  const user_id = "/api/v1/users/" + currentUser?.user_id;
  const { data: email } = useGetUserEmailQuery(
    {
      "id": `${user_id}`,
    },
    {
      enabled: !!user_id 
    }
  );
  const { data: toEmail } = useGetUserEmailQuery(
    {
      "id": `${historyRowData?.toUser}`,
    },
    {
      enabled: !!historyRowData?.toUser
    }
  );
  const { data: authorEmail } = useGetUserEmailQuery(
    {
      "id": `${historyRowData?.user}`,
    },
  );
  const { data: userTypesData } = useGetUserFilterParametersQuery(
    {
      query: { email: inputValueUser },
    },
    {
      enabled: inputValueUser.length > 1,
    }
  );
  const { data: authorTypesData } = useGetUserFilterParametersQuery(
    {
      query: { email: inputValueAuthor },
    },
    {
      enabled: inputValueAuthor.length > 1,
    }
  );
  const { data: defaultHistoryTypeName } = useGetHistoryTypeNameQuery(
    {
      "id": `${historyRowData?.type}`,
    },
    {
      enabled: !!historyRowData?.type 
    }
  );
  const { data: historyTypesData } = useHistoryTypesQuery(
    {
      query: {},
    },
    {
      enabled: inputValueHistoryType.length > 1,
    }
  );
  const {data: user} = useGetUserFilterParametersQuery(
    {
      query: { emailExact: inputValueUser }
    },
    {
      enabled: inputValueUser.length > 1,
    }
  );
  const {data: author} = useGetUserFilterParametersQuery(
    {
      query: { emailExact: inputValueAuthor }
    },
    {
      enabled: inputValueAuthor.length > 1,
    }
  );
  const checkboxChanged = (value: boolean) => { 
    setCheckboxChecked(value); 
    if(value === true) {
      setInputValueAuthor(email ?? "");
    }
    else {
      setInputValueAuthor(authorEmail ?? "");
    }
  }
  const schema = yup.object().shape({
    inputValueHistoryType: yup
      .string()
      .typeError(t("Message Error Wrong History Type") as string),
    inputValueAuthor: yup
      .string()
      .typeError(t("Message Error Wrong Author") as string),
    inputValueUser: yup
      .string()
      .typeError(t("Message Error Wrong User") as string),
    createdAt: yup
      .date()
      .typeError(t("Enter The Correct Date") as string),
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      content: historyRowData?.content,
      createdAt: historyRowData?.createdAt,
    },
  });
  const onSubmit = ({
    content,
    createdAt,
  }: FormInputs) => {
    putHistoryMutation(
      {
        payload: {
          content: content,
          createdAt: createdAt,
          toUser: (user?.["hydra:member"].length === 1) 
            ? "/api/v1/users/" + user?.["hydra:member"][0]?.id 
            : "",
          user: checkboxChecked 
            ? (author?.["hydra:member"].length === 1
              ? "/api/v1/users/" + author?.["hydra:member"][0]?.id 
              : "") 
            : undefined,
        },
        crmCodeId: historyRowData?.id,
        historyType: historyTypesData?.["hydra:member"]?.find(
          (temp) => temp.name === inputValueHistoryType
        ),
      },
      {
        onSuccess: () => {
          setOpenError(false);
          setOpenSuccess(true);
          refetch();
        },
        onError: (error: any) => {
          setOpenSuccess(false);
          setOpenError(true);
          setMessageError(error.response?.data["hydra:description"]);
        },
      }
    );
  };
  const { content, createdAt } = useWatch({ control: control });
  useEffect(() => {
    if(valuesLoaded === false) {
      if(email && toEmail && defaultHistoryTypeName) {
        if(inputValueAuthor === "" && authorEmail){
          setInputValueAuthor(authorEmail);
        }
        if(inputValueUser === ""){
          setInputValueUser(toEmail);
        }
        if(inputValueHistoryType === ""){
          setInputValueHistoryType(defaultHistoryTypeName);
        }
        setValuesLoaded(true);
      }
    }
    reset({
      email: email,
      content: content,
      createdAt: createdAt,
    });
  }, [email, authorEmail, defaultHistoryTypeName, user, author]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {putHistoryLoading && <Loader />}
      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={t("Language Code") as string}
        >
          <FormControl className={classes.textFieldCustom}>
            <Autocomplete
              noOptionsText={t("None")}
              sx={{ width: 300 }}
              onInputChange={(_, value) => setInputValueUser(value)}
              options={
                map(
                  uniqBy(userTypesData?.["hydra:member"], "email"),
                  (userType) => userType.email
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("User")}
                  variant="outlined"
                />
              )}
              value={inputValueUser}
              isOptionEqualToValue={(option, value) =>
                option === value
              }
            />
          </FormControl>
          <FormControl className={classes.textFieldCustom}>
            <Autocomplete
              noOptionsText={t("None")}
              sx={{ width: 200 }}
              onInputChange={(_, value) => setInputValueHistoryType(value)}
              options={
                map(
                  uniqBy(historyTypesData?.["hydra:member"], "name"),
                  (historyType) => `${historyType.name}`
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("History Type")}
                  variant="outlined"
                />
              )}
              value={inputValueHistoryType}
              isOptionEqualToValue={(option, value) =>
                option === value
              }
            />
          </FormControl>
          <FormControl className={classes.textFieldCustom}>
          <Controller
            control={control}
            name="content"
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                onChange={onChange}
                variant="standard"
                label={t("Change Comment")}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
          </FormControl>
          <FormControl className={classes.textFieldCustom}>
            <Controller
              control={control}
              name="createdAt"
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value}
                  onChange={onChange}
                  label={t("Date From")}
                  inputFormat={formatValueDatepicker}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EventIcon />
                      </InputAdornment>
                    ),
                  }}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "170px", mx: 2 }} />
                  )}
                />
              )}
            />
            {errors.createdAt && (
              <FormHelperText sx={{ color: "error.main" }}>
                {errors.createdAt.message}
              </FormHelperText>
            )}
          </FormControl>
          <Box>
            <FormControl className={classes.textFieldCustom}>
              <FormControlLabel
                label={t("Change Author?")}
                control={
                  <Checkbox
                    checked={checkboxChecked} 
                    onChange={ e => checkboxChanged(e.target.checked) } 
                    value={t("Author")}
                  />
                }
              />
            </FormControl>
            <FormControl className={classes.textFieldCustom}>
              <Autocomplete
                disabled={!checkboxChecked}
                noOptionsText={t("None")}
                sx={{ width: 300 }}
                onInputChange={(_, value) => setInputValueAuthor(value)}
                options={
                  map(
                    uniqBy(authorTypesData?.["hydra:member"], "email"),
                    (userType) => userType.email
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Author")}
                    variant="outlined"
                  />
                )}
                value={inputValueAuthor}
                isOptionEqualToValue={(option, value) =>
                  option === value
                }
              />
            </FormControl>
          </Box>
        </LocalizationProvider>
      </Box>
      <CustomButton
        text={t("You Save")}
        type="submit"
        style={{ mx: 5, mb: 5, mt: 5 }}
      />
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Data")}
        successMessage={t("Message Success Edit History")}
      />
    </form>
  );
}

export default EditHistoryCode;
