import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { DataGrid, GridColDef, plPL, GridFeatureMode } from "@mui/x-data-grid";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";
import { Checkbox, FormControlLabel } from "@mui/material";

interface TableBasicTypes {
  title?: string | undefined | null;
  loading: boolean;
  columns: GridColDef[];
  rows: Array<object> | undefined;
  onCheckboxSelect?: any;
  buttonTxt?: string | null;
  onCheckboxClick?: () => void;
  isHideFooter?: boolean;
  initialSortingTable?: string;
  setItemsPerPage?: (value: number) => void;
  totalItems?: number;
  setPageNumber?: (value: number) => void;
  queryFunction?: (value: boolean) => void;
  paginationMode: GridFeatureMode | undefined;
  useRowId?: boolean; 
  customPageSize?: number;
  additionalHeaderCheckBoxState?: boolean;
  setAdditionalHeaderCheckBoxState?: (value: boolean) => void;
  headerCheckBoxLabel?: string;
  styles?: any;
  getRowClassName?: any;
}
const TableBasic = ({
  title,
  loading,
  columns,
  rows,
  onCheckboxSelect,
  initialSortingTable,
  buttonTxt,
  onCheckboxClick,
  setItemsPerPage,
  totalItems,
  setPageNumber,
  queryFunction,
  isHideFooter,
  customPageSize,
  paginationMode,
  useRowId,
  additionalHeaderCheckBoxState,
  setAdditionalHeaderCheckBoxState,
  headerCheckBoxLabel,
  styles,
  getRowClassName,
}: TableBasicTypes) => {
  const isCheckbox = !!onCheckboxSelect && !!onCheckboxClick;
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: totalItems,
    page: 1,
    pageSize: customPageSize || 10,
  });
  useEffect(() => {
    setPageState((old) => ({ ...old, total: totalItems }));
    if (setPageNumber) {
      setPageNumber(pageState.page);
    }
    if (setItemsPerPage) {
      setItemsPerPage(pageState.pageSize);
    }
  }, [pageState.page, pageState.pageSize, totalItems]);

  const getRowId = (row: any) => {
    return row["@id"];
  };

  return (
    <Card>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <CardHeader title={title} />
        {setAdditionalHeaderCheckBoxState && (
          <FormControlLabel
            sx={{ mx: 5, my: 2 }}
            label={headerCheckBoxLabel}
            control={
              <Checkbox
                defaultChecked
                value={additionalHeaderCheckBoxState}
                onClick={() =>
                  setAdditionalHeaderCheckBoxState(
                    !additionalHeaderCheckBoxState
                  )
                }
              />
            }
          />
        )}
      </Box>
      <Box sx={{ height: 500 }}>
        <DataGrid
          disableColumnFilter
          hideFooter={isHideFooter}
          rows={rows || []}
          loading={loading}
          columns={columns}
          getRowClassName={(params) => getRowClassName ? getRowClassName(params.row) : ''}  
          sx={styles}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: initialSortingTable ? initialSortingTable : "id",
                  sort: "desc",
                },
              ],
            },
          }}
          pagination
          rowCount={pageState.total}
          page={pageState.page - 1}
          pageSize={pageState.pageSize}
          onSelectionModelChange={(id) => {
            onCheckboxSelect?.(id);
          }}
          paginationMode={paginationMode}
          rowsPerPageOptions={[10, 30, 50, 70, 100]}
          checkboxSelection={isCheckbox}
          localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
          onPageChange={(newPage) => {
            setPageState((old) => ({ ...old, page: newPage + 1 }));
            if (queryFunction) {
              queryFunction(true);
            }
          }}
          onPageSizeChange={(newPageSize) => {
            setPageState((old) => ({ ...old, pageSize: newPageSize }));
            if (queryFunction) {
              queryFunction(true);
            }
          }}
          getRowId={useRowId ? getRowId : undefined}
        />
      </Box>
      {isCheckbox && (
        <Button
          style={{ margin: "15px 15px 40px 15px" }}
          color="inherit"
          className="button-custom-styles"
          onClick={onCheckboxClick}
          variant="contained"
        >
          {buttonTxt}
        </Button>
      )}
    </Card>
  );
};

export default TableBasic;
