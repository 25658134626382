import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputAdornment,
  Checkbox,
  Autocomplete,
  FormControlLabel,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { formatValueDatepicker } from "helpers/Global";
import EventIcon from "@mui/icons-material/Event";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { Loader } from "components/Loader/Loader";
import usePostChangeHistoryCodeMutation from "hooks/usePostChangeHistoryCodeMutation";
import CustomButton from "components/Buttons/CustomButton";
import { EmailAlert } from "mdi-material-ui";
import useWhoIAmQuery from "hooks/useWhoIAmQuery";
import dayjs from "dayjs";
import useGetUserEmailQuery from "hooks/useGetUserEmailQuery";
import { map, uniqBy } from "lodash";
import useGetUserFilterParametersQuery from "hooks/useGetUserFilterParametersQuery";
import FormHelperText from "@mui/material/FormHelperText";

export const useStyles = makeStyles(() => ({
  textFieldCustom: {
    margin: " 15px 25px",
  },
}));
interface FormInputs {
  createdAt: string | null;
  content: string;
}
interface AddChangeHistoryCodeTypes {
  refetch: () => void;
}

function AddChangeHistoryCode({ refetch }: AddChangeHistoryCodeTypes) {
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValueAuthor, setInputValueAuthor] = useState("");
  const [inputValueUser, setInputValueUser] = useState("");
  const { t } = useTranslation();
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [messageError, setMessageError] = useState();
  const { mutate: postChangeHistoryMutation, isLoading: postChangeHistoryCodeLoading } = usePostChangeHistoryCodeMutation();
  const { data: currentUser } = useWhoIAmQuery();
  const user_id = "/api/v1/users/" + currentUser?.user_id;
  const to_user_id = "/api/v1/users/" + window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  const { data: email } = useGetUserEmailQuery(
    {
      "id": `${user_id}`,
    },
    {
      enabled: !!user_id 
    }
  );
  const { data: toEmail } = useGetUserEmailQuery(
    {
      "id": `${to_user_id}`,
    },
    {
      enabled: !!to_user_id 
    }
  );
  const { data: changeHistoryTypesDataAuthor } = useGetUserFilterParametersQuery(
    {
      query: { email: inputValueAuthor },
    },
    {
      enabled: inputValueAuthor.length > 1,
    }
  );
  const { data: changeHistoryTypesDataUser } = useGetUserFilterParametersQuery(
    {
      query: { email: inputValueUser },
    },
    {
      enabled: inputValueUser.length > 1,
    }
  );
  const { data: author } = useGetUserFilterParametersQuery(
    {
      query: { emailExact: inputValueAuthor }
    },
    {
      enabled: inputValueAuthor.length > 1,
    }
  )
  const { data: user } = useGetUserFilterParametersQuery(
    {
      query: { emailExact: inputValueUser }
    },
    {
      enabled: inputValueUser.length > 1,
    }
  )
  const schema = yup.object().shape({
  inputValueAuthor: yup
    .string()
    .typeError(t("Message Error Wrong Author") as string),
  inputValueUser: yup
    .string()
    .typeError(t("Message Error Wrong User") as string),
  createdAt: yup
    .date()
    .typeError(t("Enter The Correct Date") as string),
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      createdAt: dayjs().format(),
      content: ""
    },
  });
  const onSubmit = ({
    createdAt,
    content,
  }: FormInputs) => {
    postChangeHistoryMutation(
      {
        payload: {
          type: "/api/v1/crm_types/30",
          createdAt: createdAt,
          content: content,
          toUser: (user?.["hydra:member"].length === 1) 
            ? "/api/v1/users/" + user?.["hydra:member"][0]?.id 
            : "",
          user: checkboxChecked 
            ? (author?.["hydra:member"].length === 1
              ? "/api/v1/users/" + author?.["hydra:member"][0]?.id 
              : "") 
            : user_id,
        },
      },
      {
        onSuccess: () => {
          setOpenError(false);
          setOpenSuccess(true);
          refetch();
        },
        onError: (error: any) => {
          setOpenSuccess(false);
          setOpenError(true);
          setMessageError(error.response?.data["hydra:description"]);
        },
      }
    );
  };
  const { content, createdAt } = useWatch({ control: control });
  useEffect(() => {
    if(email && inputValueAuthor === ""){
      setInputValueAuthor(email);
    }
    if(toEmail && inputValueUser === ""){
      setInputValueUser(toEmail);
    }
    reset({
      content: content,
      createdAt: createdAt
    });
  }, [email]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {postChangeHistoryCodeLoading && <Loader />}
      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={t("Language Code") as string}
        >
          <FormControl className={classes.textFieldCustom}>
            <Controller
              control={control}
              name="createdAt"
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value}
                  onChange={onChange}
                  label={t("Date From")}
                  inputFormat={formatValueDatepicker}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EventIcon />
                      </InputAdornment>
                    ),
                  }}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "170px", mx: 2 }} />
                  )}
                />
              )}
            />
            {errors.createdAt && (
              <FormHelperText sx={{ color: "error.main" }}>
                {errors.createdAt.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl className={classes.textFieldCustom}>
          <Controller
            control={control}
            name="content"
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                onChange={onChange}
                variant="standard"
                label={t("Change Content")}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
          </FormControl>
          <FormControl className={classes.textFieldCustom}>
              <Autocomplete
                noOptionsText={t("None")}
                sx={{ width: 300 }}
                onInputChange={(_, value) => setInputValueUser(value)}
                options={
                  map(
                    uniqBy(changeHistoryTypesDataUser?.["hydra:member"], "email"),
                    (changeHistoryType: any) => changeHistoryType.email
                  ) || []
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("User")}
                    variant="outlined"
                  />
                )}
                value={inputValueUser}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
              />
          </FormControl>
          <Box>
            <FormControl className={classes.textFieldCustom}>
              <FormControlLabel
                label={t("Change The Author")}
                control={
                  <Checkbox
                    checked={checkboxChecked} 
                    onChange={ e => setCheckboxChecked(e.target.checked)} 
                    value={t("Author")}
                  />
                }
              />
            </FormControl>
            <FormControl className={classes.textFieldCustom}>
                <Autocomplete
                  disabled={!checkboxChecked}
                  noOptionsText={t("None")}
                  sx={{ width: 300 }}
                  onInputChange={(_, value) => setInputValueAuthor(value)}
                  options={
                    map(
                      uniqBy(changeHistoryTypesDataAuthor?.["hydra:member"], "email"),
                      (changeHistoryType: any) => changeHistoryType.email
                    ) || []
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Author")}
                      variant="outlined"
                    />
                  )}
                  value={inputValueAuthor}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                />
            </FormControl>
          </Box>
        </LocalizationProvider>
      </Box>
      <CustomButton
        text={t("You Save")}
        type="submit"
        style={{ mx: 5, mb: 5, mt: 5 }}
      />
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Wrong User")}
        successMessage={t("Message Success Add Change History")}
      />
    </form>
  );
}

export default AddChangeHistoryCode;
