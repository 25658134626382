import React, { useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { formatValueDatepicker, formatValueDateTime } from "helpers/Global";
import EventIcon from "@mui/icons-material/Event";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { Loader } from "components/Loader/Loader";
import { discountCodesOptions } from "constans/Constans";
import usePostDiscountMutation from "hooks/usePostDiscountMutation";
import useGetPaymentTypeQuery from "hooks/useGetPaymentTypeQuery";
import dayjs from "dayjs";
import CustomButton from "components/Buttons/CustomButton";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
    },
  },
};
export const useStyles = makeStyles(() => ({
  textFieldCustom: {
    margin: " 15px 25px",
  },
}));
interface FormInputs {
  paymentType: string;
  dateFrom: string;
  dateTo: string;
  withoutExpiration: boolean;
  options: string[];
  amount: number | null;
  text: string;
  quantity: string | null;
}
type Props = {
  refetch: () => void;
};

function AddDiscountCode({ refetch }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [messageError, setMessageError] = useState();
  const { mutate: postDiscountMutation, isLoading: postDiscountCodeLoading } =
    usePostDiscountMutation();
  const { data: paymentType } = useGetPaymentTypeQuery({
    query: {
      statusWithoutBit1And2: true,
      type: "1",
      itemsPerPage: 100,
      "order[type]": "asc",
      "order[displayOrder]": "asc",
    },
  });

  const schema = yup.object().shape({
    amount: yup
      .number()
      .typeError(t("Invalid Value") as string)
      .required(t("This Field is Required") as string),
    quantity: yup
      .string()
      .test("is-valid", "Invalid quantity", function (value) {
        const { errors } = this.parent;
        return !(errors?.quantity && value !== null && value !== "");
      })
      .typeError(t("Invalid Value") as string),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      options: [],
      dateFrom: dayjs(new Date()).format(formatValueDateTime),
      dateTo: dayjs(new Date()).format(formatValueDateTime),
    },
  });
  const { withoutExpiration } = useWatch({ control: control });
  const onSubmit = ({
    paymentType,
    dateFrom,
    dateTo,
    withoutExpiration,
    options,
    amount,
    text,
    quantity,
  }: FormInputs) => {
    const submittedOptions = options.reduce(
      (acc: Record<string, boolean>, option) => {
        acc[option] = true;
        return acc;
      },
      {}
    );
    postDiscountMutation(
      {
        paymentType: paymentType,
        dateFrom: dayjs(dateFrom).format(formatValueDateTime),
        dateTo: withoutExpiration
          ? "9999-12-31T00:00:00+00:00"
          : dayjs(dateTo).format(formatValueDateTime),
        options: submittedOptions,
        amount: amount,
        text: text,
        quantity: quantity ? parseInt(quantity) : null,
      },
      {
        onSuccess: () => {
          setOpenError(false);
          setOpenSuccess(true);
          refetch();
        },
        onError: (error: any) => {
          setOpenSuccess(false);
          setOpenError(true);
          setMessageError(error.response?.data["hydra:description"]);
        },
      }
    );
  };
  const options = discountCodesOptions.map((row) => ({
    name: row.name,
    value: row.value,
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {postDiscountCodeLoading && <Loader />}
      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        <FormControl className={classes.textFieldCustom}>
          <InputLabel id="add-inscriptions-user-select-label">
            {t("Type")}
          </InputLabel>
          <Controller
            control={control}
            name="paymentType"
            render={({ field: { value, onChange } }) => (
              <Select
                label={t("Type")}
                value={value}
                sx={{ width: 250 }}
                onChange={onChange}
                MenuProps={MenuProps}
                labelId="add-inscriptions-user-select-label"
              >
                {paymentType?.["hydra:member"].map((getCrmsTypes) => (
                  <MenuItem key={getCrmsTypes.id} value={getCrmsTypes["@id"]}>
                    {getCrmsTypes.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={t("Language Code") as string}
        >
          <FormControl className={classes.textFieldCustom}>
            <Controller
              control={control}
              name="dateFrom"
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value}
                  onChange={onChange}
                  label={t("Date From")}
                  inputFormat={formatValueDatepicker}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EventIcon />
                      </InputAdornment>
                    ),
                  }}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "170px", mx: 2 }} />
                  )}
                />
              )}
            />
          </FormControl>
          {!withoutExpiration && (
            <FormControl className={classes.textFieldCustom}>
              <Controller
                control={control}
                name="dateTo"
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    value={value}
                    onChange={onChange}
                    label={t("Expiration Date")}
                    inputFormat={formatValueDatepicker}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <EventIcon />
                        </InputAdornment>
                      ),
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "170px", mx: 2 }} />
                    )}
                  />
                )}
              />
            </FormControl>
          )}
        </LocalizationProvider>
        <FormControl className={classes.textFieldCustom}>
          <Controller
            control={control}
            name="withoutExpiration"
            render={({ field: { value, onChange } }) => (
              <FormControlLabel
                label={t("Without Expiration")}
                control={
                  <Checkbox
                    value={value}
                    onChange={onChange}
                    checked={!!value}
                  />
                }
              />
            )}
          />
        </FormControl>
        <FormControl
          className={classes.textFieldCustom}
          sx={{ width: "220px" }}
        >
          <InputLabel id="discount-option-selectBox-label">
            {t("Option")}
          </InputLabel>
          <Controller
            control={control}
            name="options"
            render={({ field: { value, onChange } }) => (
              <Select
                multiple
                value={value}
                onChange={onChange}
                sx={{ m: 0, minWidth: 220 }}
                variant="standard"
                id="discount-option-select"
                labelId="discount-option-selectBox-label"
              >
                {options?.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
        <FormControl className={classes.textFieldCustom}>
          <Controller
            control={control}
            name="amount"
            render={({ field: { value, onChange } }) => (
              <>
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!errors.amount && value !== null}
                  variant="standard"
                  label={t("Price After Discount")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 7 }}
                />
                {errors.amount && value !== null && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {errors.amount.message}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </FormControl>
        <FormControl className={classes.textFieldCustom}>
          <Controller
            control={control}
            name="text"
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                onChange={onChange}
                variant="standard"
                label={t("Discount Text")}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  maxLength: 20,
                }}
              />
            )}
          />
        </FormControl>
        <FormControl className={classes.textFieldCustom}>
          <Controller
            control={control}
            name="quantity"
            render={({ field: { value, onChange } }) => (
              <>
                <TextField
                  value={value}
                  onChange={onChange}
                  variant="standard"
                  error={!!errors.quantity}
                  label={t("Counter Of Remaining Codes")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 7 }}
                />
              </>
            )}
          />
        </FormControl>
      </Box>
      <CustomButton
        text={t("You Save")}
        style={{ mx: 5, mb: 5, mt: 5 }}
        type="submit"
      />
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={
          messageError
            ? t("Wrong Data Entered Or Discount Code Already Exists")
            : t("Message Error Add Discount")
        }
        successMessage={t("Message Success Add Discount Code")}
      />
    </form>
  );
}

export default AddDiscountCode;
