import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { API_PREFIX, getApiClient } from "helpers/Global";
import { components } from "schema/schema";

const pathCrm = `${API_PREFIX}/crms/`;

type Payload = components["schemas"]["Crm.jsonld"];
type Response = {
    CrmData: components["schemas"]["Crm.jsonld"];
    userData: User;
};
type HistoryType = components["schemas"]["CrmType.jsonld"];
type CrmCodeId = components["schemas"]["Crm.jsonld"]["id"]
type Params = {
  payload: Payload;
  crmCodeId: CrmCodeId;
  historyType: HistoryType | undefined;
};
type User = {
    id: number;
};

const putHistoryMutation = async ({ payload, crmCodeId, historyType }: Params) => {
  if(historyType) {
    payload["type"] = '/api/v1/crm_types/' + historyType.id;
  }
  const { data } = await getApiClient().put(`${pathCrm}${crmCodeId}`, payload);
  return data;
};

export default function usePutHistoryMutation(
  options: UseMutationOptions<Response, AxiosError, Params> = {}
) {
  return useMutation<Response, AxiosError, Params>(putHistoryMutation, options);
}
