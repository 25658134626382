import {
  Box,
  InputAdornment,
  Typography,
  useMediaQuery,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React, { useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import TableBasic from "components/TableBasic/TableBasic";
import { useTranslation } from "react-i18next";
import useGetTransactionsListCsvQuery from "hooks/useGetTransactionsListCsvQuery";
import { Loader } from "components/Loader/Loader";
import CustomButton from "components/Buttons/CustomButton";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import dayjs from "dayjs";
import useGetTransactionsListQuery from "hooks/useGetTransactionsListQuery";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTheme } from "@mui/material/styles";
import EventIcon from "@mui/icons-material/Event";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { formatValueDatepicker } from "helpers/Global";
import { useForm } from "react-hook-form";

export interface FormInputs {
  dateFrom: string,
  dateTo: string,
  isWholePeriod: boolean,
}

function TransactionsReport() {
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [CSVEnabled, setCSVEnabled] = useState(false);
  const today = dayjs();
  const { control } = useForm<FormInputs>({
    defaultValues: {
      dateFrom: "2000-01-01",
    },
  });
  const { dateFrom, dateTo, isWholePeriod } = useWatch({ control });
  const queryValues = {
    page: pageNumber,
    itemsPerPage: itemsPerPage,
    "verificationDate[after]": isWholePeriod ? undefined : dayjs(dateFrom).format('YYYY-MM-DD'),
    "verificationDate[before]": isWholePeriod ? undefined : dayjs(dateTo).add(1, 'day').format('YYYY-MM-DD'),
  };
  const { data: RowsData, isLoading: RowsDataLoading } = useGetTransactionsListQuery(
    {
      query: queryValues
    },
  );
  const GetCsv = () => {
    setCSVEnabled(true);
  };
  const { data: CsvData, isLoading: CsvDataLoading } = useGetTransactionsListCsvQuery(
    {
      query: queryValues,
    },
    {
      enabled: CSVEnabled,
      onSettled: () => {
        setCSVEnabled(false);
      },
    }
  );
  const columns: GridColDef[] = [
    {
      flex: 0.2,
      minWidth: 200,
      field: "fullName",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("First Name and Last Name"),
    },
    {
      flex: 0.2,
      minWidth: 200,
      field: "login",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Login"),
    },
    {
      flex: 0.15,
      minWidth: 80,
      field: "cardNumber",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Card Number"),
    },
    {
      flex: 0.15,
      minWidth: 300,
      field: "paymentTypeName",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Payment Type"),
    },
    {
      flex: 0.15,
      minWidth: 300,
      field: "comment",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Comment"),
    },
    {
      flex: 0.15,
      minWidth: 150,
      field: "verificationDate",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Date Of Payment"),
      valueFormatter: (params) => dayjs(params.value).isValid() ? dayjs(params.value).format('YYYY-MM-DD') : "",
    },
    {
      flex: 0.15,
      minWidth: 100,
      field: "amountInBaseUnit",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Amount"),
      valueFormatter: (params) => params.value + " zł",
    },
    {
      flex: 0.15,
      minWidth: 200,
      field: "userEnteredByFullName",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Introduced"),
    },
    {
      flex: 0.15,
      minWidth: 200,
      field: "addedDate",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Date"),
      valueFormatter: (params) => dayjs(params.value).isValid() ? dayjs(params.value).format('YYYY-MM-DD') : "",
    },
    {
      flex: 0.15,
      minWidth: 200,
      field: "dateFromOfContribution",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("In Membership Since"),
      valueFormatter: (params) => dayjs(params.value).isValid() ? dayjs(params.value).format('YYYY-MM-DD') : "",
    },
    {
      flex: 0.15,
      minWidth: 200,
      field: "dateToOfContribution",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Membership Paid To"),
      valueFormatter: (params) => dayjs(params.value).isValid() ? dayjs(params.value).format('YYYY-MM-DD') : "",
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: onlySmallScreen ? "column" : "row",
          mt: onlySmallScreen ? 5 : 0,
          mb: 5,
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={t("Language Code") as string}
        >
          <Typography>{t("Entry Date")}</Typography>
          <Controller
            control={control}
            name="dateFrom"
            render={({ field: { value, onChange } }) => (
              <DatePicker
                disabled={isWholePeriod}
                maxDate={today}
                inputFormat={formatValueDatepicker}
                value={value}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EventIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={onChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={formatValueDatepicker}
                    sx={{ width: "180px", mx: 2 }}
                  />
                )}
              />
            )}
          />
          <Typography>{t("To")}</Typography>
          <Controller
            control={control}
            name="dateTo"
            render={({ field: { value, onChange } }) => (
              <DatePicker
                disabled={isWholePeriod}
                maxDate={today}
                inputFormat={formatValueDatepicker}
                value={value}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EventIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={onChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={formatValueDatepicker}
                    sx={{
                      width: "180px",
                      mx: 2,
                    }}
                    error={dateFrom && dateTo ? dateFrom > dateTo : false}
                    helperText={
                      dateFrom && dateTo ? dateFrom > dateTo
                        ? t("Date Range Validation")
                        : "" : ""
                    }
                    FormHelperTextProps={{
                      style: { position: "absolute", bottom: "-25px" },
                    }}
                  />
                )}
              />
            )}
          />
        </LocalizationProvider>
        <Controller
          control={control}
          name="isWholePeriod"
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              label={t("Show Whole History")}
              control={
                <Checkbox checked={value} onChange={onChange} sx={{ ml: 10 }} />
              }
            />
          )}
        />
      </Box>
      <TableBasic
        paginationMode="server"
        columns={columns}
        loading={RowsDataLoading}
        rows={RowsData?.["hydra:member"]}
        title={t("Transactions Report")}
        setItemsPerPage={setItemsPerPage}
        totalItems={RowsData?.["hydra:totalItems"]}
        setPageNumber={setPageNumber}
      />
      {CsvDataLoading && <Loader />}
      <CustomButton
        text={t("Generate CSV")}
        style={{ mx: 5, mb: 5, mt: 5 }}
        onClickFunction={() => GetCsv()}
      />
      {CsvData && !CsvDataLoading && (
        <CSVLink
          className={"csv-button-link"}
          data={String(CsvData)}
          filename={`transactions_report_${format(
            new Date(),
            "yyyy-MM-dd HH:mm:ss"
          )}.csv`}
          target="_blank"
        >
          <Button
            color="inherit"
            variant="contained"
            className="button-custom-styles"
            sx={{ mx: 5, mb: 5, mt: 5 }}
          >
            {t("Download")}
          </Button>
        </CSVLink>
      )}
    </div>
  );
}

export default TransactionsReport;
