import { AxiosError } from "axios";
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "react-query";
import { API_PREFIX, getApiClient } from "helpers/Global";
import { components, operations } from "schema/schema";

const path = `${API_PREFIX}/ads/`;

type Response = operations["api_apiv1ads_id_get"]["responses"]["200"]["content"]["application/ld+json"];
type Id = {
  id: number;
};

const getAdByIdQuery = async (id: Id) => {

  const { data } = await getApiClient().get(path + id.id);
  return data;
};

export default function useGetAdsByIdQuery(
  id: Id,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [id],
    () => getAdByIdQuery(id),
    options
  );
}
