import React from "react";
import { Control, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { validateEmail } from "helpers/Global";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  label: string;
  control: Control<any>;
  editedValue?: boolean;
  variant?: "standard" | "outlined";
  error?: boolean;
  isShrink?: boolean;
}

function EmailFieldValidate({
  name,
  label,
  control,
  editedValue,
  variant,
  error,
  isShrink,
}: Props) {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <TextField
          value={value}
          onChange={onChange}
          variant={variant}
          type="email"
          label={label}
          InputLabelProps={{ shrink: isShrink }}
          error={
            ((!validateEmail(value) && value !== "" && value !== undefined) || !!error) && editedValue
          }
          helperText={
            !validateEmail(value) && value !== "" && value !== undefined && editedValue
              ? t("Error Email Validate")
              : ""
          }
          InputProps={{
            disabled: !editedValue,
          }}
        />
      )}
    />
  );
}

export default EmailFieldValidate;
