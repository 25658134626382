import React from "react";
import { Box, Button, Card, Dialog, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import EditContributionCode from "./EditContributionCode";
import { ContributionTypes } from "types/types";

interface EditContributionModalTypes {
  showContributionModal: boolean;
  setShowContributionModal: (val: boolean) => void;
  refetch: () => void;
  contributionRowData: ContributionTypes | undefined;
}

function EditContributionModal({
  showContributionModal,
  setShowContributionModal,
  contributionRowData,
  refetch,
}: EditContributionModalTypes) {
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Dialog
        open={showContributionModal}
        BackdropProps={{
          style: {
            backgroundColor: theme.palette.customColors.modalBgc,
          },
        }}
      >
        <Box
          className="array-modal"
          sx={{
            width: onlyMediumScreen ? "90vw" : "70vw",
            minHeight: 150,
            px: onlySmallScreen ? 5 : "unset",
          }}
        >
          <Card
            sx={{
              minHeight: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingRight: onlySmallScreen ? "unset" : "50px",
              flexWrap: "wrap",
              py: 5,
            }}
          >
            <EditContributionCode
              contributionRowData={contributionRowData}
              refetch={refetch}
            />
          </Card>
          <Button
            className="array-close-button"
            onClick={() => setShowContributionModal(false)}
          >
            <CloseIcon />
          </Button>
        </Box>
      </Dialog>
    </>
  );
}
export default EditContributionModal;
