import React from "react";
import { Box, Button, Card, Dialog, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import EditHistoryCode from "./EditHistoryCode";
import { HistoryTypes } from "types/types";

interface EditHistoryModalTypes {
  showHistoryModal: boolean;
  setShowHistoryModal: (val: boolean) => void;
  refetch: () => void;
  historyRowData: HistoryTypes | undefined;
}

function EditHistoryModal({
  showHistoryModal,
  setShowHistoryModal,
  historyRowData,
  refetch,
}: EditHistoryModalTypes) {
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Dialog
        open={showHistoryModal}
        BackdropProps={{
          style: {
            backgroundColor: theme.palette.customColors.modalBgc,
          },
        }}
      >
        <Box
          className="array-modal"
          sx={{
            width: onlyMediumScreen ? "90vw" : "70vw",
            minHeight: 150,
            px: onlySmallScreen ? 5 : "unset",
          }}
        >
          <Card
            sx={{
              minHeight: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingRight: onlySmallScreen ? "unset" : "50px",
              flexWrap: "wrap",
              py: 5,
            }}
          >
            <EditHistoryCode
              historyRowData={historyRowData}
              refetch={refetch}
            />
          </Card>
          <Button
            className="array-close-button"
            onClick={() => setShowHistoryModal(false)}
          >
            <CloseIcon />
          </Button>
        </Box>
      </Dialog>
    </>
  );
}

export default EditHistoryModal;
