import React, { useState } from "react";
import TableBasic from "components/TableBasic/TableBasic";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import useGetContributionsQuery from "hooks/useGetContributionsQuery";
import { operations } from "schema/schema";
import dayjs from "dayjs";
import { formatValueDateTime } from "helpers/Global";
import UseHasAccess from "hooks/useHasAccess";
import { Role } from "helpers/Global";
import ActionsButton from "./ActionsButton";
import EditContributionModal from "components/PageEditUser/TableSubpages/EditContributionModal";
import { ContributionTypes } from "types/types";

interface UserContributionsTypes {
  userInformationData:
    | operations["api_apiv1users_id_get"]["responses"]["200"]["content"]["application/ld+json"]
    | undefined;
}
function Contributions({ userInformationData }: UserContributionsTypes) {
  const { t } = useTranslation();
  const hasAccess = UseHasAccess([Role.ROLE_SUPER_USER]).length > 0;
  const [openEditContributionModal, setOpenEditContributionModal] = useState(false);
  const [contributionRowData, setContributionRowData] = useState<ContributionTypes>();
  const { data: contributionsData, refetch, isLoading: userContributionLoading } = useGetContributionsQuery({
    query: {
      "user.id": userInformationData?.id,
      "order[dateTo]": "desc",
    },
  });

  const columns: GridColDef[] = [
    {
      flex: 0.1,
      field: "nameOfContributionPaid",
      minWidth: 300,
      headerName: t("Type Of Contributions Paid") as string,
      valueFormatter: (params) => {
        return params.value == null
          ? t("Entry Of Paid Contributions")
          : params.value;
      },
    },
    {
      flex: 0.1,
      field: "dataVerify",
      minWidth: 30,
      headerName: t("Date Of Paid") as string,
      valueFormatter: (params) =>
        params.value
          ? dayjs(params.value).format(formatValueDateTime)
          : t("No Data"),
    },
    {
      flex: 0.1,
      field: "dateFrom",
      minWidth: 30,
      headerName: t("Active Since") as string,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("YYYY-MM-DD") : "0000-00-00",
    },
    {
      flex: 0.1,
      field: "dateTo",
      minWidth: 30,
      headerName: t("Active To") as string,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("YYYY-MM-DD") : "0000-00-00",
    },
    {
      flex: 0.1,
      field: "commentP24",
      minWidth: 130,
      headerName: t("Comments") as string,
      valueFormatter: (params) => {
        return params.value == null ? t("No Data") : params.value;
      },
    },
    {
      flex: 0.1,
      field: "introduced",
      minWidth: 100,
      headerName: t("Introduced") as string,
      valueFormatter: (params) => {
        return params.value == null ? t("No Data") : params.value;
      },
    },
    {
      flex: 0.1,
      field: "entryDate",
      minWidth: 30,
      headerName: t("Date Of Entry") as string,
      valueFormatter: (params) =>
        params.value
          ? dayjs(params.value).format(formatValueDateTime)
          : t("No Data"),
    },
    {
      flex: 0.1,
      minWidth: 90,
      hide: !hasAccess,
      sortable: false,
      disableColumnMenu: true,
      field: "actions",
      headerName: t("Actions") as string,
      renderCell: ({ row }) =>
        hasAccess && (
          <ActionsButton id={row.id} refetch={refetch} 
          setOpenEditContributionModal={setOpenEditContributionModal}
          rowData={row}
          setContributionRowData={setContributionRowData}
          />
        ),
    },
  ];

  return (
    <Grid item xs={12}>
      <TableBasic
        paginationMode="client"
        columns={columns}
        loading={userContributionLoading}
        rows={contributionsData?.["hydra:member"]}
        title={t("Contributions")}
        isHideFooter={true}
      />
        <EditContributionModal
          refetch={refetch}
          showContributionModal={openEditContributionModal}
          setShowContributionModal={setOpenEditContributionModal}
          contributionRowData={contributionRowData}
        />
    </Grid>
  );
}

export default Contributions;
