import React, { useState } from "react";
import useGetUsersListReportQuery from "hooks/useGetUsersListReportQuery";
import { GridColDef } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import TableBasic from "components/TableBasic/TableBasic";
import { useTranslation } from "react-i18next";
import useGetUsersListReportCsvQuery from "hooks/useGetUsersListReportCsvQuery";
import { Loader } from "components/Loader/Loader";
import CustomButton from "components/Buttons/CustomButton";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import dayjs from "dayjs";

function UsersListReport() {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [CSVEnabled, setCSVEnabled] = useState(false);
  const GetCsv = () => {
    setCSVEnabled(true);
  };
  const queryValues = {
    page: pageNumber,
    itemsPerPage: itemsPerPage,
  };
  const { data: RowsData, isLoading: RowsDataLoading } = useGetUsersListReportQuery(
    {
      query: queryValues,
    },
  );
  const { data: CsvData, isLoading: CsvDataLoading } = useGetUsersListReportCsvQuery(
    {
      query: queryValues,
    },
    {
      enabled: CSVEnabled,
      onSettled: () => {
        setCSVEnabled(false);
      },
    }
  );
  const columns: GridColDef[] = [
    {
      flex: 0.2,
      minWidth: 200,
      field: "fullName",
      valueGetter: (params) => {
        return `${params.row.firstName || ''} ${params.row.lastName || ''}`;
      },
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("First Name and Last Name"),
    },
    {
      flex: 0.2,
      minWidth: 200,
      field: "email",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Login"),
    },
    {
      flex: 0.15,
      minWidth: 80,
      field: "cardNumber",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Card Number"),
    },
    {
      flex: 0.15,
      minWidth: 200,
      field: "contributionExpirationDate",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Membership Paid To"),
      valueFormatter: (params) => dayjs(params.value).isValid() ? dayjs(params.value).format('YYYY-MM-DD') : "",
    },
    {
      flex: 0.15,
      minWidth: 200,
      field: "p24DateNewestTransaction",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Date Newest Transaction"),
      valueFormatter: (params) => dayjs(params.value).isValid() ? dayjs(params.value).format('YYYY-MM-DD   HH:mm:ss') : "",
    },
    {
      flex: 0.15,
      minWidth: 150,
      field: "p24AmmountTransaction",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Amount Transaction"),
      valueFormatter: (params) => params.value === null ? "" : params.value + " zł",
    },
    {
      flex: 0.15,
      minWidth: 300,
      field: "p24TypeTransaction",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Payment Type"),
    },
    {
      flex: 0.15,
      minWidth: 300,
      field: "p24DateCommentTransaction",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Comment Newest Transaction"),
    },
    {
      flex: 0.15,
      minWidth: 150,
      field: "inMembershipSince",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("In Membership Since"),
      valueFormatter: (params) => dayjs(params.value).isValid() ? dayjs(params.value).format('YYYY-MM-DD') : "",
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <TableBasic
        paginationMode="server"
        columns={columns}
        loading={RowsDataLoading}
        rows={RowsData?.["hydra:member"]}
        title={t("Users List Report")}
        setItemsPerPage={setItemsPerPage}
        totalItems={RowsData?.["hydra:totalItems"]}
        setPageNumber={setPageNumber}
      />
      {CsvDataLoading && <Loader />}
      <CustomButton
        text={t("Generate CSV")}
        style={{ mx: 5, mb: 5, mt: 5 }}
        onClickFunction={() => GetCsv()}
      />
      {CsvData && !CsvDataLoading && (
        <CSVLink
          className={"csv-button-link"}
          data={String(CsvData)}
          filename={`users_list_report_${format(
            new Date(),
            "yyyy-MM-dd HH:mm:ss"
          )}.csv`}
          target="_blank"
        >
          <Button
            color="inherit"
            variant="contained"
            className="button-custom-styles"
            sx={{ mx: 5, mb: 5, mt: 5 }}
          >
            {t("Download")}
          </Button>
        </CSVLink>
      )}
    </div>
  );
}

export default UsersListReport;
