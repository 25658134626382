import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Card,
  Divider,
  FormControl,
  Typography,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import FormHelperText from "@mui/material/FormHelperText";
import { styled, useTheme } from "@mui/material/styles";
import "dayjs/locale/pl";
import "dayjs/locale/en";
import { FormControlProps } from "@mui/material/FormControl";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Loader } from "components/Loader/Loader";
import EmailFieldValidate from "components/ValidateFields/EmailFieldValidate";
import CustomButton from "components/Buttons/CustomButton";
import CodeMirror from '@uiw/react-codemirror';
import { loadLanguage, langNames, langs } from '@uiw/codemirror-extensions-langs';
import { htmlToText } from 'html-to-text';
import useGetWhoAmI from "hooks/useWhoIAmQuery";
import ViewNewsletterModal from "./ViewNewsletterModal";
import useGetNewsletterHistoryQuery from "hooks/useGetNewsletterHistoryQuery";
import useDeleteNewsletterHistory from "hooks/useDeleteNewsletterHistory";
import useDeleteNewsletterHistoryFile from "hooks/useDeleteNewsletterHistoryFile";
import usePutNewsletterHistoryMutation from "hooks/usePutNewsletterHistoryMutation";
import { useLocation } from 'react-router-dom';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { Dashboard } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import useGetNewsletterHistoriesFileQuery from "hooks/useGetNewsletterHistoriesFileQuery";
import { useNavigate } from "react-router-dom";
import DeleteOutline from "mdi-material-ui/DeleteOutline";
import TwoStepsAcceptModal from "components/TwoStepsAcceptModal/TwoStepsAcceptModal";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import NewsletterHistoryCreate from "components/PageEditNewsletterHistory/NewsletterHistoryCreate";

export interface FormInputs {
  title: string;
  senderEmail: string;
  senderName: string;
  reply: string;
  replyName: string;
  contentTxt: string;
  contentHtml: string;
}

const FormControlCustom = styled(FormControl)<FormControlProps>(
  ({ theme }) => ({
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 260,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: 500,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 800,
    },
    margin: "0.5rem 1.25rem",
  })
);
function NewsletterHistoryData() {

  const { t } = useTranslation();
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const schema = yup.object().shape({
    title: yup
      .string()
      .required(t("This Field is Required") as string)
      .min(2, t("Title is too short") as string),
    senderEmail: yup.string().required(t("This Field is Required") as string),
    reply: yup.string(),
    replyName: yup.string().when('reply', {
      is: (reply: string) => !!reply,
      otherwise: yup.string(),
    }),
  });
  const {
    control: control,
    watch,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      title: "",
      senderEmail: "",
      senderName: "",
      reply: "",
      replyName: "",
    },
  });
  const [openViewNewsletterModal, setOpenViewNewsletterModal] = useState(false);
  const [formData, setFormData] = useState({});
  const { data: WhoAmI } = useGetWhoAmI();
  const location = useLocation();
  const [newsletterId, setNewsletterId] = useState<number | undefined>(undefined);
  const navigate = useNavigate();

  const { data: RowsData, isLoading: RowsDataLoading, refetch } = useGetNewsletterHistoryQuery(
    {
      query: newsletterId || location.state?.id ?
        { id: newsletterId || location.state?.id } :
        {
          status: location.state?.status || 0,
          "user.id": location.state?.userId?.user_id || WhoAmI?.user_id,
          id: newsletterId || location.state?.id,
        }
    },
    {
      onSuccess: (data) => {
        setNewsletterId(data?.["hydra:member"][0]?.id);
      }
    }
  );

  const [contentHtml, setCode] = useState('');

  const {
    title = '',
    senderEmail = '',
    senderName = '',
    reply = '',
    replyName = '',
    contentTxt = ''
  } = RowsData?.["hydra:member"][0] || {};
  const contentHtmlFromDb = RowsData?.["hydra:member"][0]?.contentHtml || '';

  function formatHtml(contentHtmlFromDb: string) {
    var formattedHtml = contentHtmlFromDb;
    return formattedHtml;
  }

  useEffect(() => {
    setValue('title', title || '')
    setValue('senderEmail', senderEmail || '');
    setValue('senderName', senderName || '');
    setValue('reply', reply || '');
    setValue('replyName', replyName || '');
    setValue('contentTxt', contentTxt || '');
    setCode(formatHtml(contentHtmlFromDb));
  }, [RowsData]);

  const htmlToTxt = htmlToText(contentHtml);
  const { mutate: putMutate } = usePutNewsletterHistoryMutation();

  const [uppy, setUppy] = useState(() => new Uppy().use(XHRUpload, {
    endpoint: '/api/v1/newsletter_histories/file/upload/' + newsletterId,
  }));
  uppy.on('complete', (result) => {
    refetchFileData();
  })

  const onSubmit = ({
    title,
    senderEmail,
    senderName,
    reply,
    replyName,
    contentTxt,
  }: FormInputs) => {
    setOpenViewNewsletterModal(true);
    if (reply === '') {
      replyName = '';
    }
    setFormData({ title, contentHtml, contentTxt, senderEmail, senderName, reply, replyName });
    if (RowsData?.["hydra:member"][0].status == 0) {
      const handlePut = () => {
        putMutate(
          {
            newsletterHistoriesId: RowsData?.["hydra:member"][0].id ? RowsData?.["hydra:member"][0].id : null,
            payload: {
              title: title,
              senderEmail: senderEmail,
              senderName: senderName,
              reply: reply,
              replyName: replyName,
              contentHtml: contentHtml,
              contentTxt: contentTxt,
              status: 0,
              type: "/api/v1/newsletter_types/2",
              receiver: RowsData?.["hydra:member"][0].receiver ? RowsData?.["hydra:member"][0].receiver : "",
              user: "/api/v1/users/" + WhoAmI?.user_id,
            },
          },
          {
            onSuccess: (data) => {
              setNewsletterId(data.id);
            }
          }
        )
      }
      handlePut();
    }
  };
  const codemirrorTheme = theme.palette.mode === 'dark' ? 'dark' : 'light';

  const clearAll = () => {
    setValue("title", "");
    setValue("senderEmail", "");
    setValue("senderName", "");
    setValue("reply", "");
    setValue("replyName", "");
    setValue("contentHtml", "");
    setCode("");
    setValue("contentTxt", "");
  };

  const { mutate: deleteMutate } = useDeleteNewsletterHistory();
  const handleDelete = () => {
    clearAll();
    deleteMutate(
      {
        payload: {
          newsletterId: RowsData?.["hydra:member"][0].id,
        }
      },
      {
        onSuccess: () => {
          navigate('/newsletter-history-edit', {});  //reset the entire location.state data
          window.location.reload();
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  };

  const { data: FileData, isLoading: FileDataLoading, refetch: refetchFileData } = useGetNewsletterHistoriesFileQuery(
    {
      "id": newsletterId || location.state?.id,
    },
  );

  useEffect(() => {
    var newUppy = new Uppy().use(XHRUpload, {
      endpoint: '/api/v1/newsletter_histories/file/upload/' + newsletterId,
    });
    if (uppy !== newUppy) setUppy(newUppy);
  }, [newsletterId]);

  const { mutate: deleteFileMutate } = useDeleteNewsletterHistoryFile();
  const handleDeleteFile = (newsletterFileName: string) => {
    deleteFileMutate(
      {
        payload: {
          newsletterId: RowsData?.["hydra:member"][0].id,
          fileName: newsletterFileName,
        }
      },
      {
        onSuccess: () => {
          refetchFileData();
          setOpenError(false);
          setOpenSuccess(true);
        },
        onError: (error: any) => {
          setOpenSuccess(false);
          setOpenError(true);
          setMessageError(error.response?.data["hydra:description"]);
        },
      }
    );
  };

  const [openTwoStepsAcceptModal, setOpenTwoStepsAcceptModal] = useState(false);
  const [newsletterFileNameToDelete, setNewsletterFileNameToDelete] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [messageError, setMessageError] = useState();

  return (
    <Box>
      {RowsDataLoading ?
        <Loader /> :
        RowsData?.["hydra:member"][0]?.id ?
          (
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Card>
                  <Typography sx={{ ml: 5, mt: 5 }}>{t("Edit The Message Content")}</Typography>
                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      py: 5,
                      marginBottom: "30px",
                    }}
                  >
                    <FormControlCustom>
                      <Controller
                        name="title"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            label={t("Title")}
                            value={value}
                            variant="outlined"
                            onChange={onChange}
                            error={!!errors?.title}
                          />
                        )}
                      />
                      {errors.title && (
                        <FormHelperText sx={{ color: "error.main" }}>
                          {errors.title.message}
                        </FormHelperText>
                      )}
                    </FormControlCustom>
                    <FormControlCustom>
                      <EmailFieldValidate
                        name="senderEmail"
                        label={t("Sender Email")}
                        control={control}
                        editedValue
                        error={!!errors?.senderEmail}
                      />
                      {errors.senderEmail && (
                        <FormHelperText sx={{ color: "error.main" }}>
                          {errors.senderEmail.message}
                        </FormHelperText>
                      )}
                    </FormControlCustom>
                    <FormControlCustom>
                      <Controller
                        control={control}
                        name="senderName"
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            value={value}
                            onChange={onChange}
                            type="text"
                            label={`${t("Sender Name Optional")}`}
                          />
                        )}
                      />
                    </FormControlCustom>
                    <FormControlCustom>
                      <EmailFieldValidate
                        name="reply"
                        label={t("Reply To Email Optional")}
                        control={control}
                        editedValue
                        error={!!errors?.reply}
                      />
                      {errors.reply && (
                        <FormHelperText sx={{ color: "error.main" }}>
                          {errors.reply.message}
                        </FormHelperText>
                      )}
                    </FormControlCustom>
                    <FormControlCustom>
                      {watch("reply") && (
                        <Controller
                          name="replyName"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              label={t("Reply To Name Optional")}
                              value={value}
                              variant="outlined"
                              onChange={onChange}
                              error={!!errors?.replyName}
                            />
                          )}
                        />
                      )}
                      {errors.replyName && (
                        <FormHelperText sx={{ color: "error.main" }}>
                          {errors.replyName.message}
                        </FormHelperText>
                      )}
                    </FormControlCustom>
                  </Box>
                </Card>
                <Card sx={{ my: 5 }}>
                  <Typography sx={{ ml: 5, mt: 5, justifyContent: "center", display: "flex" }}>
                    {t("Content HTML")}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      py: 0,
                      marginBottom: "5px",
                    }}
                  >
                    <Divider />
                    <CodeMirror
                      value={contentHtml}
                      theme={codemirrorTheme}
                      height="400px"
                      width={onlySmallScreen ? "16rem" : onlyMediumScreen ? "30rem" : "49rem"}
                      extensions={[langs.html()]}
                      onChange={(value, viewUpdate) => {
                        setCode(value);
                      }}
                      style={{
                        height: 'auto',
                        overflow: 'auto',
                      }}
                    />
                    <FormControlCustom>
                      <Controller
                        control={control}
                        name="contentTxt"
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            value={value}
                            onChange={onChange}
                            type="text"
                            label={`${t("Content Txt")}`}
                            multiline
                            rows={15}
                            InputLabelProps={{ shrink: true }}
                            style={{ width: onlySmallScreen ? "16rem" : onlyMediumScreen ? "30rem" : "49rem", margin: "auto" }}
                          />
                        )}
                      />
                    </FormControlCustom>
                  </Box>
                  <CustomButton text={t("Generate Txt From Html")} style={{ mb: "30px", mx: "auto", display: "flex" }} type="button" onClickFunction={() => setValue('contentTxt', htmlToTxt)} />
                </Card>
                <Box
                  sx={{
                    mt: "30px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                </Box>
                {FileDataLoading}
                <Card sx={{ my: 5 }}>
                  <Typography sx={{ ml: 5, mt: 5, textDecoration: 'underline' }}>
                    {t("File For Newsletter")}
                  </Typography>
                  <Divider />
                  <div style={{ padding: '10px 0 0 20px' }}>
                    {FileDataLoading ?
                      <div>
                        <p><CircularProgress color="success" />{t("Loading Files Please Wait")}</p>
                      </div>
                      : <div>
                        {FileData?.newsletterFileNames?.length > 0 ? (
                          FileData?.newsletterFileNames.map((newsletterFileName: string, index: React.Key | null | undefined) => (
                            <div key={index} style={{ position: 'relative', marginBottom: '5px' }}>
                              <p>
                                <button
                                  onClick={() => {
                                    setOpenTwoStepsAcceptModal(true);
                                    setNewsletterFileNameToDelete(newsletterFileName)
                                  }}
                                  type="button"
                                >
                                  <DeleteOutline fontSize="small" />
                                </button>
                                {newsletterFileName}
                              </p>
                              <hr></hr>
                            </div>
                          ))
                        ) : (
                          <div>
                            <p>{t("There Are No Files For This Newsletter")}</p>
                            <br></br>
                          </div>
                        )}
                      </div>
                    }
                  </div>
                  <Dashboard
                    height={"300px"}
                    width={"100%"}
                    uppy={uppy}
                  />
                </Card>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  {RowsDataLoading}
                  <CustomButton text={t("Preview Of Message Content")} style={{ m: 2 }} type="submit" />
                  {(RowsData?.["hydra:member"].length === 0) && <CustomButton text={t("Clear")} style={{ m: 2 }} type="button" onClickFunction={clearAll} />}
                  {(RowsData?.["hydra:member"][0]?.status == 0) && <CustomButton text={t("Clear")} style={{ m: 2 }} type="button" onClickFunction={handleDelete} />}
                </Box>
              </form>
              {RowsDataLoading}
              <ViewNewsletterModal
                showNewsletterModal={openViewNewsletterModal}
                setShowNewsletterModal={setOpenViewNewsletterModal}
                formData={formData}
                newsletterId={newsletterId}
                refetch={refetch}
              />
              <TwoStepsAcceptModal
                message={t("Do You Want Delete File From Newsletter?")}
                executeButtonName={t("Continue")}
                executeFunction={() => handleDeleteFile(newsletterFileNameToDelete)}
                isOpen={openTwoStepsAcceptModal}
                setIsOpen={setOpenTwoStepsAcceptModal}
              />
              <ChangeParametersModal
                openSuccess={openSuccess}
                openError={openError}
                setOpenSuccess={setOpenSuccess}
                setOpenError={setOpenError}
                errorMessage={t("Message Error Delete File")}
                successMessage={t("Message Success Delete File")}
              />
            </div>
          ) : <NewsletterHistoryCreate />
      }
    </Box>
  );
}

export default NewsletterHistoryData;
