import React from "react";
import { Box, Button, Card, Dialog, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import EditChangeHistoryCode from "./EditChangeHistoryCode";
import { CrmsTypes } from "types/types";

interface EditChangeHistoryModalTypes {
  showChangeHistoryModal: boolean;
  setShowChangeHistoryModal: (val: boolean) => void;
  refetch: () => void;
  changeHistoryRowData: CrmsTypes | undefined;
}

function EditChangeHistoryModal({
  showChangeHistoryModal,
  setShowChangeHistoryModal,
  changeHistoryRowData,
  refetch,
}: EditChangeHistoryModalTypes) {
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Dialog
        open={showChangeHistoryModal}
        BackdropProps={{
          style: {
            backgroundColor: theme.palette.customColors.modalBgc,
          },
        }}
      >
        <Box
          className="array-modal"
          sx={{
            width: onlyMediumScreen ? "90vw" : "70vw",
            minHeight: 150,
            px: onlySmallScreen ? 5 : "unset",
          }}
        >
          <Card
            sx={{
              minHeight: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingRight: onlySmallScreen ? "unset" : "50px",
              flexWrap: "wrap",
              py: 5,
            }}
          >
            <EditChangeHistoryCode
              changeHistoryRowData={changeHistoryRowData}
              refetch={refetch}
            />
          </Card>
          <Button
            className="array-close-button"
            onClick={() => setShowChangeHistoryModal(false)}
          >
            <CloseIcon />
          </Button>
        </Box>
      </Dialog>
    </>
  );
}

export default EditChangeHistoryModal;
