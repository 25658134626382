import React from "react";
import { Box, Button, Card, Dialog, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import EditTransactionsCode from "./EditTransactionsCode";
import {TransactionsTypes} from "types/types";

interface EditTransactionsModalTypes {
  showTransactionsModal: boolean;
  setShowTransactionsModal: (val: boolean) => void;
  refetch: () => void;
  transactionsRowData: TransactionsTypes | undefined;
}

function EditTransactionsModal({
  showTransactionsModal,
  setShowTransactionsModal,
  transactionsRowData,
  refetch,
}: EditTransactionsModalTypes) {
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={showTransactionsModal}
      BackdropProps={{
        style: {
          backgroundColor: theme.palette.customColors.modalBgc,
        },
      }}
    >
      <Box
        className="array-modal"
        sx={{
          width: onlyMediumScreen ? "90vw" : "70vw",
          minHeight: 150,
          px: onlySmallScreen ? 5 : "unset",
        }}
      >
        <Card
          sx={{
            minHeight: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingRight: onlySmallScreen ? "unset" : "50px",
            flexWrap: "wrap",
            py: 5,
          }}
        >
          <EditTransactionsCode
            transactionsRowData={transactionsRowData}
            refetch={refetch}
          />
        </Card>
        <Button
          className="array-close-button"
          onClick={() => setShowTransactionsModal(false)}
        >
          <CloseIcon />
        </Button>
      </Box>
    </Dialog>
  );
}

export default EditTransactionsModal;
