import React, { useState } from "react";
import TableBasic from "components/TableBasic/TableBasic";
import { useTranslation } from "react-i18next";
import { GridColDef, GridRowId, GridSortModel } from "@mui/x-data-grid";
import dayjs from "dayjs";
import useGetAdsListQuery from "hooks/useGetAdsListQuery";
import { Box, Button, Checkbox, CardHeader } from "@mui/material";
import _, { find } from "lodash";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import { useSettings } from "hooks/useSettings";
import usePutAdMutation from "hooks/usePutAdMutation";

function AdsList() {
  const { t } = useTranslation();
  const { settings } = useSettings();
  const [sortOptions, setSortOptions] = useState<GridSortModel>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [adsListEnabled, setAdsListEnabled] = useState(true);
  const [isChecked, setIsChecked] = useState(true);
  const { mutate: putMutate } = usePutAdMutation();
  const handleChangeStatus = (id: number, newStatus: number) => {
    putMutate(
      {
        id: id,
        payload: {
          status: newStatus,
        },
      },
      {
        onSuccess: () => {
          refetch();
        },
        onError: (error: any) => { },
      }
    );
  };
  const [inputValues, setInputValues] = useState({
    status: 0,
  });
  const navigate = useNavigate();
  const queryValues = {
    page: pageNumber,
    itemsPerPage: itemsPerPage,
    "order[id]": find(sortOptions, { field: "id" })?.sort || "desc",
    status: inputValues.status,
    // status: inputValues,
  };
  const {
    data: AdsListData,
    isLoading: AdsListLoading,
    refetch,
  } = useGetAdsListQuery(
    {
      query: queryValues,
    },
    {
      enabled: adsListEnabled,
    }
  );

  const filterRows = (value: string, columnName: string) => {
    setInputValues((inputValues) => ({
      ...inputValues,
      [columnName]: value,
    }));
  };
  const handleChange = (
    e: { target: { value: string } },
    columnName: string
  ) => {
    filterRows(e.target.value, columnName);
  };

  const columns: GridColDef[] = [
    {
      flex: 0.2,
      minWidth: 40,
      maxWidth: 80,
      field: "id",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box>
          <div>{t("Id")}</div>
        </Box>
      ),
    },
    {
      flex: 0.2,
      minWidth: 200,
      field: "adTypeName",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box>
          <div>{t("Ad Type")}</div>
        </Box>
      ),
    },
    {
      flex: 0.2,
      minWidth: 200,
      field: "adInfo",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box>
          <div>{t("Short Ad Description")}</div>
        </Box>
      ),
    },
    {
      flex: 0.2,
      minWidth: 90,
      field: "width",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box>
          <div>{t("Width")}</div>
        </Box>
      ),
    },
    {
      flex: 0.2,
      minWidth: 90,
      field: "height",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box>
          <div>{t("Height")}</div>
        </Box>
      ),
    },
    {
      flex: 0.2,
      minWidth: 140,
      field: "beginDate",
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (params: {
        value: string | number | Date | dayjs.Dayjs | null | undefined;
      }) => dayjs(params.value).format("YYYY-MM-DD   HH:mm"),
      renderHeader: () => (
        <Box>
          <div>{t("Begin Date")}</div>
        </Box>
      ),
    },
    {
      flex: 0.2,
      minWidth: 140,
      field: "endDate",
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (params: {
        value: string | number | Date | dayjs.Dayjs | null | undefined;
      }) =>
        params.value
          ? dayjs(params.value).format("YYYY-MM-DD   HH:mm")
          : `${t("Indefinite")}`,
      renderHeader: () => (
        <Box>
          <div>{t("End Date")}</div>
        </Box>
      ),
    },
    {
      flex: 0.2,
      minWidth: 60,
      maxWidth: 70,
      field: "url",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box>
          <div>{t("Url")}</div>
        </Box>
      ),
      renderCell: (params) => (
        <Button
          style={{
            maxWidth: "30px",
            maxHeight: "30px",
            minWidth: "30px",
            minHeight: "30px",
          }}
          variant="contained"
          color="primary"
          onClick={() => {
            window.location.href = params.row.url;
          }}
        >
          <OpenInBrowserIcon />
        </Button>
      ),
    },
    {
      flex: 0.2,
      minWidth: 70,
      field: "view",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box>
          <div>{t("Views")}</div>
        </Box>
      ),
    },
    {
      flex: 0.2,
      minWidth: 70,
      field: "click",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box>
          <div>{t("Clicks")}</div>
        </Box>
      ),
    },
    {
      flex: 0.2,
      minWidth: 70,
      field: "ctr",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box>
          <div>{t("%Ctr")}</div>
        </Box>
      ),
    },
    {
      flex: 0.2,
      minWidth: 60,
      maxWidth: 70,
      field: "details",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box>
          <div>{t("Details")}</div>
        </Box>
      ),
      renderCell: (params) => (
        <Button
          style={{
            maxWidth: "30px",
            maxHeight: "30px",
            minWidth: "30px",
            minHeight: "30px",
          }}
          variant="contained"
          color="primary"
          onClick={() => {
            navigate("/ads-details", {
              state: {
                id: params.row.id,
              },
            });
          }}
        >
          <InfoIcon />
        </Button>
      ),
    },
    {
      flex: 0.2,
      minWidth: 60,
      maxWidth: 70,
      field: "edit",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box>
          <div>{t("Edit")}</div>
        </Box>
      ),
      renderCell: (params) => (
        <Button
          style={{
            maxWidth: "30px",
            maxHeight: "30px",
            minWidth: "30px",
            minHeight: "30px",
          }}
          variant="contained"
          color="primary"
          onClick={() => {
            navigate("/ads-edit", {
              state: {
                id: params.row.id,
              },
            });
          }}
        >
          <EditIcon />
        </Button>
      ),
    },
    {
      flex: 0.2,
      minWidth: 60,
      maxWidth: 60,
      field: "status",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box className="table-header-custom-styles">
          <div className="centered">
            {t("Akt")}
          </div>
          <div>
            <Checkbox
              checked={isChecked}
              onChange={(e) => {
                const newStatus = e.target.checked ? 0 : 1;
                setIsChecked(e.target.checked);
                handleChange(
                  { target: { value: newStatus.toString() } },
                  "status"
                );
              }}
            />
          </div>
        </Box>
      ),
      renderCell: (params) => (
        <Checkbox
          checked={params.value === 0}
          onChange={() => {
            const newStatus = params.value === 0 ? 1 : 0;
            handleChangeStatus(params.id, newStatus);
          }}
          inputProps={{ "aria-label": "controlled" }}
        />
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <style>
        {`
          .legend {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
          .yellow-row {
            background-color: yellow !important;
          }
          .orange-row {
            background-color: orange !important;
          }
        `}
      </style>
      <Card
        sx={{
          backgroundColor: settings.mode === "light" ? "inherit" : "#28243D",
        }}
      >
        <CardHeader title={t("Legend")} />
        <ul style={{ listStyleType: "none" }}>
          <li>
            <span className="legend orange-row"></span>
            {t("Clicks/Views Limit Exceeded")}
          </li>
          <li>
            <span className="legend yellow-row"></span>
            {t("The End Date Has Passed")}
          </li>
        </ul>
      </Card>

      <TableBasic
        paginationMode="server"
        columns={columns}
        initialSortingTable={"ads"}
        loading={AdsListLoading}
        rows={AdsListData?.["hydra:member"]}
        title={t("Available Ads")}
        setItemsPerPage={setItemsPerPage}
        totalItems={AdsListData?.["hydra:totalItems"]}
        setPageNumber={setPageNumber}
        queryFunction={setAdsListEnabled}
        getRowClassName={(row: {
          status: number;
          endDate: any;
          click: number;
          banerLimitClick: number;
          banerLimitView: number;
          view: number;
        }) => {
          if (
            (row.click > row.banerLimitClick ||
              row.view > row.banerLimitView) &&
            row.banerLimitClick > 0 &&
            row.banerLimitView > 0
          ) {
            return "orange-row";
          } else if (
            row.endDate < new Date().toISOString() &&
            (row.endDate != null || row.endDate != "0000-00-00T00:00:00Z")
          ) {
            return "yellow-row";
          } else {
            return "";
          }
        }}
      />
    </div>
  );
}

export default AdsList;
