import React, { useState, useEffect } from "react";
import { Box, useMediaQuery, FormControl, Typography, Autocomplete, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useTheme, styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import { Divider } from "@mui/material";
import { useSettings } from "hooks/useSettings";
import dayjs from "dayjs";
import { FormControlProps } from "@mui/material/FormControl";
import { Controller, useForm, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import CustomButton from "components/Buttons/CustomButton";
import { map, uniqBy } from "lodash";
import useGetAdsTypesQuery from "hooks/useGetAdsTypesQuery";
import Uppy from '@uppy/core';
import Uppy2 from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { Dashboard } from '@uppy/react';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import EventIcon from "@mui/icons-material/Event";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import usePostAdMutation from "hooks/usePostAdMutation";
import usePutAdMutation from "hooks/usePutAdMutation";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from "@mui/material/FormHelperText";
import { useNavigate } from "react-router-dom";
import { Loader } from "components/Loader/Loader";
import TwoStepsAcceptModal from "components/TwoStepsAcceptModal/TwoStepsAcceptModal";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import useGetAdsByIdQuery from "hooks/useGetAdByIdQuery";

export interface FormInputs {
  inputValue: any;
  value: any;
  information: string;
  firstDay: string | null;
  lastDay: string | null;
  numberOfViews: number;
  numberOfClicks: number;
  urlAddress: string;
  capping: number;
  email: string | null;
  adScript: string;
}
const FormControlCustom = styled(FormControl)<FormControlProps>(
  ({ theme }) => ({
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: 260,
      margin: "0.5rem 0rem",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: 500,
      margin: "0.5rem 1.25rem",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 800,
      margin: "0.5rem 1.25rem",
    },
  })
);

function AdsEdit() {
  const { t } = useTranslation();
  const location = useLocation();
  const { settings } = useSettings();
  const theme = useTheme();
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [
    openTwoStepsAcceptModalForDelete,
    setOpenTwoStepsAcceptModalForDelete,
  ] = useState(false);
  const [textError, setTextError] = useState("");
  const [textSuccess, setTextSuccess] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [messageError, setMessageError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { data: adsTypesData, isLoading: adsTypesLoading } =
    useGetAdsTypesQuery({});
  const { data: adData, isLoading: adDataLoading } =
    useGetAdsByIdQuery(
      {
        "id": location?.state?.id ? location?.state?.id : undefined
      },
      {
        enabled: location?.state?.id !== null
      }
    );
  const [checked, setChecked] = useState(
    adData?.endDate === null ? true : false
  );
  const [adType, setAdType] = useState(
    adData ? adData.adType : undefined
  );
  const [adFile, setAdFile] = useState(
    adData ? adData.fileName : ""
  );
  const [adFileDekstop, setAdFileDekstop] = useState(
    adData ? adData.fileNameDesktop : ""
  );
  const [year, setYear] = useState(new Date().getFullYear());
  const imgDimension = React.useRef({ width: 0, height: 0 });
  const [uppy, setUppy] = useState(() => new Uppy({
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: [
        "image/*",
        ".jpg",
        ".jpeg",
        ".png",
        ".gif",
        ".bmp",
        ".webp",
        ".swf",
      ],
    },
  }).use(XHRUpload, {
    endpoint: "/api/v1/ads/file/upload/" + year,
  }));
  uppy.on("complete", (result) => {
    result.successful.forEach((file) => {
      setAdFile(file.name);
    });
  });
  uppy.on("file-added", (file) => {
    const img = new Image();
    img.onload = () => {
      if (!adType) {
        uppy.info(`${t("No Ad Type Has Been Selected")}`, "error", 8000);
        uppy.removeFile(file.id);
      } else if (
        img.width === adType.typeWidth &&
        (img.height === adType.typeHeight ||
          adType.typeHeight === 0)
      ) {
      } else {
        uppy.info(
          `${t(
            "The Image Has Incorrect Dimensions. Acceptable Dimensions For This Ad Creation:"
          )} ${adType.typeWidth} x ${adType.typeHeight}. ${t(
            "The Given Image Has"
          )}: ${img.width} x ${img.height}`,
          "error",
          12000
        );
        uppy.removeFile(file.id);
      }
    };
    img.src = URL.createObjectURL(file.data);
  });
  const [uppy2, setUppy2] = useState(() => new Uppy({
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: [
        "image/*",
        ".jpg",
        ".jpeg",
        ".png",
        ".gif",
        ".bmp",
        ".webp",
        ".swf",
      ],
    },
  }).use(XHRUpload, {
    endpoint: "/api/v1/ads/file/upload/" + year,
  }));
  uppy2.on("complete", (result) => {
    result.successful.forEach((file) => {
      setAdFileDekstop(file.name);
    });
  });
  uppy2.on("file-added", (file) => {
    const img = new Image();
    img.onload = () => {
      if (!adType) {
        uppy2.info(`${t("No Ad Type Has Been Selected")}`, "error", 8000);
        uppy2.removeFile(file.id);
      } else if (
        (img.width === adType.typeWidth ||
          adType?.typeWidth && img.width === 2 * adType?.typeWidth) &&
        (img.height === adType.typeHeight ||
          adType.typeHeight === 0)
      ) {
        imgDimension.current = { width: img.width, height: img.height };
      } else {
        uppy2.info(
          `${t(
            "The Image Has Incorrect Dimensions. Acceptable Dimensions For This Ad Creation:"
          )} ${adType.typeWidth} x ${adType.typeHeight} ${t("Or")} ${adType?.typeWidth ? adType?.typeWidth * 2 : ""} x ${adType.typeHeight}.
          ${t("The Given Image Has")}: ${img.width} x ${img.height}`,
          "error",
          12000
        );
        uppy2.removeFile(file.id);
      }
    };
    img.src = URL.createObjectURL(file.data);
  });
  useEffect(() => {
    var newUppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: [
          "image/*",
          ".jpg",
          ".jpeg",
          ".png",
          ".gif",
          ".bmp",
          ".webp",
          ".swf",
        ],
      },
    }).use(XHRUpload, {
      endpoint: "/api/v1/ads/file/upload/" + year,
    });
    if (uppy !== newUppy) setUppy(newUppy);
    var newUppy2 = new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: [
          "image/*",
          ".jpg",
          ".jpeg",
          ".png",
          ".gif",
          ".bmp",
          ".webp",
          ".swf",
        ],
      },
    }).use(XHRUpload, {
      endpoint: "/api/v1/ads/file/upload/" + year,
    });
    if (uppy2 !== newUppy2) setUppy2(newUppy2);
  }, [year, adType]);
  useEffect(() => {
    reset({
      inputValue:
        (adData && {
          id: adData?.adType?.id,
          typeName: adData?.adType?.typeName,
          typeWidth: adData?.adType?.typeWidth,
          typeHeight: adData?.adType?.typeHeight,
        }) ||
        null,
      information: adData?.adInfo,
      firstDay: adData?.beginDate,
      lastDay: adData?.endDate,
      numberOfViews: adData?.banerLimitView,
      numberOfClicks: adData?.banerLimitClick,
      urlAddress: adData?.url,
      capping: adData?.capping,
      email: adData?.email,
      adScript: adData?.adScript,
    });
    setAdFile(adData ? adData.fileName : "");
    setAdFileDekstop(adData ? adData.fileNameDesktop : "");
    if (!adType && adData) {
      setAdType(adData.adType);
    }
  }, [adData]);

  let url = window.location.href;
  let segments = url.split("/");
  segments.pop();
  let newUrl = segments.join("/");

  const schema = yup.object().shape({
    information: yup.string().required(t("This Field is Required") as string).max(255, t('Maximum 255 Characters') as string),
    firstDay: yup.date().nullable().required(t('This Field is Required') as string).typeError(t('Invalid Date') as string),
    lastDay: yup.mixed().when('checked', {
      is: false,
      then: yup.date().nullable().required(t('This Field is Required') as string).typeError(t('Invalid Date') as string)
        .min(yup.ref('firstDay'), t('Last Day Cannot Be Before First Day') as string),
      otherwise: yup.date().nullable().typeError(t('Invalid Date') as string).min(yup.ref('firstDay'), t('Last Day Cannot Be Before First Day') as string)
    }).typeError(t('Invalid Date') as string),
    inputValue: yup
      .object()
      .nullable()
      .required(t("This Field is Required") as string),
    numberOfViews: yup
      .number()
      .required(t("This Field is Required") as string)
      .positive(t('Enter Positive Number') as string)
      .typeError(t('Invalid Number') as string)
      .max(1000000, t('Number Must Be Lesser Than 1000000') as string),
    numberOfClicks: yup
      .number()
      .required(t("This Field is Required") as string)
      .positive(t('Enter Positive Number') as string)
      .typeError(t('Invalid Number') as string)
      .max(1000000, t('Number Must Be Lesser Than 1000000') as string),
    urlAddress: yup.string().required(t("This Field is Required") as string).max(255, t('Maximum 255 Characters') as string),
    capping: yup
      .number()
      .required(t("This Field is Required") as string)
      .positive(t('Enter Positive Number') as string)
      .typeError(t('Invalid Number') as string)
      .max(255, t('Number Must Be Lesser Than 256') as string),
    email: yup.string()
      .email(t('Invalid Email') as string)
      .required(t('This Field is Required') as string)
      .typeError(t('Invalid Email') as string)
      .max(255, t('Maximum 255 Characters') as string)
  });

  const {
    control: control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      inputValue:
        (adData && {
          id: adData?.adType?.id,
          typeName: adData?.adType?.typeName,
          typeWidth: adData?.adType?.typeWidth,
          typeHeight: adData?.adType?.typeHeight,
        }) ||
        null,
      information: adData?.adInfo || '',
      firstDay: adData?.beginDate,
      lastDay: adData?.endDate,
      numberOfViews: adData?.banerLimitView || 0,
      numberOfClicks: adData?.banerLimitClick || 0,
      urlAddress: adData?.url || '',
      capping: adData?.capping || 0,
      email: adData?.email || '',
      adScript: adData?.adScript || '',
    },
  });
  const { mutate: postMutate } = usePostAdMutation();
  const { mutate: putMutate } = usePutAdMutation();
  const handleDelete = () => {
    setIsLoading(true);
    putMutate(
      {
        id: adData?.id || 0,
        payload: {
          status: 2,
        },
      },
      {
        onSuccess: () => {
          setIsLoading(false);
          setOpenError(false);
          setOpenSuccess(true);
          setTextSuccess(`${t("Message Success Delete Ad")}`);
        },
        onError: (error: any) => {
          setIsLoading(false);
          setOpenSuccess(false);
          setOpenError(true);
          setMessageError(error.response?.data["hydra:description"]);
          setTextError(`${t("Message Error Delete Ad")}`);
        },
      }
    );
  };
  const onSubmit = ({
    inputValue,
    information,
    firstDay,
    lastDay,
    numberOfViews,
    numberOfClicks,
    urlAddress,
    capping,
    email,
    adScript,
  }: FormInputs) => {
    if (checked === true) {
      lastDay = null;
    }
    const handlePost = () => {
      setIsLoading(true);
      postMutate(
        {
          payload: {
            adType: '/api/v1/ad_types/' + adType?.id,
            adInfo: information,
            beginDate: firstDay ? new Date(firstDay).toLocaleString() : null,
            endDate: lastDay ? new Date(lastDay).toLocaleString() : null,
            banerLimitView: numberOfViews,
            banerLimitClick: numberOfClicks,
            url: urlAddress,
            capping: capping,
            email: email,
            fileName: adFile,
            fileNameDesktop: adFileDekstop,
            adScript: adScript,
            width: adFileDekstop ? imgDimension.current.width : undefined,
            height: adFileDekstop ? imgDimension.current.height : undefined,
          },
        },
        {
          onSuccess: () => {
            setIsLoading(false);
            setOpenError(false);
            setOpenSuccess(true);
            setTextSuccess(`${t("Message Success Create Ad")}`);
          },
          onError: (error: any) => {
            setIsLoading(false);
            setOpenSuccess(false);
            setOpenError(true);
            setMessageError(error.response?.data["hydra:description"]);
            setTextError(`${t("Message Error Create Ad")}`);
          },
        }
      );
    };
    const handleEdit = () => {
      setIsLoading(true);
      putMutate(
        {
          id: adData?.id || 0,
          payload: {
            adType: '/api/v1/ad_types/' + adType?.id,
            adInfo: information,
            beginDate: firstDay ? new Date(firstDay).toLocaleString() : null,
            endDate: lastDay ? new Date(lastDay).toLocaleString() : null,
            banerLimitView: numberOfViews,
            banerLimitClick: numberOfClicks,
            url: urlAddress,
            capping: capping,
            email: email,
            fileName: adFile,
            fileNameDesktop: adFileDekstop,
            adScript: adScript,
            width: adFileDekstop ? imgDimension.current.width : undefined,
            height: adFileDekstop ? imgDimension.current.height : undefined,
          },
        },
        {
          onSuccess: () => {
            setIsLoading(false);
            setOpenError(false);
            setOpenSuccess(true);
            setTextSuccess(`${t("Message Success Edit Ad")}`);
          },
          onError: (error: any) => {
            setIsLoading(false);
            setOpenSuccess(false);
            setOpenError(true);
            setMessageError(error.response?.data["hydra:description"]);
            setTextError(`${t("Message Error Edit Ad")}`);
          },
        }
      );
    };
    if (location.state) {
      handleEdit();
    } else {
      handlePost();
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: settings.mode === "light" ? "inherit" : "#28243D",
      }}
    >
      {location.state ? (
        <CardHeader title={t("Ad Editing")} />
      ) : (
        <CardHeader title={t("New Ad")} />
      )}
      <Divider />
      <Grid
        container
        spacing={6}
        item
        sx={{
          m: onlySmallScreen ? 0 : 4,
          justifyContent: "center",
          width: onlySmallScreen ? "100%" : "unset",
          pl: onlyMediumScreen ? 0 : "unset",
        }}
      >
        {(adsTypesLoading || adDataLoading) && <Loader />}
        <Box
          sx={{
            maxWidth: 800,
            display: "flex",
            flexDirection: "row",
            justifyContent: onlySmallScreen ? "center" : "unset",

          }}
        >
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <CardHeader title={t("Editing Ad Content")} />
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    py: 5,
                    marginBottom: "30px",
                  }}
                >
                  {adsTypesLoading}

                  <FormControlCustom>
                    <Controller
                      name="inputValue"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          noOptionsText={t("None")}
                          onChange={(_, value) => {
                            field.onChange(value);
                            setAdType(value);
                          }}
                          options={
                            map(
                              uniqBy(
                                adsTypesData?.["hydra:member"],
                                "typeName"
                              ),
                              (adsTypesData: any) => adsTypesData
                            ) || []
                          }
                          getOptionLabel={(option) =>
                            `${option.typeName} (${option.typeWidth}x${option.typeHeight})`
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("Ad Type")}
                              variant="outlined"
                              error={!!errors.inputValue}
                            />
                          )}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                        />
                      )}
                    />
                    {errors.inputValue && (
                      <FormHelperText
                        sx={{ color: "error.main", paddingLeft: "5px" }}
                      >
                        {errors.inputValue?.message?.toString()}
                      </FormHelperText>
                    )}
                  </FormControlCustom>
                  <FormControlCustom>
                    <Controller
                      control={control}
                      name="information"
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          value={value}
                          onChange={onChange}
                          type="text"
                          label={`${t("Information About Ad")}`}
                          error={!!errors.information}
                        />
                      )}
                    />
                    {errors.information && (
                      <FormHelperText sx={{ color: "error.main" }}>
                        {errors.information.message}
                      </FormHelperText>
                    )}
                  </FormControlCustom>
                  <FormControl
                    sx={{
                      width: "100%",
                      maxWidth: onlySmallScreen ? 260 : "100%",
                      my: 2,
                      mx: onlySmallScreen ? 5 : 3,
                      alignItems: onlyMediumScreen ? "center" : "flex-start",
                    }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={t("Language Code") as string}
                    >
                      <Controller
                        control={control}
                        name="firstDay"
                        defaultValue={dayjs().format("YYYY-MM-DD HH:mm")}
                        render={({ field: { value, onChange } }) => (
                          <DateTimePicker
                            inputFormat="YYYY-MM-DD HH:mm"
                            label={t("First Day Of Emission")}
                            value={value}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <EventIcon />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(newValue) => {
                              onChange(newValue);
                              setYear(dayjs(newValue).year());
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="YYYY-MM-DD HH:mm"
                                sx={{
                                  width: onlySmallScreen ? "100%" : 260,
                                  mx: 2,
                                }}
                                FormHelperTextProps={{
                                  style: {
                                    position: "absolute",
                                    bottom: "-19px",
                                  },
                                }}
                                error={!!errors.firstDay}
                              />
                            )}
                          />
                        )}
                      />
                      {errors.firstDay && (
                        <FormHelperText sx={{ color: "error.main" }}>
                          {errors.firstDay.message}
                        </FormHelperText>
                      )}
                    </LocalizationProvider>
                  </FormControl>

                  <FormControl
                    sx={{
                      width: "100%",
                      maxWidth: onlySmallScreen ? 260 : "100%",
                      my: 2,
                      mx: onlySmallScreen ? 5 : 3,
                      alignItems: onlyMediumScreen ? "center" : "flex-start",
                    }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={t("Language Code") as string}
                    >
                      <Controller
                        control={control}
                        name="lastDay"
                        defaultValue={dayjs().format("YYYY-MM-DD HH:mm")}
                        render={({ field: { value, onChange } }) => (
                          <DateTimePicker
                            inputFormat="YYYY-MM-DD HH:mm"
                            label={t("Last Day Of Emission")}
                            value={checked ? null : value}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <EventIcon />
                                </InputAdornment>
                              ),
                            }}
                            onChange={onChange}
                            disabled={checked}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="YYYY-MM-DD HH:mm"
                                sx={{
                                  width: onlySmallScreen ? "100%" : 260,
                                  mx: 2,
                                }}
                                FormHelperTextProps={{
                                  style: {
                                    position: "absolute",
                                    bottom: "-19px",
                                  },
                                }}
                                error={!!errors.lastDay}
                              />
                            )}
                          />
                        )}
                      />
                      {errors.lastDay && (
                        <FormHelperText sx={{ color: "error.main" }}>
                          {errors.lastDay.message}
                        </FormHelperText>
                      )}
                    </LocalizationProvider>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={(event) => setChecked(event.target.checked)}
                        />
                      }
                      label={`${t("Indefinite Ad")}`}
                      sx={{ marginLeft: onlySmallScreen ? "0px" : "10px" }}
                    />
                  </FormControl>

                  <FormControlCustom
                    sx={{
                      width: "100%",
                      maxWidth: onlySmallScreen ? 260 : "100%",
                    }}
                  >
                    <Controller
                      control={control}
                      name="numberOfViews"
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          value={value}
                          onChange={onChange}
                          type="number"
                          label={`${t("Limit Number Of Views")}`}
                          error={!!errors.numberOfViews}
                        />
                      )}
                    />
                    {errors.numberOfViews && (
                      <FormHelperText sx={{ color: "error.main" }}>
                        {errors.numberOfViews.message}
                      </FormHelperText>
                    )}
                  </FormControlCustom>
                  <FormControlCustom
                    sx={{
                      width: "100%",
                      maxWidth: onlySmallScreen ? 260 : "100%",
                    }}
                  >
                    <Controller
                      control={control}
                      name="numberOfClicks"
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          value={value}
                          onChange={onChange}
                          type="number"
                          label={`${t("Limit Number Of Clicks")}`}
                          error={!!errors.numberOfClicks}
                        />
                      )}
                    />
                    {errors.numberOfClicks && (
                      <FormHelperText sx={{ color: "error.main" }}>
                        {errors.numberOfClicks.message}
                      </FormHelperText>
                    )}
                  </FormControlCustom>
                  <FormControlCustom>
                    <Controller
                      control={control}
                      name="urlAddress"
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          value={value}
                          onChange={onChange}
                          type="text"
                          label={`${t("Url Address")}`}
                          error={!!errors.urlAddress}
                        />
                      )}
                    />
                    {errors.urlAddress && (
                      <FormHelperText sx={{ color: "error.main" }}>
                        {errors.urlAddress.message}
                      </FormHelperText>
                    )}
                  </FormControlCustom>
                  <FormControlCustom>
                    <Controller
                      control={control}
                      name="capping"
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          value={value}
                          onChange={onChange}
                          type="number"
                          label={`${t("Capping")}`}
                          error={!!errors.capping}
                        />
                      )}
                    />
                    {errors.capping && (
                      <FormHelperText sx={{ color: "error.main" }}>
                        {errors.capping.message}
                      </FormHelperText>
                    )}
                  </FormControlCustom>
                  <FormControlCustom>
                    <Controller
                      control={control}
                      name="email"
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          value={value}
                          onChange={onChange}
                          type="text"
                          label={`${t("Ad Owner Email")}`}
                          error={!!errors.email}
                        />
                      )}
                    />
                    {errors.email && (
                      <FormHelperText sx={{ color: "error.main" }}>
                        {errors.email.message}
                      </FormHelperText>
                    )}
                  </FormControlCustom>
                  <FormControlCustom>
                    <Controller
                      control={control}
                      name="adScript"
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          value={value}
                          onChange={onChange}
                          type="text"
                          label={`${t("adScript Code")}`}
                        />
                      )}
                    />
                  </FormControlCustom>

                  <Grid
                    container
                    spacing={0}
                    sx={{ marginLeft: "20px", marginRight: "20px" }}
                  >
                    <Grid item xs={12} sm={6} sx={{ paddingRight: onlySmallScreen ? "0px" : "10px" }}>
                      <p>{t("File With Animated Graphic")}</p>
                      <Dashboard height={"190px"} width={"100%"} uppy={uppy} />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ paddingLeft: onlySmallScreen ? "0px" : "10px" }}>
                      <p>{t("File With Static Graphic")}</p>
                      <Dashboard height={"190px"} width={"100%"} uppy={uppy2} />
                    </Grid>
                  </Grid>

                  {(adFile != "" || adFileDekstop != "") && (
                    <>
                      <Grid container spacing={0} sx={{ marginLeft: "20px", marginRight: "20px" }}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                          }}
                        >
                          <Divider />
                          <CardHeader title={t("File preview")} />
                          <Divider />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                          sx={{ paddingRight: onlySmallScreen ? "0px" : "10px" }}
                        >
                          <p>
                            {t("Static Graphic")} {adFile}
                          </p>
                          <img
                            src={newUrl + "/img/Ads/" + year + "/" + adFile}
                            alt={`${t("There Is No File With Static Graphic")}`}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                          sx={{ paddingLeft: onlySmallScreen ? "0px" : "10px" }}
                        >
                          <p>
                            {t("Animated Graphic")} {adFileDekstop}
                          </p>
                          <img
                            src={
                              newUrl + "/img/Ads/" + year + "/" + adFileDekstop
                            }
                            alt={`${t(
                              "There Is No File With Animated Graphic"
                            )}`}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Box>
              </Card>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row-reverse",
                  m: onlySmallScreen ? 0 : 4,
                }}
              >
                {location.state && (
                  <div>
                    <CustomButton
                      text={t("Show Detailed Report")}
                      style={{ m: 5, height: "60px" }}
                      onClickFunction={() => {
                        navigate("/ads-details", {
                          state: {
                            id: adData?.id,
                          },
                        });
                      }}
                    />
                    <CustomButton
                      text={t("Save Changes")}
                      style={{ m: 5, height: "60px" }}
                      type="submit"
                    />
                    <CustomButton
                      text={t("Delete")}
                      style={{ m: 5, height: "60px" }}
                      onClickFunction={() =>
                        setOpenTwoStepsAcceptModalForDelete(true)
                      }
                    />
                    <CustomButton
                      text={t("Back To Ads List")}
                      style={{ m: 5, height: "60px" }}
                      onClickFunction={() => {
                        navigate("/ads-list");
                      }}
                    />
                  </div>
                )}
                {!location.state && (
                  <CustomButton
                    text={t("You Save")}
                    style={{ m: 5, height: "60px" }}
                    type="submit"
                  />
                )}
              </Box>
              {isLoading ? <Loader /> : null}
            </form>
          </Box>

          <TwoStepsAcceptModal
            message={t("Do You Want Delete Ad")}
            executeButtonName={t("Continue")}
            executeFunction={() => handleDelete()}
            isOpen={openTwoStepsAcceptModalForDelete}
            setIsOpen={setOpenTwoStepsAcceptModalForDelete}
          />
          <ChangeParametersModal
            openSuccess={openSuccess}
            openError={openError}
            setOpenSuccess={setOpenSuccess}
            setOpenError={setOpenError}
            errorMessage={textError}
            successMessage={textSuccess}
          />
        </Box>
      </Grid>
    </Card>
  );
}

export default AdsEdit;
