import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { map, orderBy, reverse, takeRight } from "lodash";
import { Tooltip } from "@mui/material";
import { TransactionsResponseTypes } from "types/types";
import { formattedDate, formatValueDateOther } from "helpers/Global";

type Props = {
  userTransactions?: TransactionsResponseTypes;
  refetch: () => void;
};

function Transactions({ userTransactions, refetch }: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 150 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("Date")}</TableCell>
              <TableCell>{t("Value")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reverse(
              map(
                takeRight(
                  orderBy(
                    userTransactions?.["hydra:member"],
                    "verificationDate"
                  ),
                  3
                ),
                (row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-of-type td, &:last-of-type th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {formattedDate(
                        row.verificationDate,
                        formatValueDateOther
                      )}
                    </TableCell>
                    {row.status === 1 && (
                      <Tooltip
                        placement="left"
                        title={
                          row.description ? `${row.description}` : t("None")
                        }
                      >
                        <TableCell
                          sx={{
                            cursor: "pointer",
                          }}
                          component="th"
                          scope="row"
                        >
                          {row.amountInBaseUnit} zł
                        </TableCell>
                      </Tooltip>
                    )}
                    {row.status === 3 && (
                      <Tooltip
                        placement="left"
                        title={t("Returned Transaction")}
                        sx={{ marginTop: "-10px" }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            textDecoration: "line-through",
                            cursor: "pointer",
                          }}
                        >
                          {row.amountInBaseUnit} zł
                        </TableCell>
                      </Tooltip>
                    )}
                  </TableRow>
                )
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Transactions;
