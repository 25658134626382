import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { operations } from "schema/schema";

const path = "api/v1/p24_transactions_csv";
const queryKey = "transactionsListCSV";

type Response =
  operations["p24TransactionCSV"]["responses"]["200"]["content"]["application/ld+json"];
type Parameter = operations["p24TransactionCSV"]["parameters"];

const getTransactionsListCsvQuery = async (parameters: Parameter) => {
  const { data } = await getApiClient().get(path, {
    params: parameters?.query,
  });
  return data;
};

export default function useGetTransactionsListCsvQuery(
  parameters: Parameter,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey, parameters],
    () => getTransactionsListCsvQuery(parameters),
    options
  );
}
