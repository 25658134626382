import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";

type Response = string;
type Id = {
  id: string;
};

const getPaymentNameQuery = async (id: Id) => {
  const { data } = await getApiClient().get(id.id);
  return data.name;
};

export default function useGetPaymentNameQuery(
  id: Id,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [id],
    () => getPaymentNameQuery(id),
    options
  );
}
