import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { API_PREFIX, getApiClient } from "helpers/Global";
import { components } from "schema/schema";

const pathContribution = `${API_PREFIX}/contributions/`;

type Payload = components["schemas"]["Contribution.jsonld"];
type Response = {
    contributionData: components["schemas"]["Contribution.jsonld"];
    userData: User;
};
type ContributionCodeId = components["schemas"]["Contribution.jsonld"]["id"]
type Params = {
  payload: Payload;
  ContributionCodeId: ContributionCodeId;
};
type User = {
    id: number;
};

const putContributionCode = async ({ ContributionCodeId, payload }: Params) => {
    const { data } = await getApiClient().put(`${pathContribution}${ContributionCodeId}`, payload);
    return data;
};

export default function usePutContributionCodeMutation(
  options: UseMutationOptions<Response, AxiosError, Params> = {}
) {
  return useMutation<Response, AxiosError, Params>(putContributionCode, options);
}
