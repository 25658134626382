import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { operations } from "schema/schema";

const path = "api/v1/users/resignation_from_membership";
const queryKey = "resignationFromMembership";

type Response =
  operations["resignationFromMembership"]["responses"]["200"]["content"]["application/ld+json"];
type Parameter = operations["resignationFromMembership"]["parameters"];

const getMembershipResignationReportQuery = async (parameters: Parameter) => {
  const { data } = await getApiClient().get(path, {
    params: parameters?.query,
  });
  return data;
};

export default function useGetMembershipResignationReportQuery(
  parameters: Parameter,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey, parameters],
    () => getMembershipResignationReportQuery(parameters),
    options
  );
}
