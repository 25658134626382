import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { API_PREFIX, getApiClient } from "helpers/Global";
import { components } from "schema/schema";

const path = `${API_PREFIX}/ads/`;

type Payload = components["schemas"]["Ad.jsonld"];
type Response = components["schemas"]["Ad.jsonld"];

type Params = {
  payload: Payload;
  id: number
};

const putAd = async ({ payload, id }: Params) => {
  const { data } = await getApiClient().put(`${path}${id}`, payload);
  return data;
};

export default function usePutAdMutation(
  options: UseMutationOptions<Response, AxiosError, Params> = {}
) {
  return useMutation<Response, AxiosError, Params>(putAd, options);
}