import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { components } from "schema/schema";

const path = "/api/v1/contributions";
type ContributionId = components["schemas"]["Contribution.jsonld"]["id"];

const putDeleteContribution = async (contributionId: ContributionId) => {

  const { data } = await getApiClient().delete(`${path}/${contributionId}`);
  
  return data;
};

export default function useDeleteContributionMutation(
  options: UseMutationOptions<void, AxiosError, ContributionId> = {}
) {
  return useMutation<void, AxiosError, ContributionId>(putDeleteContribution, options);
}
