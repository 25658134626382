import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { API_PREFIX, getApiClient } from "helpers/Global";
import { components } from "schema/schema";

const pathCrm = `${API_PREFIX}/crms/`;

type Payload = components["schemas"]["Crm.jsonld"];
type Response = {
    CrmData: components["schemas"]["Crm.jsonld"];
    userData: User;
};
type CrmCodeId = components["schemas"]["Crm.jsonld"]["id"]
type Params = {
  payload: Payload;
  CrmCodeId: CrmCodeId;
};
type User = {
    id: number;
};

const putCrmCode = async ({ CrmCodeId, payload }: Params) => {
  const { data } = await getApiClient().put(`${pathCrm}${CrmCodeId}`, payload);
  return data;
};

export default function usePutChangeHistoryCodeMutation(
  options: UseMutationOptions<Response, AxiosError, Params> = {}
) {
  return useMutation<Response, AxiosError, Params>(putCrmCode, options);
}
