import { useQuery, UseQueryOptions } from "react-query";
import { API_PREFIX, getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { operations } from "schema/schema";

const path = `${API_PREFIX}/ad_types`;
const queryKey = "adsType";

type Response = operations["api_apiv1ad_types_get_collection"]["responses"]["200"]["content"]["application/ld+json"];
type Parameter = operations["api_apiv1ad_types_get_collection"]["parameters"];

const getFilterAdsTypes = async (parameters: Parameter) => {
  const { data } = await getApiClient().get(path, {
    params: parameters?.query,
  });
  return data;
};

export default function useGetAdsTypesQuery(
  parameters: Parameter,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey, parameters],
    () => getFilterAdsTypes(parameters),
    options
  );
}
