import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { map } from "lodash";
import useVerificationStatementQuery from "hooks/useVerificationStatementQuery";
import CardHeader from "@mui/material/CardHeader";
import { Card, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Loader } from "components/Loader/Loader";
import VerificationStatementTable from "components/PageVerificationStatement/VerificatrionStatementTable";
import useGetYearsVerificationQuery from "hooks/useGetYearsVerificationQuery";

function VerificationStatement() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const { data: yearsData } = useGetYearsVerificationQuery({});
  const [yearsValues, setYearsValues] = useState<number[] | undefined>([
    currentYear,
  ]);
  const {
    data: verificationStatementData,
    isLoading: verificationStatementLoading,
  } = useVerificationStatementQuery({
    query: {
      "dateVerification[]": yearsValues,
    },
  });
  const unselectByValue = (year: number) => setYearsValues(oldValues => oldValues?.filter(value => year !== value));
  const selectOrUnselectByValue = (year: number) => {
    if (!yearsValues?.some(item => year === item.valueOf()) && yearsValues) {
      setYearsValues([...yearsValues, year])
    }
    else {
      unselectByValue(year);
    }
  };

  return (
    <Card>
      {verificationStatementLoading && <Loader />}
      <CardHeader title={t("Verification Statement")} />
      <FormControl sx={{ ml: 4 }}>
        <InputLabel id="verification-statement-select-label">
          {t("Years Displayed")}
        </InputLabel>
        <Select
          multiple
          label={t("Years Displayed")}
          value={yearsValues ?? []}
          sx={{ width: 250 }}
          renderValue={(selected) => selected.join(", ")}
          id="verification-statement-multiple-selectbox"
          labelId="verification-statement-select-label"
        >
          <MenuItem onClick={() => setYearsValues(yearsData?.years as unknown as number[])}>
            <b>{t("All")}</b>
          </MenuItem>
          {yearsData?.years &&
            yearsData?.years.map((year: number, index: number) => (
              <MenuItem
                key={index}
                value={year}
                onClick={() => selectOrUnselectByValue(year)}
              >
                {year}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {
        map(verificationStatementData?.verify, (data, index) => (
          <VerificationStatementTable data={data} index={index} />
        ))
      }
    </Card >
  );
}

export default VerificationStatement;
