import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { API_PREFIX, getApiClient } from "helpers/Global";
import { components } from "schema/schema";

const path = `${API_PREFIX}/newsletter_histories/`;
type Payload = components["schemas"]["NewsletterHistory.jsonld"];
type Response = components["schemas"]["NewsletterHistory.jsonld"];

type Params = {
  payload: Payload;
  newsletterHistoriesId: number|null;
};

const putAddNewsletterHistory = async ({ newsletterHistoriesId, payload }: Params) => {

  const { data } = await getApiClient().put(`${path}${newsletterHistoriesId}`, payload);
  return data;
};

export default function usePuttNewsletterHistoryMutation(
  options: UseMutationOptions<Response, AxiosError, Params> = {}
) {
  return useMutation<Response, AxiosError, Params>(putAddNewsletterHistory, options);
}
