import React, { useState } from "react";
import TableBasic from "components/TableBasic/TableBasic";
import { useTranslation } from "react-i18next";
import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import useGetAdsTypesQuery from "hooks/useGetAdsTypesQuery";
import { Box } from "@mui/material";

function AdsTypes() {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [adsTypeEnabled, setAdsTypeEnabled] = useState(true);
  const query = {
    page: pageNumber,
    itemsPerPage: itemsPerPage,
  }

  const { data: AdsTypesData, isLoading: AdsTypesLoading } = 
    useGetAdsTypesQuery(
        {
            query: query,
        },
        {
            enabled: adsTypeEnabled,
        }
    )

  const columns: GridColDef[] = [
    {
      flex: 0.2,
      minWidth: 150,
      field: "typeName",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box>
          <div>
              {t("Ads Type Name")}
          </div>
        </Box>
      ),
    },
    {
      flex: 0.2,
      minWidth: 100,
      field: "typeWidth",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box>
          <div>
              {t("Width")}
          </div>
        </Box>
      ),
    },
    {
      flex: 0.2,
      minWidth: 100,
      field: "typeHeight",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box>
          <div>
              {t("Height")}
          </div>
        </Box>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <TableBasic
        paginationMode="server"
        columns={columns}
        initialSortingTable={"adsTypes"}
        loading={AdsTypesLoading}
        rows={AdsTypesData?.["hydra:member"]}
        title={t("Ads Types")}
        setItemsPerPage={setItemsPerPage}
        totalItems={AdsTypesData?.["hydra:totalItems"]}
        setPageNumber={setPageNumber}
        queryFunction={setAdsTypeEnabled}
        customPageSize={itemsPerPage}
      />
    </div>
  );
}

export default AdsTypes;
