import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Autocomplete,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { Loader } from "components/Loader/Loader";
import usePutBalanceCodeMutation from "hooks/usePutBalanceMutation";
import CustomButton from "components/Buttons/CustomButton";
import usePaymentTypesQuery from "hooks/usePaymentTypesQuery";
import { map, uniqBy } from "lodash";
import { BalancesTypes } from "types/types";

export const useStyles = makeStyles(() => ({
  textFieldCustom: {
    margin: " 15px 25px",
  },
}));
interface FormInputs {
  paymentType: string | null;
  points: number;
}
interface EditBalanceCodeTypes {
  balanceRowData: BalancesTypes | undefined;
  refetch: () => void;
}

function EditBalanceCode({ refetch, balanceRowData }: EditBalanceCodeTypes) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [messageError, setMessageError] = useState();
  const { mutate: putBalanceMutation, isLoading: putBalanceCodeLoading } = usePutBalanceCodeMutation();
  const [inputValue, setInputValue] = useState(balanceRowData?.paymentTitle);
  const { data: paymentTypesData } = usePaymentTypesQuery(
    {
      query: {
        name: inputValue != null ? inputValue : undefined,
      },
    },
    {
      enabled: inputValue ? inputValue?.length > 1 : false,
    }
  );
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
        paymentType: balanceRowData?.paymentTitle,
        points: balanceRowData?.points,
    },
  });
  const onSubmit = ({
    points,
  }: FormInputs) => {
    putBalanceMutation(
      {
        payload: {
            points: points,
            paymentType: '/api/v1/payment_types/' + paymentTypesData?.["hydra:member"]?.find(
              (temp) => temp.name === inputValue
            )?.id,
        },
        balanceId: balanceRowData?.id,
      },
      {
        onSuccess: () => {
          setOpenError(false);
          setOpenSuccess(true);
          refetch();
        },
        onError: (error: any) => {
          setOpenSuccess(false);
          setOpenError(true);
          setMessageError(error.response?.data["hydra:description"]);
        },
      }
    );
  };
  useEffect(() => {
    reset({
      paymentType: balanceRowData?.paymentTitle
    });
  }, [balanceRowData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {putBalanceCodeLoading && <Loader />}
      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={t("Language Code") as string}
        >
          <FormControl>
            <Autocomplete
              noOptionsText={t("None")}
              sx={{ width: 450, my: 2, mx: 5 }}
              onInputChange={(_, value) => setInputValue(value)}
              options={
                map(
                  uniqBy(paymentTypesData?.["hydra:member"], "name"),
                  (paymentType: any) => paymentType.name
                ) || []
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Paid Title / Name Of Withdrawal")}
                  variant="outlined"
                />
              )}
              value={inputValue}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
            />
          </FormControl>
          <FormControl className={classes.textFieldCustom}>
          <Controller
            control={control}
            name="points"
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                onChange={(e) => {
                    onChange(parseInt(e.target.value, 10) || 0);
                  }}
                variant="standard"
                label={t("Points")}
                InputLabelProps={{ shrink: true }}
                type="number"
              />
            )}
          />
        </FormControl>
        </LocalizationProvider>
      </Box>
      <CustomButton
        text={t("You Save")}
        type="submit"
        style={{ mx: 5, mb: 5, mt: 5 }}
      />
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Edit Balance")}
        successMessage={t("Message Success Edit Balance")}
      />
    </form>
  );
}

export default EditBalanceCode;
