import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { API_PREFIX, getApiClient } from "helpers/Global";
import { components } from "schema/schema";

const path = `${API_PREFIX}/ads/send_amazon_email`;

type Payload = components["schemas"]["Ad.AmazonEmailDto.jsonld"];
type Response = components["schemas"]["Ad.AmazonEmailDto.jsonld"];

type Params = {
  payload: Payload;
};

const postAdAmazonEmail = async ({ payload }: Params) => {
  const { data } = await getApiClient().post(path, payload);
  return data;
};

export default function usePostAdAmazonEmailMutation(
  options: UseMutationOptions<Response, AxiosError, Params> = {}
) {
  return useMutation<Response, AxiosError, Params>(postAdAmazonEmail, options);
}
