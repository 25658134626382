import React, { useState, MouseEvent } from "react";
import { Box, Button, Card, Dialog, useMediaQuery, CardHeader } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import CustomButton from "components/Buttons/CustomButton";
import { useTranslation } from "react-i18next";
import useDeleteNewsletterHistoriesMutation from "hooks/useDeleteNewsletterHistoriesMutation";
import useCoppyNewsletterHistoriesMutation from "hooks/useCoppyNewsletterHistoriesMutation";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import TwoStepsAcceptModal from "components/TwoStepsAcceptModal/TwoStepsAcceptModal";
import { operations } from "schema/schema";
import { newsletterHistoryStatus } from "constans/Constans";
import { useNavigate } from "react-router-dom";

type NewsletterHistory = operations["api_apiv1newsletter_histories_id_get"]["responses"]["200"]["content"]["application/ld+json"];

interface ViewNewsletterModalTypes {
  showNewsletterModal: boolean;
  setShowNewsletterModal: (val: boolean) => void;
  refetch: () => void;
  newsletterRowData: NewsletterHistory | undefined;
};

function ViewNewsletterModal({
  refetch,
  showNewsletterModal,
  setShowNewsletterModal,
  newsletterRowData,
}: ViewNewsletterModalTypes) {
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { mutate: deleteMutate } = useDeleteNewsletterHistoriesMutation();
  const { mutate: coppyMutate } = useCoppyNewsletterHistoriesMutation();
  const { t } = useTranslation();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openTwoStepsAcceptModal, setOpenTwoStepsAcceptModal] = useState(false);
  const [openError, setOpenError] = useState(false);
  const navigate = useNavigate();
  const handleDelete = () => {
    deleteMutate({
      newsletterId: newsletterRowData?.id,
      payload: { status: newsletterHistoryStatus.DELETED }
    },
      {
        onSuccess: () => {
          refetch();
          setOpenSuccess(true);
          setTimeout(() => {
            setShowNewsletterModal(false);
          }, 2000);
        },
        onError: () => {
          setOpenSuccess(false);
          setOpenError(true);
        },
      });
  };
  const handleCoppy = () => {
    coppyMutate(
      {
        payload: { newsletterId: newsletterRowData?.id },
      },
      {
        onSuccess: (data) => {
          navigate('/newsletter-history-edit', {
            state: {
              id: data,
            },
          });
        }
      }
    );
  }

  return (
    <>
      <Dialog
        open={showNewsletterModal}
        BackdropProps={{
          style: {
            backgroundColor: theme.palette.customColors.modalBgc,
          },
        }}
      >
        <Box
          className="array-modal"
          sx={{
            width: onlyMediumScreen ? "90vw" : "70vw",
            minHeight: 150,
            px: onlySmallScreen ? 5 : "unset",
          }}
        >
          <Card
            sx={{
              minHeight: 10,
              px: onlySmallScreen ? "unset" : "50px",
              py: 5,
              fontSize: 'default',
              flexWrap: "wrap",
              overflow: 'auto',
            }}
          >
            <CardHeader title={t("Preview Of Message Content")} />
          </Card>
          <Card
            sx={{
              height: 520,
              px: onlySmallScreen ? "unset" : "50px",
              py: 5,
              fontSize: 'default',
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              overflow: 'auto',
            }}
          >
            {newsletterRowData && newsletterRowData.contentHtml &&
              <div dangerouslySetInnerHTML={{ __html: newsletterRowData.contentHtml }} />}
          </Card>
          <Card
            sx={{
              height: 80,
              px: onlySmallScreen ? "unset" : "50px",
              py: 0,
              fontSize: 'default',
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              overflow: 'auto',
            }}
          >
            {newsletterRowData && newsletterRowData.status == newsletterHistoryStatus.SENT && (
              <CustomButton
                text={t("Copy For Edit")}
                type="submit"
                style={{ m: 2 }}
                onClickFunction={() => {
                  handleCoppy()
                }
                }
              />
            )}
            {newsletterRowData && newsletterRowData.status != newsletterHistoryStatus.SENT && (
              <CustomButton
                text={t("Edit")}
                type="submit"
                style={{ m: 2 }}
                onClickFunction={() =>
                  navigate('/newsletter-history-edit', {
                    state: {
                      id: newsletterRowData.id,
                      status: 3,
                      action: "edit",
                    },
                  })
                }
              />
            )}
            {newsletterRowData && newsletterRowData.status != newsletterHistoryStatus.SENT && (
              <CustomButton
                text={t("Delete")}
                onClickFunction={() => {
                  setOpenTwoStepsAcceptModal(true)
                }}
                style={{ m: 2 }}
              />
            )}
            <CustomButton
              text={t("Close")}
              onClickFunction={() => setShowNewsletterModal(false)}
              style={{ m: 2 }}
            />
          </Card>
          <Button
            className="array-close-button"
            onClick={() => setShowNewsletterModal(false)}
          >
            <CloseIcon />
          </Button>
          <TwoStepsAcceptModal
            message={t("Do You Want To Delete Mailing?")}
            executeButtonName={t("Delete")}
            executeFunction={handleDelete}
            isOpen={openTwoStepsAcceptModal}
            setIsOpen={setOpenTwoStepsAcceptModal}
          />
          <ChangeParametersModal
            openSuccess={openSuccess}
            openError={openError}
            setOpenSuccess={setOpenSuccess}
            setOpenError={setOpenError}
            errorMessage={t("Message Error Delete Mailing")}
            successMessage={t("Message Success Delete Mailing")}
          />
        </Box>
      </Dialog >
    </>
  );
}
export default ViewNewsletterModal;
