import {
  Box,
  InputAdornment,
  Typography,
  useMediaQuery,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React, { useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import TableBasic from "components/TableBasic/TableBasic";
import { useTranslation } from "react-i18next";
import useGetMembershipResignationReportCsvQuery from "hooks/useGetMembershipResignationReportCsvQuery";
import { Loader } from "components/Loader/Loader";
import CustomButton from "components/Buttons/CustomButton";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import dayjs from "dayjs";
import useGetMembershipResignationReportQuery from "hooks/useGetMembershipResignationReportQuery";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTheme } from "@mui/material/styles";
import EventIcon from "@mui/icons-material/Event";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useForm } from "react-hook-form";

export interface FormInputs {
  dateFrom: string,
  dateTo: string,
  isWholePeriod: boolean,
}

function MembershipResignationReport() {
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [CSVEnabled, setCSVEnabled] = useState(false);
  const today = dayjs();
  const GetCsv = () => {
    setCSVEnabled(true);
  };
  const { control } = useForm<FormInputs>({
    defaultValues: {
      dateFrom: "2000-01-01",
    },
  });
  const { dateFrom, dateTo, isWholePeriod } = useWatch({ control });
  const queryValues = {
    page: pageNumber,
    itemsPerPage: itemsPerPage,
    membershipResignationDateFrom: isWholePeriod ? dayjs('1900-01-01').format('YYYY-MM-DD') : dayjs(dateFrom).date(1).format('YYYY-MM-DD'),
    membershipResignationDateTo: isWholePeriod ? dayjs().format('YYYY-MM-DD') : dayjs(dateTo).date(dayjs(dateTo).daysInMonth()).format('YYYY-MM-DD'),
  };
  const { data: RowsData, isLoading: RowsDataLoading } = useGetMembershipResignationReportQuery(
    {
      query: queryValues,
    },
  );
  const { data: CsvData, isLoading: CsvDataLoading } = useGetMembershipResignationReportCsvQuery(
    {
      query: queryValues,
    },
    {
      enabled: CSVEnabled,
      onSettled: () => {
        setCSVEnabled(false);
      },
    }
  );
  const columns: GridColDef[] = [
    {
      flex: 0.2,
      minWidth: 200,
      field: "fullName",
      valueGetter: (params) => {
        return `${params.row.firstName || ''} ${params.row.lastName || ''}`;
      },
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("First Name and Last Name"),
    },
    {
      flex: 0.2,
      minWidth: 200,
      field: "email",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Login"),
    },
    {
      flex: 0.15,
      minWidth: 80,
      field: "cardNumber",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Card Number"),
    },
    {
      flex: 0.15,
      minWidth: 150,
      field: "mobilePhone",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Mobile Phone Number"),
    },
    {
      flex: 0.15,
      minWidth: 200,
      field: "contributionExpirationDate",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Contribution Expiration Date"),
      valueFormatter: (params) => dayjs(params.value).isValid() ? dayjs(params.value).format('YYYY-MM-DD') : "",
    },
    {
      flex: 0.15,
      minWidth: 150,
      field: "totalAmount",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Contribution Sum"),
      valueFormatter: (params) => params.value + " zł",
    },
    {
      flex: 0.15,
      minWidth: 300,
      field: "basicMembershipFiveYear",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Basic Membership Five Year"),
      valueGetter: (params) => params.row?.countPaidContributionForMember.BASIC_MEMBERSHIP_FIVE_YEAR
    },
    {
      flex: 0.15,
      minWidth: 300,
      field: "basicMembershipOneYear",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Basic Membership One Year"),
      valueGetter: (params) => params.row?.countPaidContributionForMember.BASIC_MEMBERSHIP_ONE_YEAR
    },
    {
      flex: 0.15,
      minWidth: 300,
      field: "extendedMembershipFiveYear",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Extended Membership Five Year"),
      valueGetter: (params) => params.row?.countPaidContributionForMember.EXTENDED_MEMBERSHIP_FIVE_YEAR
    },
    {
      flex: 0.15,
      minWidth: 300,
      field: "extendedMembershipOneYear",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Extended Membership One Year"),
      valueGetter: (params) => params.row?.countPaidContributionForMember.EXTENDED_MEMBERSHIP_ONE_YEAR
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: onlySmallScreen ? "column" : "row",
          mt: onlySmallScreen ? 5 : 0,
          mb: 5,
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={t("Language Code") as string}
        >
          <Typography>{t("Entry Date")}</Typography>
          <Controller
            control={control}
            name="dateFrom"
            render={({ field: { value, onChange } }) => (
              <DatePicker
                disabled={isWholePeriod}
                maxDate={today}
                views={['year', 'month']}
                inputFormat={"YYYY-MM"}
                value={value}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EventIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={onChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={"YYYY-MM"}
                    sx={{ width: "180px", mx: 2 }}
                  />
                )}
              />
            )}
          />
          <Typography>{t("To")}</Typography>
          <Controller
            control={control}
            name="dateTo"
            render={({ field: { value, onChange } }) => (
              <DatePicker
                disabled={isWholePeriod}
                maxDate={today}
                views={['year', 'month']}
                inputFormat={"YYYY-MM"}
                value={value}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EventIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={onChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={"YYYY-MM"}
                    sx={{
                      width: "180px",
                      mx: 2,
                    }}
                    error={dateFrom && dateTo ? dateFrom > dateTo : false}
                    helperText={
                      dateFrom && dateTo ? dateFrom > dateTo
                        ? t("Date Range Validation")
                        : "" : ""
                    }
                    FormHelperTextProps={{
                      style: { position: "absolute", bottom: "-25px" },
                    }}
                  />
                )}
              />
            )}
          />
          <Controller
            control={control}
            name="isWholePeriod"
            render={({ field: { value, onChange } }) => (
              <FormControlLabel
                label={t("Show Whole History")}
                control={
                  <Checkbox checked={value} onChange={onChange} sx={{ ml: 10 }} />
                }
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      <TableBasic
        paginationMode="server"
        columns={columns}
        loading={RowsDataLoading}
        rows={RowsData?.["hydra:member"]}
        title={t("Membership Resignation Report")}
        setItemsPerPage={setItemsPerPage}
        totalItems={RowsData?.["hydra:totalItems"]}
        setPageNumber={setPageNumber}
        useRowId
      />
      {CsvDataLoading && <Loader />}
      <CustomButton
        text={t("Generate CSV")}
        style={{ mx: 5, mb: 5, mt: 5 }}
        onClickFunction={() => GetCsv()}
      />
      {CsvData && !CsvDataLoading && (
        <CSVLink
          className={"csv-button-link"}
          data={String(CsvData)}
          filename={`membership_resignation_report_${format(
            new Date(),
            "yyyy-MM-dd HH:mm:ss"
          )}.csv`}
          target="_blank"
        >
          <Button
            color="inherit"
            variant="contained"
            className="button-custom-styles"
            sx={{ mx: 5, mb: 5, mt: 5 }}
          >
            {t("Download")}
          </Button>
        </CSVLink>
      )}
    </div>
  );
}

export default MembershipResignationReport;
