import React, { useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import TableBasic from "components/TableBasic/TableBasic";
import { useTranslation } from "react-i18next";
import useGetMembershipStatisticsQuery from "hooks/useGetMembershipStatisticsQuery";
import useGetMembershipStatisticsCsvQuery from "hooks/useGetMembershipStatisticsCsvQuery";
import { CSVLink } from "react-csv";
import { Loader } from "components/Loader/Loader";
import CustomButton from "components/Buttons/CustomButton";
import { Button } from "@mui/material";
import { format } from "date-fns";

function MembershipStatistics() {
  const { t } = useTranslation();
  const [CSVEnabled, setCSVEnabled] = useState(false);
  const GetCsv = () => {
    setCSVEnabled(true);
  };
  const { data: RowsData, isLoading: RowsDataLoading } = useGetMembershipStatisticsQuery(
    {
      query: {},
    },
  );
  const { data: CsvData, isLoading: CsvDataLoading } = useGetMembershipStatisticsCsvQuery(
    {
      query: {},
    },
    {
      enabled: CSVEnabled,
      onSettled: () => {
        setCSVEnabled(false);
      },
    }
  );
  const columns: GridColDef[] = [
    {
      flex: 0.4,
      minWidth: 300,
      field: "nameOfContribution",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Contribution Type"),
    },
    {
      flex: 0.2,
      minWidth: 250,
      field: "memberCount",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Member Count In Number"),
    },
    {
      flex: 0.2,
      minWidth: 250,
      field: "memberInPercent",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Member Count In Percents"),
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <TableBasic
        paginationMode="server"
        columns={columns}
        loading={RowsDataLoading}
        rows={RowsData?.["hydra:member"]}
        title={t("Membership Statistics")}
        totalItems={RowsData?.["hydra:totalItems"]}
        useRowId
        isHideFooter
      />
      {CsvDataLoading && <Loader />}
      <CustomButton
        text={t("Generate CSV")}
        style={{ mx: 5, mb: 5, mt: 5 }}
        onClickFunction={() => GetCsv()}
      />
      {CsvData && !CsvDataLoading && (
        <CSVLink
          className={"csv-button-link"}
          data={String(CsvData)}
          filename={`membership_statistics_${format(
            new Date(),
            "yyyy-MM-dd HH:mm:ss"
          )}.csv`}
          target="_blank"
        >
          <Button
            color="inherit"
            variant="contained"
            className="button-custom-styles"
            sx={{ mx: 5, mb: 5, mt: 5 }}
          >
            {t("Download")}
          </Button>
        </CSVLink>
      )}
    </div>
  );
}

export default MembershipStatistics;
