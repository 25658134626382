import {
  Box,
  InputAdornment,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import TableBasic from "components/TableBasic/TableBasic";
import { useTranslation } from "react-i18next";
import useGetMembershipPaymentDailyReportQuery from "hooks/useGetMembershipPaymentDailyReportQuery";
import { Loader } from "components/Loader/Loader";
import CustomButton from "components/Buttons/CustomButton";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTheme } from "@mui/material/styles";
import EventIcon from "@mui/icons-material/Event";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useForm } from "react-hook-form";
import useGetMembershipPaymentDailyReportCsvQuery from "hooks/useGetMembershipPaymentDailyReportCsvQuery";

export interface FormInputs {
  date: string,
}

function MembershipPaymentDailyReport() {
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [CSVEnabled, setCSVEnabled] = useState(false);
  const { control } = useForm<FormInputs>();
  const { date } = useWatch({ control });
  const today = dayjs();
  const GetCsv = () => {
    setCSVEnabled(true);
  };
  const queryValues = {
    page: pageNumber,
    itemsPerPage: itemsPerPage,
    "verificationDate[after]": dayjs(date).date(1).format('YYYY-MM-DD'),
    "verificationDate[before]": dayjs(date).date(dayjs(date).daysInMonth()).format('YYYY-MM-DD'),
    "exists[contributions]": true,
  };
  const { data: RowsData, isLoading: RowsDataLoading } = useGetMembershipPaymentDailyReportQuery(
    {
      query: queryValues
    },
  );
  const { data: CsvData, isLoading: CsvDataLoading } = useGetMembershipPaymentDailyReportCsvQuery(
    {
      query: queryValues
    },
    {
      enabled: CSVEnabled,
      onSettled: () => {
        setCSVEnabled(false);
      },
    }
  );
  const columns: GridColDef[] = [
    {
      flex: 0.2,
      minWidth: 200,
      field: "fullName",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("First Name and Last Name"),
    },
    {
      flex: 0.15,
      minWidth: 200,
      field: "login",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Login"),
    },
    {
      flex: 0.15,
      minWidth: 80,
      field: "cardNumber",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Card Number"),
    },
    {
      flex: 0.15,
      minWidth: 200,
      field: "dateToOfContribution",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Date To Of Contribution"),
      valueFormatter: (params) => dayjs(params.value).isValid() ? dayjs(params.value).format('YYYY-MM-DD') : "",
    },
    {
      flex: 0.15,
      minWidth: 120,
      field: "verificationDate",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Verification Date"),
      valueFormatter: (params) => dayjs(params.value).isValid() ? dayjs(params.value).format('YYYY-MM-DD') : "",
    },
    {
      flex: 0.15,
      minWidth: 120,
      field: "amountInCents",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Amount"),
      valueFormatter: (params) => params.value / 100 + " zł"
    },
    {
      flex: 0.15,
      minWidth: 300,
      field: "paymentTypeName",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Payment Type"),
    },
    {
      flex: 0.15,
      minWidth: 300,
      field: "comment",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Comment"),
    },
    {
      flex: 0.15,
      minWidth: 150,
      field: "dateFromOfContribution",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Date From Of Contribution"),
      valueFormatter: (params) => dayjs(params.value).isValid() ? dayjs(params.value).format('YYYY-MM-DD') : "",
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: onlySmallScreen ? "column" : "row",
          mt: onlySmallScreen ? 5 : 0,
          mb: 5,
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={t("Language Code") as string}
        >
          <Typography>{t("Entry Date")}</Typography>
          <Controller
            control={control}
            name="date"
            render={({ field: { value, onChange } }) => (
              <DatePicker
                maxDate={today}
                views={['year', 'month']}
                inputFormat={"YYYY-MM"}
                value={value}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EventIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={onChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={"YYYY-MM"}
                    sx={{ width: "180px", mx: 2 }}
                  />
                )}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      <TableBasic
        paginationMode="server"
        columns={columns}
        loading={RowsDataLoading}
        rows={RowsData?.["hydra:member"]}
        title={t("Membership Payment Daily Report")}
        setItemsPerPage={setItemsPerPage}
        totalItems={RowsData?.["hydra:totalItems"]}
        setPageNumber={setPageNumber}
        useRowId
      />
      {CsvDataLoading && <Loader />}
      <CustomButton
        text={t("Generate CSV")}
        style={{ mx: 5, mb: 5, mt: 5 }}
        onClickFunction={() => GetCsv()}
      />
      {CsvData && !CsvDataLoading && (
        <CSVLink
          className={"csv-button-link"}
          data={String(CsvData)}
          filename={`membership_payment_daily_report_${format(
            new Date(),
            "yyyy-MM-dd HH:mm:ss"
          )}.csv`}
          target="_blank"
        >
          <Button
            color="inherit"
            variant="contained"
            className="button-custom-styles"
            sx={{ mx: 5, mb: 5, mt: 5 }}
          >
            {t("Download")}
          </Button>
        </CSVLink>
      )}
    </div>
  );
}

export default MembershipPaymentDailyReport;
