import {
  Box,
  InputAdornment,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import TableBasic from "components/TableBasic/TableBasic";
import { useTranslation } from "react-i18next";
import useGetMembershipPaymentReportQuery from "hooks/useGetMembershipPaymentReportQuery";
import { Loader } from "components/Loader/Loader";
import CustomButton from "components/Buttons/CustomButton";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTheme } from "@mui/material/styles";
import EventIcon from "@mui/icons-material/Event";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useForm } from "react-hook-form";
import useGetMembershipPaymentReportCsvQuery from "hooks/useGetMembershipPaymentReportCsvQuery";

export interface FormInputs {
  year: string,
}

function MembershipPaymentReport() {
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [CSVEnabled, setCSVEnabled] = useState(false);
  const today = dayjs();
  const { control } = useForm<FormInputs>();
  const { year } = useWatch({ control });
  const { data: RowsData, isLoading: RowsDataLoading } = useGetMembershipPaymentReportQuery(
    {
      path:
      {
        date: dayjs(year).format('YYYY')
      }
    },
    dayjs(year).format('YYYY')
  );
  const GetCsv = () => {
    setCSVEnabled(true);
  };
  const { data: CsvData, isLoading: CsvDataLoading } = useGetMembershipPaymentReportCsvQuery(
    {
      path:
      {
        date: dayjs(year).format('YYYY')
      },
    },
    dayjs(year).format('YYYY'),
    {
      enabled: CSVEnabled,
      onSettled: () => {
        setCSVEnabled(false);
      },
    }
  );
  const columns: GridColDef[] = [
    {
      flex: 0.2,
      minWidth: 350,
      field: "nameOfPaymentsAndDeclarations",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Name Of Statistics"),
      valueFormatter: (params) =>
        params.value === "liczba_wplat_od_przedluzajacych_czlonkostwo" ? t("Payment Count Membership Prolongation") :
          params.value === "Liczba_wplat_od_nowych_czlonkow" ? t("Payment Count New Members") :
            params.value === "Liczba_aktywnych_czlonkow_na_koniec_miesiaca" ? t("Active Users Count") :
              params.value === "Liczba_czlonkow_na_koniec_miesiaca" ? t("Users Count") :
                params.value === "Liczba_rezygnacji" ? t("Resignations Count") :
                  ""
    },
    {
      flex: 0.2,
      minWidth: 80,
      field: "january",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("January"),
      valueFormatter: (params) => params.value === null ? 0 : params.value
    },
    {
      flex: 0.15,
      minWidth: 80,
      field: "february",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("February"),
      valueFormatter: (params) => params.value === null ? 0 : params.value
    },
    {
      flex: 0.15,
      minWidth: 80,
      field: "march",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("March"),
      valueFormatter: (params) => params.value === null ? 0 : params.value
    },
    {
      flex: 0.15,
      minWidth: 80,
      field: "april",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("April"),
      valueFormatter: (params) => params.value === null ? 0 : params.value
    },
    {
      flex: 0.15,
      minWidth: 80,
      field: "may",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("May"),
      valueFormatter: (params) => params.value === null ? 0 : params.value
    },
    {
      flex: 0.15,
      minWidth: 80,
      field: "june",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("June"),
      valueFormatter: (params) => params.value === null ? 0 : params.value
    },
    {
      flex: 0.15,
      minWidth: 80,
      field: "july",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("July"),
      valueFormatter: (params) => params.value === null ? 0 : params.value
    },
    {
      flex: 0.15,
      minWidth: 80,
      field: "august",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("August"),
      valueFormatter: (params) => params.value === null ? 0 : params.value
    },
    {
      flex: 0.15,
      minWidth: 80,
      field: "september",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("September"),
      valueFormatter: (params) => params.value === null ? 0 : params.value
    },
    {
      flex: 0.15,
      minWidth: 80,
      field: "october",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("October"),
      valueFormatter: (params) => params.value === null ? 0 : params.value
    },
    {
      flex: 0.15,
      minWidth: 80,
      field: "november",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("November"),
      valueFormatter: (params) => params.value === null ? 0 : params.value
    },
    {
      flex: 0.15,
      minWidth: 80,
      field: "december",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("December"),
      valueFormatter: (params) => params.value === null ? 0 : params.value
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: onlySmallScreen ? "column" : "row",
          mt: onlySmallScreen ? 5 : 0,
          mb: 5,
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={t("Language Code") as string}
        >
          <Typography>{t("Entry Date")}</Typography>
          <Controller
            control={control}
            name="year"
            render={({ field: { value, onChange } }) => (
              <DatePicker
                maxDate={today}
                views={['year']}
                inputFormat={"YYYY"}
                value={value}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EventIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={onChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={"YYYY"}
                    sx={{ width: "180px", mx: 2 }}
                  />
                )}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      <TableBasic
        paginationMode="server"
        columns={columns}
        loading={RowsDataLoading}
        rows={RowsData?.["hydra:member"]}
        title={t("Membership Payment Report")}
        totalItems={RowsData?.["hydra:totalItems"]}
        useRowId
        isHideFooter
      />
      {CsvDataLoading && <Loader />}
      <CustomButton
        text={t("Generate CSV")}
        style={{ mx: 5, mb: 5, mt: 5 }}
        onClickFunction={() => GetCsv()}
      />
      {CsvData && !CsvDataLoading && (
        <CSVLink
          className={"csv-button-link"}
          data={String(CsvData)}
          filename={`membership_payment_report_${format(
            new Date(),
            "yyyy-MM-dd HH:mm:ss"
          )}.csv`}
          target="_blank"
        >
          <Button
            color="inherit"
            variant="contained"
            className="button-custom-styles"
            sx={{ mx: 5, mb: 5, mt: 5 }}
          >
            {t("Download")}
          </Button>
        </CSVLink>
      )}
    </div>
  );
}

export default MembershipPaymentReport;
