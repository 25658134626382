import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { components } from "schema/schema";

const pathPoints = `/api/v1/balances/`;

type Payload = components["schemas"]["Balance.jsonld"];
type Params = {
  payload: Payload;
  balanceId: components["schemas"]["Balance.jsonld"]["id"];
};

const putBalanceCode = async ({ balanceId, payload }: Params) => {
    const { data } = await getApiClient().put(`${pathPoints}${balanceId}`, payload);
    return data;
};

export default function usePutBalanceMutation(
  options: UseMutationOptions<Response, AxiosError, Params> = {}
) {
  return useMutation<Response, AxiosError, Params>(putBalanceCode, options);
}
