import React, { useState } from "react";
import TableBasic from "components/TableBasic/TableBasic";
import { Box, Button, Dialog, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { components } from "schema/schema";
import useGenerateCsvVerificationProfilesQuery from "hooks/useGenerateCsvVerificationProfilesQuery";
import { Loader } from "components/Loader/Loader";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import CustomButton from "components/Buttons/CustomButton";
import { userVerificationStatusType } from "constans/Constans";
import { CSVLink } from "react-csv";
import { format } from "date-fns";

interface ResultsOfVerificationModalTypes {
  showResultsOfVerification: boolean;
  setShowResultsOfVerification: (showResultsOfVerification: boolean) => void;
  resultsOfVerificationData?: components["schemas"]["User.UsersMassProfileVerificationOutputDto.jsonld-mass_profile_verification"];
  inputEmails?: string[];
  checkingProfilesLoading: boolean;
}

function ResultsOfVerificationModal({
  showResultsOfVerification,
  setShowResultsOfVerification,
  resultsOfVerificationData,
  inputEmails,
  checkingProfilesLoading,
}: ResultsOfVerificationModalTypes) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const {
    mutate: CsvData,
    isLoading: CsvDataLoading,
    data: resultsOfVerificationDataCsv,
  } = useGenerateCsvVerificationProfilesQuery();
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const GetCsv = () => {
    CsvData(
      {
        emails: inputEmails,
      },
      {
        onError: () => {
          setOpenSuccess(false);
          setOpenError(true);
        },
      }
    );
  };
  const columns: GridColDef[] = [
    {
      flex: 0.1,
      field: "email",
      minWidth: 150,
      headerName: t("Email") as string,
    },
    {
      flex: 0.25,
      minWidth: 100,
      field: "firstName",
      headerName: t("First Name") as string,
    },
    {
      flex: 0.25,
      minWidth: 100,
      field: "lastName",
      headerName: t("Last Name") as string,
    },
    {
      flex: 0.25,
      minWidth: 170,
      field: "mobilePhone",
      headerName: t("Mobile Phone Number") as string,
    },
    {
      flex: 0.25,
      minWidth: 100,
      field: "profileStatus",
      headerName: t("Status") as string,
      renderCell: (params) => {
        const statusValue = params.value as number;
        const statusObject = userVerificationStatusType.find(
          (status) => status.value === statusValue
        );
        if (statusObject) {
          return statusObject.name;
        } else {
          return "";
        }
      },
    },
    {
      flex: 0.25,
      minWidth: 70,
      field: "cardNumber",
      headerName: t("Card") as string,
    },
    {
      flex: 0.25,
      minWidth: 100,
      field: "newsletter",
      headerName: t("Newsletter") as string,
      valueGetter: (params) => {
        return params.row.subscribedNewsletterTypes.find((element: string) => {
          return element === "/api/v1/newsletter_types/1";
        })
          ? t("Yes")
          : t("No");
      },
    },
    {
      flex: 0.25,
      minWidth: 100,
      field: "subscribedNewsletterTypes",
      headerName: t("Mailing") as string,
      valueGetter: (params) => {
        return params.value.find((element: string) => {
          return element === "/api/v1/newsletter_types/2";
        })
          ? t("Yes")
          : t("No");
      },
    },
  ];
  return (
    <Dialog
      open={showResultsOfVerification}
      BackdropProps={{
        style: {
          backgroundColor: theme.palette.customColors.modalBgc,
        },
      }}
    >
      <Box
        className="array-modal"
        sx={{
          width: onlyMediumScreen ? "100vw" : "70vw",
          px: onlyMediumScreen ? 5 : "unset",
        }}
      >
        <Button
          className="array-close-button"
          onClick={() => setShowResultsOfVerification(false)}
        >
          <CloseIcon />
        </Button>
        <div className="result-of-verification-wrapper">
          <TableBasic
            paginationMode="client"
            columns={columns}
            loading={checkingProfilesLoading}
            title={t("Results Of Verification List")}
            rows={resultsOfVerificationData?.emails}
            useRowId
          />
          {CsvDataLoading && <Loader />}
          <CustomButton
            text={t("Generate CSV")}
            style={{ m: 5 }}
            onClickFunction={() => GetCsv()}
          />
          {resultsOfVerificationDataCsv && !CsvDataLoading && (
            <CSVLink
              className={"csv-button-link"}
              data={String(resultsOfVerificationDataCsv)}
              filename={`results_of_verification_${format(
                new Date(),
                "yyyy-MM-dd HH:mm:ss"
              )}.csv`}
              target="_blank"
            >
              <Button
                color="inherit"
                variant="contained"
                className="button-custom-styles"
                sx={{ mx: 5, mb: 5, mt: 5 }}
              >
                {t("Download")}
              </Button>
            </CSVLink>
          )}
        </div>
      </Box>
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Failed Generate CSV")}
        successMessage={""}
      />
    </Dialog>
  );
}
export default ResultsOfVerificationModal;
