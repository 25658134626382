import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { components } from "schema/schema";

const path = "/api/v1/crms";
type ChangeHistoryId = components["schemas"]["Crm.jsonld"]["id"];

const putDeleteChangeHistory = async (ChangeHistoryId: ChangeHistoryId) => {
  const payload = {ChangeHistoryId:'/api/v1/users/' + ChangeHistoryId, status:"2"};
  const { data } = await getApiClient().put(`${path}/${ChangeHistoryId}`, payload);
  return data;
};

export default function useDeleteChangeHistoryMutation(
  options: UseMutationOptions<void, AxiosError, ChangeHistoryId> = {}
) {
  return useMutation<void, AxiosError, ChangeHistoryId>(putDeleteChangeHistory, options);
}
