import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { operations } from "schema/schema";

const path = "api/v1/membership_payment_report_csv/";
const queryKey = "membershipPaymentCsvReport";

type Response =
  operations["membershipPaymentReportCsv"]["responses"]["200"]["content"]["application/ld+json"];
type Parameter = operations["membershipPaymentReportCsv"]["parameters"];

const getMembershipPaymentReportCsvQuery = async (parameters: Parameter, year: string) => {
  const { data } = await getApiClient().get(`${path}${year}`, {
    params: parameters?.query,
  });
  return data;
};

export default function useGetMembershipPaymentReportCsvQuery(
  parameters: Parameter,
	year: string,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey, parameters],
    () => getMembershipPaymentReportCsvQuery(parameters, year),
    options
  );
}
