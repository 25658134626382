import {
	Box,
	useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import { Divider } from "@mui/material";
import { useSettings } from "hooks/useSettings";
import { useLocation } from 'react-router-dom';
import usePostNewsletterHistoryMutation from "hooks/usePostNewsletterHistoryMutation";
import { useNavigate } from "react-router-dom";
import CustomButton from "components/Buttons/CustomButton";
import useGetWhoAmI from "hooks/useWhoIAmQuery";

function NewsletterHistoryCreate(refetch: any, setNewsletterId: any) {

	const { t } = useTranslation();
	const { settings } = useSettings();
	const theme = useTheme();
	const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
	const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const location = useLocation();
	const navigate = useNavigate();
	const { mutate: postMutate } = usePostNewsletterHistoryMutation();
	const { data: WhoAmI } = useGetWhoAmI();
	const handlePost = () => {
		postMutate(
			{
				payload: {
					title: "",
					senderEmail: "",
					senderName: "",
					reply: "",
					replyName: "",
					contentHtml: "",
					contentTxt: "",
					status: 0,
					type: "/api/v1/newsletter_types/2",
					receiver: "",
					user: "/api/v1/users/" + WhoAmI?.user_id || location.state?.userId?.user_id,
					preheader: "",
				}
			},
			{
				onSuccess: () => {
					window.location.reload();
				}
			}
		)
	};
	const handleNavigate = () => {
		navigate('/newsletter-history', {
			state: {
				status: 0,
				userId: location.state?.userId,
			},
		});
	}

	return (
		<Box
			sx={{
				maxWidth: 800,
				justifyContent: onlySmallScreen ? "center" : "unset",
				m: 5,
			}}
		>
			<CustomButton text={t("Create Newsletter")} style={{ m: 2, height: '60px', mt: 5 }} type="button" onClickFunction={handlePost} />
			<CustomButton text={t("Go To Edit/Copy")} style={{ m: 2, height: '60px', mt: 5 }} type="button" onClickFunction={handleNavigate} />
		</Box>
	);
}

export default NewsletterHistoryCreate;
