import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { operations } from "schema/schema";

const path = "api/v1/users/resignation_from_membership_CSV";
const queryKey = "resignationFromMembershipCSV";

type Response =
  operations["resignationFromMembershipCSV"]["responses"]["200"]["content"]["application/ld+json"];
type Parameter = operations["resignationFromMembershipCSV"]["parameters"];

const getMembershipResignationReportCsvQuery = async (parameters: Parameter) => {
  const { data } = await getApiClient().get(path, {
    params: parameters?.query,
  });
  return data;
};

export default function useGetMembershipResignationReportCsvQuery(
  parameters: Parameter,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey, parameters],
    () => getMembershipResignationReportCsvQuery(parameters),
    options
  );
}
