import {
    Box,
    useMediaQuery,
  } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import { Divider } from "@mui/material";
import { useSettings } from "hooks/useSettings";
import NewsletterHistoryData from "components/PageEditNewsletterHistory/NewsletterHistoryData";
  
function NewsletterHistoryEdit() {
  const { t } = useTranslation();
  const { settings } = useSettings();
  const theme = useTheme();
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card
      sx={{
        backgroundColor: settings.mode === "light" ? "inherit" : "#28243D",
      }}
    >
      <CardHeader title={t("Mailing Create Edit")} />
      <Divider />
      <Grid
        container
        spacing={6}
        item
        sx={{
          m: onlyMediumScreen ? 0 : 4,
          justifyContent: "center",
          width: onlySmallScreen ? "100%" : "unset",
          pl: onlyMediumScreen ? 0 : "unset",
        }}
      >
        <Box
          sx={{
            maxWidth: 800,
            display: "flex",
            flexDirection: "row",
            justifyContent: onlySmallScreen ? "center" : "unset",
            m: 5,
          }}
        >
          <NewsletterHistoryData />
        </Box>
      </Grid>
    </Card>
  );
}

export default NewsletterHistoryEdit;
  