import React, { useEffect, useState } from "react";
import { roles as rolesObjects } from "constans/Constans";
import { Box, FormControl, FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { operations } from "schema/schema";
import useUserInformationMutation from "hooks/useUserInformationMutation";
import { Loader } from "components/Loader/Loader";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { useForm, Controller } from "react-hook-form";
import { includes, map, concat, filter } from "lodash";
import useGetUserStagingAreaQuery from "hooks/useGetUserStagingAreaQuery";
import usePostActivateLinkMutation from "hooks/usePostActivateLinkMutation";
import CustomButton from "components/Buttons/CustomButton";
import UseHasAccess from "hooks/useHasAccess";
import { Role } from "helpers/Global";
import { useQueryClient } from "react-query";
import { queryKey } from "hooks/useWhoIAmQuery";

interface PermissionsTypes {
  userInformationData:
    | operations["api_apiv1users_id_get"]["responses"]["200"]["content"]["application/ld+json"]
    | undefined;
  refetch: () => void;
}

interface FormInputs {
  userRoles: string[];
  isActive: boolean;
}

function Permissions({ userInformationData, refetch }: PermissionsTypes) {
  const queryClient = useQueryClient()
  const hasAccess = UseHasAccess([Role.ROLE_SUPER_USER]).length > 0;
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const { mutate: postActivateLink, isLoading: postActivateLinkLoading } =
    usePostActivateLinkMutation();
  const { mutate: changeRoles, isLoading: changeRolesLoading } =
    useUserInformationMutation();
  const { data: userStagingArea } = useGetUserStagingAreaQuery({
    query: {
      email: userInformationData?.email,
    },
  });
  const {
    control: RolesControl,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<FormInputs>();
  const onSubmit = ({ userRoles, isActive }: FormInputs) => {
    const newRoles = userRoles.map((item) =>
      item === "ROLE_REDACTOR_LEVEL_0" ? "ROLE_REDACTOR_LEVEL_1" : item
    );
    changeRoles(
      {
        payload: {
          roles: newRoles,
          active: isActive,
          errorCount: isActive ? 1 : undefined,
        },
        userId: userInformationData?.id,
      },
      {
        onSuccess: () => {
          setOpenSuccess(true);
          setIsEdit(false);
          refetch();
          queryClient.invalidateQueries(queryKey);
        },
        onError: () => {
          setOpenSuccess(false);
          setOpenError(true);
          setIsEdit(false);
        },
      }
    );
  };
  const handleClick = () => {
    postActivateLink(
      {
        hash: userStagingArea?.["hydra:member"][0].hash,
      },
      {
        onSuccess: () => {
          setOpenSuccess(true);
          setIsEdit(false);
        },
        onError: () => {
          setOpenSuccess(false);
          setOpenError(true);
          setIsEdit(false);
          reset();
        },
      }
    );
  };
  useEffect(() => {
    reset({
      userRoles: userInformationData?.roles,
      isActive: userInformationData?.active,
    });
  }, [userInformationData, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {(changeRolesLoading || postActivateLinkLoading) && <Loader />}
      <FormControl sx={{ width: 200, mt: 2 }}>
        <Controller
          control={RolesControl}
          name="userRoles"
          render={({ field: { value, onChange } }) => (
            <>
              {map(rolesObjects, ({ id, name, nameValue }) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!isEdit ? true : hasAccess ? false : nameValue === "ROLE_SUPER_USER" ? true : false}
                        onChange={() => {
                          onChange(
                            includes(
                              value,
                              nameValue === "ROLE_REDACTOR_LEVEL"
                                ? `ROLE_REDACTOR_LEVEL_${userInformationData?.redactorRoleLevel}`
                                : nameValue
                            )
                              ? filter(
                                  value,
                                  (val) =>
                                    val !==
                                    (nameValue === "ROLE_REDACTOR_LEVEL"
                                      ? `ROLE_REDACTOR_LEVEL_${userInformationData?.redactorRoleLevel}`
                                      : nameValue)
                                )
                              : concat(
                                  value,
                                  nameValue === "ROLE_REDACTOR_LEVEL"
                                    ? `ROLE_REDACTOR_LEVEL_${userInformationData?.redactorRoleLevel}`
                                    : nameValue
                                )
                          );
                        }}
                        checked={includes(
                          value,
                          nameValue === "ROLE_REDACTOR_LEVEL"
                            ? `ROLE_REDACTOR_LEVEL_${userInformationData?.redactorRoleLevel}`
                            : nameValue
                        )}
                      />
                    }
                    key={id}
                    label={name}
                  />
                );
              })}
              <FormControlLabel
                label={t("User")}
                control={<Checkbox checked={true} disabled={true} />}
              />
            </>
          )}
        />
      </FormControl>
      <FormControl>
        <Controller
          control={RolesControl}
          name="isActive"
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              label={t("Active-v2")}
              control={
                <Checkbox
                  disabled={!isEdit}
                  defaultChecked={userInformationData?.active}
                  onChange={(e) => onChange(e.target.checked)}
                  checked={!!value}
                />
              }
            />
          )}
        />
      </FormControl>
      {!userInformationData?.active && isEdit && userStagingArea && (
        <Button
          sx={{
            textDecoration: "none",
            color: "black",
            lineHeight: "40px",
          }}
          onClick={() => handleClick()}
        >
          {t("Send Activate Link")}
        </Button>
      )}
      <Box sx={{ display: "flex" }}>
        <CustomButton
          text={!isEdit ? t("Edit") : t("Cancel")}
          style={{ m: 2, width: "100px", ml: 0 }}
          onClickFunction={() => {
            setIsEdit(!isEdit);
            if (isEdit && isDirty) reset();
          }}
        />
        {isEdit && (
          <CustomButton
            text={t("You Save")}
            style={{ m: 2, width: "100px" }}
            type="submit"
          />
        )}
      </Box>
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Edit User Parameters")}
        successMessage={t("Message Success Edit User Parameters")}
      />
    </form>
  );
}

export default Permissions;
