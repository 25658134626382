import React, { useState, MouseEvent } from "react";
import { PencilOutline } from "mdi-material-ui";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DotsVertical from "mdi-material-ui/DotsVertical";
import DeleteOutline from "mdi-material-ui/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import useDeleteContributionMutation from "hooks/useDeleteContributionMutation";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import TwoStepsAcceptModal from "components/TwoStepsAcceptModal/TwoStepsAcceptModal";

interface ActionsButtonType {
  id: number;
  refetch:()=> void;
  setOpenEditContributionModal: (value: boolean) => void;
  rowData: any;
  setContributionRowData: any;
}

const ActionsButton = ({ 
  id, 
  refetch,
  setOpenEditContributionModal,
  rowData,
  setContributionRowData,
}: ActionsButtonType) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const rowOptionsOpen = Boolean(anchorEl);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openTwoStepsAcceptModal, setOpenTwoStepsAcceptModal] = useState(false);
  const [openError, setOpenError] = useState(false);
  const { t } = useTranslation();
  let { contributionId } = useParams();
  contributionId = JSON.stringify(id);
  const { mutate } = useDeleteContributionMutation();
  const handleRowOptionsClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleRowOptionsClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    mutate(id, {
      onSuccess: () => {
        setOpenSuccess(true);
        refetch();
      },
      onError: () => {
        setOpenSuccess(false);
        setOpenError(true);
      },
    });
    handleRowOptionsClose();
  };

  return (
    <>
      <IconButton size="small" onClick={handleRowOptionsClick}>
        <DotsVertical />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={rowOptionsOpen}
        onClose={handleRowOptionsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ style: { minWidth: "8rem" } }}
      >
        <MenuItem onClick={() => {
          setContributionRowData(rowData);
          setOpenEditContributionModal(true);
        }}>
          <PencilOutline fontSize="small" sx={{ mr: 2 }} />
          {t("Edit")}
        </MenuItem>
        <MenuItem onClick={() => setOpenTwoStepsAcceptModal(true)}>
          <DeleteOutline fontSize="small" sx={{ mr: 2 }} />
          {t("Delete")}
        </MenuItem>
      </Menu>
      <TwoStepsAcceptModal
        message={t("Do You Want To Delete Contribution?")}
        executeButtonName={t("Delete")}
        executeFunction={handleDelete}
        isOpen={openTwoStepsAcceptModal}
        setIsOpen={setOpenTwoStepsAcceptModal}
      />
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Delete Contribution")}
        successMessage={t("Message Success Delete Contribution")}
      />
    </>
  );
};
export default ActionsButton;
