import React, { useState, MouseEvent } from "react";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DotsVertical from "mdi-material-ui/DotsVertical";
import IconButton from "@mui/material/IconButton";
import { PencilOutline } from "mdi-material-ui";
import {TransactionsTypes} from "types/types";
import DeleteOutline from "mdi-material-ui/DeleteOutline";
import useDeleteTransactionMutation from "hooks/useDeleteTransactionMutation";
import { useParams } from "react-router-dom";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import TwoStepsAcceptModal from "components/TwoStepsAcceptModal/TwoStepsAcceptModal";

interface ActionsButtonTransactionsTypes {
  id: string;
  setOpenRefundModal: (val: boolean) => void;
  setIdTransaction: (id: string) => void;
  setOpenEditTransactionsModal: (value: boolean) => void;
  setTransactionsRowData: (value: TransactionsTypes) => void;
  transactionsRowData: TransactionsTypes;
  refetch: () => void;
}

const ActionsButtonTransactions = ({
  id,
  refetch,
  setOpenRefundModal,
  setIdTransaction,
  setOpenEditTransactionsModal,
  setTransactionsRowData,
  transactionsRowData
}: ActionsButtonTransactionsTypes) => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openTwoStepsAcceptModal, setOpenTwoStepsAcceptModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const rowOptionsOpen = Boolean(anchorEl);
  const { t } = useTranslation();
  const handleRowOptionsClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleRowOptionsClose = () => {
    setAnchorEl(null);
  };
  let { transactionId } = useParams();
  transactionId = JSON.stringify(id);
  const { mutate } = useDeleteTransactionMutation();
  const handleDelete = () => {
    mutate(id, {
      onSuccess: () => {
        setOpenSuccess(true);
        refetch();
      },
      onError: () => {
        setOpenSuccess(false);
        setOpenError(true);
      },
    });
    handleRowOptionsClose();
  };

  return (
    <>
      <IconButton size="small" onClick={handleRowOptionsClick}>
        <DotsVertical />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={rowOptionsOpen}
        onClose={handleRowOptionsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ style: { minWidth: "8rem" } }}
      >
        <MenuItem
          onClick={() => {
            setIdTransaction(id);
            setOpenRefundModal(true);
          }}
        >
          <PaymentsOutlinedIcon fontSize="small" sx={{ mr: 2 }} />
          {t("Refund Transaction")}
        </MenuItem>
        <MenuItem onClick={() => {
          setTransactionsRowData(transactionsRowData);
          setOpenEditTransactionsModal(true);
        }}>
          <PencilOutline fontSize="small" sx={{ mr: 2 }} />
          {t("Edit")}
        </MenuItem>
        <MenuItem onClick={() => setOpenTwoStepsAcceptModal(true)}>
          <DeleteOutline fontSize="small" sx={{ mr: 2 }} />
          {t("Delete")}
        </MenuItem>
      </Menu>
      <TwoStepsAcceptModal
        message={t("Do You Want To Delete Transaction?")}
        executeButtonName={t("Delete")}
        executeFunction={handleDelete}
        isOpen={openTwoStepsAcceptModal}
        setIsOpen={setOpenTwoStepsAcceptModal}
      />
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Delete Transaction")}
        successMessage={t("Message Success Delete Transaction")}
      />
    </>
  );
};

export default ActionsButtonTransactions;
