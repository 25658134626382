import React, { useState } from "react";
import useGetVerificationHistoryQuery from "hooks/useGetVerificationHistoryQuery";
import { GridColDef } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import TableBasic from "components/TableBasic/TableBasic";
import { useTranslation } from "react-i18next";
import useGetVerificationLogCsvQuery from "hooks/useGetVerificationLogCsvQuery";
import { Loader } from "components/Loader/Loader";
import CustomButton from "components/Buttons/CustomButton";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import dayjs from "dayjs";
import { filter, map } from "lodash";
import { verificationHistoryResult } from "constans/Constans";

function UsersVerificationReport() {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [CSVEnabled, setCSVEnabled] = useState(false);
  const queryValues = {
    page: pageNumber,
    itemsPerPage: itemsPerPage,
    unicalVerification: "true",
  };
  const GetCsv = () => {
    setCSVEnabled(true);
  };
  const { data: RowsData, isLoading: RowsDataLoading } =
    useGetVerificationHistoryQuery(
      {
        query: queryValues,
      },
    );
  const { data: CsvData, isLoading: CsvDataLoading } = useGetVerificationLogCsvQuery(
    {
      query: queryValues,
    },
    {
      enabled: CSVEnabled,
      onSettled: () => {
        setCSVEnabled(false);
      },
    }
  );
  const columns: GridColDef[] = [
    {
      flex: 0.2,
      minWidth: 200,
      field: "fullName",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("First Name and Last Name"),
    },
    {
      flex: 0.2,
      minWidth: 200,
      field: "emailNumberCard",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Email Or Card Number"),
    },
    {
      flex: 0.15,
      minWidth: 150,
      field: "date",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Date Of Verification"),
      valueFormatter: (params: {
        value: string | number | Date | dayjs.Dayjs | null | undefined;
      }) => dayjs(params.value).format("YYYY-MM-DD   HH:mm"),
    },
    {
      flex: 0.15,
      minWidth: 200,
      field: "verifiedByFullName",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Verified By"),
    },
    {
      flex: 0.15,
      minWidth: 300,
      field: "result",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => t("Result"),
      valueFormatter: (params: { value: string | undefined }) => {
        const filteredValues = filter(verificationHistoryResult, {
          nameValue: params.value,
        });
        return map(filteredValues, "name").join(", ");
      },
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <TableBasic
        paginationMode="server"
        columns={columns}
        loading={RowsDataLoading}
        rows={RowsData?.["hydra:member"]}
        title={t("Users Verification Report")}
        setItemsPerPage={setItemsPerPage}
        totalItems={RowsData?.["hydra:totalItems"]}
        setPageNumber={setPageNumber}
      />
      {CsvDataLoading && <Loader />}
      <CustomButton
        text={t("Generate CSV")}
        style={{ mx: 5, mb: 5, mt: 5 }}
        onClickFunction={() => GetCsv()}
      />
      {CsvData && !CsvDataLoading && (
        <CSVLink
          className={"csv-button-link"}
          data={String(CsvData)}
          filename={`users_verification_report_${format(
            new Date(),
            "yyyy-MM-dd HH:mm:ss"
          )}.csv`}
          target="_blank"
        >
          <Button
            color="inherit"
            variant="contained"
            className="button-custom-styles"
            sx={{ mx: 5, mb: 5, mt: 5 }}
          >
            {t("Download")}
          </Button>
        </CSVLink>
      )}
    </div>
  );
}

export default UsersVerificationReport;
